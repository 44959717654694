<template>
  <div class="container">
    <div class="left-title" v-if="$route.query.type == 14">分时用能</div>
    <div class="left-title" v-if="$route.query.type == 15">分时电费</div>
    <div class="back" @click="backPrev">返回</div>
    <div class="right-container">
      <div class="right-top">
        <!-- <el-form ref="form" :model="form"> -->
          <!-- <div
            style="width: 54vw; display: flex; justify-content: space-between"
          > -->
            <!-- <el-form-item label="全局搜索：">
              <el-input
                v-model="form.searchContent"
                placeholder="请输入电站名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="报文时间：" v-if="$route.query.date != '1'">
              <el-date-picker
                style="width: 11vw; margin-right: 2vw"
                v-model="form.beginTime"
                type="date"
                format="yyyy-MM-dd"
                placeholder="开始日期"
                :picker-options="pickerOptions0"
              >
              </el-date-picker>
              <el-date-picker
                style="width: 11vw"
                v-model="form.endTime"
                type="date"
                format="yyyy-MM-dd"
                placeholder="结束日期"
                :picker-options="pickerOptions1"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="报文时间：" v-if="$route.query.date == '1'">
              <el-date-picker
                style="width: 11vw; margin-right: 2vw"
                v-model="form.beginTime"
                type="date"
                format="yyyy-MM-dd"
                placeholder="开始日期"
                disabled
              >
              </el-date-picker>
              <el-date-picker
                style="width: 11vw"
                v-model="form.endTime"
                type="date"
                format="yyyy-MM-dd"
                placeholder="结束日期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
          </div> -->
          <div style="width: 55vw; margin-top:8vh;display: flex; justify-content: flex-end">
            <div style="display: flex; height: 4.6vh">
              <!-- <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div> -->
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        <!-- </el-form> -->
      </div>
      <el-table
        :data="hourlyEnergy"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
        v-if="$route.query.type == 14"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="mrName" label="表名称"></el-table-column>
        <el-table-column prop="sn" label="表号"></el-table-column>
        <el-table-column label="表类型">
          <template slot-scope="scope">
            <span v-if="scope.row.mrWhType == '1'"> 总电表 </span>
            <span v-if="scope.row.mrWhType == '2'"> 光伏电表 </span>
          </template>
        </el-table-column>
        <el-table-column prop="v" label="总电量"></el-table-column>
        <el-table-column prop="a1" label="尖"></el-table-column>
        <el-table-column prop="a2" label="峰"></el-table-column>
        <el-table-column prop="a3" label="平"></el-table-column>
        <el-table-column prop="a4" label="谷"></el-table-column>
        <el-table-column
          prop="dt"
          label="报文时间"
          v-if="$route.query.date == '1'"
        ></el-table-column>
        <el-table-column
          prop="dtStr"
          label="报文时间"
          v-if="$route.query.date == '2'"
        ></el-table-column>
        <el-table-column label="电表详情">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleCheck(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="$route.query.type == 15"
        :data="hourlyPrice"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="mrName" label="电表名称"></el-table-column>
        <el-table-column label="表类型">
          <template slot-scope="scope">
            <span v-if="scope.row.mrWhType == '1'"> 总电表 </span>
            <span v-if="scope.row.mrWhType == '2'"> 光伏电表 </span>
          </template>
        </el-table-column>
        <el-table-column prop="sn" label="表号"></el-table-column>
        <el-table-column prop="v" label="总电量电费"></el-table-column>
        <el-table-column prop="a1" label="尖电费"></el-table-column>
        <el-table-column prop="a2" label="峰电费"></el-table-column>
        <el-table-column prop="a3" label="平电费"></el-table-column>
        <el-table-column prop="a4" label="谷电费"></el-table-column>
        <el-table-column
          prop="dt"
          label="报文时间"
          v-if="$route.query.date == '1'"
        ></el-table-column>
        <el-table-column
          prop="dtStr"
          label="报文时间"
          v-if="$route.query.date == '2'"
        ></el-table-column>
        <el-table-column label="电表详情">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleCheck(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getTimeSharingTable,
  exportEleList,
  getTimeSharingPriceTable,
  exportElePrice,
} from "@/api/dataAnalysis";
export default {
  name: "hourlyChargeAndEnergy",
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        beginTime: "", // 报文时间
        endTime: "",
      },
      hourlyEnergy: [],
      hourlyPrice: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.$route.query.type == 14) {
        this.getList();
      } else {
        this.getPrice();
      }
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.$route.query.type == 14) {
        this.getList();
      } else {
        this.getPrice();
      }
    },
    // 返回上一页
    backPrev() {
      let type = this.$route.query.type;
      if (this.fromPage == "/energyConsumption") {
        this.$router.push("/energyConsumption");
      } else {
        this.$router.push({
          path: "/rankingTotalEnergy",
          query: { type },
        });
      }
    },
    // 查看
    handleCheck(index) {
      let type = this.$route.query.type;
      this.$router.push({
        path: "/hourlyDetail",
        query: {
          type,
          title: this.$route.query.title,
          date: this.$route.query.date,
          bTime: this.form.beginTime,
          eTime: this.form.endTime,
          index: index,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        },
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getList() {
      if (this.$route.query.date != "1") {
        if (this.form.beginTime != "") {
          this.form.beginTime = this.formateDate(this.form.beginTime);
        }
        if (this.form.endTime != "") {
          this.form.endTime = this.formateDate(this.form.endTime);
        }
        let data = {
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          title: this.$route.query.title,
          type: this.$route.query.date,
          bTime: this.form.beginTime,
          eTime: this.form.endTime,
        };
        getTimeSharingTable(data).then((res) => {
          console.log(res, "说大话");
          this.hourlyEnergy = res.rows;
          for (let i in this.hourlyEnergy) {
            this.hourlyEnergy[i].a1 = this.$division(this.hourlyEnergy[i].a1);
            this.hourlyEnergy[i].a2 = this.$division(this.hourlyEnergy[i].a2);
            this.hourlyEnergy[i].a3 = this.$division(this.hourlyEnergy[i].a3);
            this.hourlyEnergy[i].a4 = this.$division(this.hourlyEnergy[i].a4);
            this.hourlyEnergy[i].v = this.$division(this.hourlyEnergy[i].v);
          }
          this.total = res.total;
        });
      } else {
        let date = Date.now();
        console.log(this.formateDate(date), "单刷的还u");
        this.form.beginTime = this.formateDate(date);
        this.form.endTime = this.formateDate(date);
        let data = {
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          title: this.$route.query.title,
          type: this.$route.query.date,
          bTime: this.form.beginTime,
          eTime: this.form.endTime,
        };
        getTimeSharingTable(data).then((res) => {
          console.log(res, "说大话");
          this.hourlyEnergy = res.rows;
          this.total = res.total;
        });
      }
    },
    // 查询
    query() {
      if (this.$route.query.type == 14) {
        this.currentPage = 1;
        this.getList();
      } else {
        this.currentPage = 1;
        this.getPrice();
      }
    },
    // 重置
    reset() {
      if (this.$route.query.date == "2") {
        this.form.beginTime = "";
        this.form.endTime = "";
        this.getList();
      }
    },
    // 用能导出
    exportData() {
      if (this.form.beginTime != "") {
        this.form.beginTime = this.formateDate(this.form.beginTime);
      }
      if (this.form.endTime != "") {
        this.form.endTime = this.formateDate(this.form.endTime);
      }
      let data = {
        title: this.$route.query.title,
        type: this.$route.query.date,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
      };
      exportEleList(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
    // 电费
    getPrice() {
      if (this.$route.query.date != "1") {
        if (this.form.beginTime != "") {
          this.form.beginTime = this.formateDate(this.form.beginTime);
        }
        if (this.form.endTime != "") {
          this.form.endTime = this.formateDate(this.form.endTime);
        }
        let data = {
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          title: this.$route.query.title,
          type: this.$route.query.date,
          bTime: this.form.beginTime,
          eTime: this.form.endTime,
        };
        getTimeSharingPriceTable(data).then((res) => {
          console.log(res, "说大话");
          this.hourlyPrice = res.rows;
          for (let i in this.hourlyPrice) {
            this.hourlyPrice[i].a1 = this.$division(this.hourlyPrice[i].a1);
            this.hourlyPrice[i].a2 = this.$division(this.hourlyPrice[i].a2);
            this.hourlyPrice[i].a3 = this.$division(this.hourlyPrice[i].a3);
            this.hourlyPrice[i].a4 = this.$division(this.hourlyPrice[i].a4);
            this.hourlyPrice[i].v = this.$division(this.hourlyPrice[i].v);
          }
          this.total = res.total;
        });
      } else {
        let date = Date.now();
        console.log(this.formateDate(date), "单刷的还u");
        this.form.beginTime = this.formateDate(date);
        this.form.endTime = this.formateDate(date);
        let data = {
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          title: this.$route.query.title,
          type: this.$route.query.date,
          bTime: this.form.beginTime,
          eTime: this.form.endTime,
        };
        getTimeSharingPriceTable(data).then((res) => {
          console.log(res, "说大话");
          this.hourlyPrice = res.rows;
          this.total = res.total;
        });
      }
    },
    // 电费导出
    exportData() {
      if (this.form.beginTime != "") {
        this.form.beginTime = this.formateDate(this.form.beginTime);
      }
      if (this.form.endTime != "") {
        this.form.endTime = this.formateDate(this.form.endTime);
      }
      let data = {
        title: this.$route.query.title,
        type: this.$route.query.date,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
      };
      exportElePrice(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
  },
  mounted() {
    if (this.$route.query.type == 14) {
      this.getList();
    } else {
      this.getPrice();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromPage = from.path;
    });
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .back {
    position: absolute;
    top: 8vh;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
    z-index: 99;
  }
  .right-container {
    width: 75.5vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    .right-top {
      margin: 0 auto;
      width: 62.5vw;
      margin-bottom: 3vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        //   align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}

::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
.el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .el-pager li.active {
  color: #409eff;
}
</style>
