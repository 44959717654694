<template>
  <div class="container">
    <template v-if="zPageShow">
      <div class="container-form">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
        >
          <el-form-item label="电站名称：">
            <el-input
              v-model="formInline.title"
              placeholder="请输入电站名称"
              clearable=""
            ></el-input>
          </el-form-item>
          <el-form-item label="设备S/N：">
            <el-input
              v-model="formInline.sn"
              placeholder="请输入设备S/N"
              clearable=""
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="container-form-submit" @click="onSubmit">查询</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="container-checklist">
        <div
          class="container-checklist-init"
          :class="checkIndex == index ? 'container-checklist-change' : ''"
          v-for="(item, index) in checkList"
          :key="index"
          @click="clickCheckChange(item, index)"
        >
          {{ item.label }}({{ item.num }})
        </div>
      </div>
      <div class="container-table">
        <table border="1">
          <tr>
            <td v-for="(item, index) in tableTHList" :key="index">
              {{ item }}
            </td>
          </tr>
          <template v-if="tableList && tableList.length">
            <tr
              @click="clickRowD(item)"
              v-for="(item, index) in tableList"
              :key="index"
            >
              <td>{{ item.psName }}</td>
              <td>{{ item.itMeName }}</td>
              <td>{{ item.itName }}</td>
              <td>
                <span
                  v-if="[0, 1].includes(item.stateCode)"
                  class="container-table-type"
                ></span>
                <span
                  v-else-if="item.stateCode == 2"
                  class="container-table-type container-table-typeyell"
                ></span>
                <span
                  v-else
                  class="container-table-type container-table-typered"
                ></span>
              </td>
              <td>{{ item.itAmWattage }}</td>
              <td>{{ item.allP }}</td>
              <td>{{ item.todayPower }}</td>
              <td>{{ item.allPower }}</td>
              <td>{{ item.equivalentTime }}</td>
              <td>{{ item.date }}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="8">{{ tableEmptyText }}</td>
          </tr>
        </table>
      </div>
      <template v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next,total"
          :total="total"
        >
        </el-pagination>
      </template>
    </template>
    <template v-if="!zPageShow">
      <OverviewOfInverterseDetails :rowData="rowData" />
    </template>
  </div>
</template>

<script>
import {
  inverterPowerStationGET,
  powerStationCountGET,
} from "@/api/inverterAddition";
import OverviewOfInverterseDetails from "@/pages/OverviewOfInverterseDetails.vue";
export default {
  name: "OverviewOfInverterse",
  components: { OverviewOfInverterseDetails },
  data() {
    return {
      formInline: {},
      checkList: [
        {
          label: "全部",
          value: "",
          num: "",
        },
        {
          label: "正常",
          value: "",
          num: "",
        },
        {
          label: "异常",
          value: "",
          num: "",
        },
        {
          label: "离线",
          value: "",
          num: "",
        },
        // {
        //   label: "接入中",
        //   value: "",
        //   num: 1,
        // },
      ],
      checkIndex: 0,
      tableTHList: [
        "电站名称",
        "抄表器名称",
        "逆变器名称",
        "状态",
        "装机功率",
        "实时功率",
        "当日发电",
        "累计发电",
        "等效小时",
        "上报时间",
      ],
      rowData: {},
      tableEmptyText: "暂无数据",
      tableList: [],
      currentPage: 1,
      total: 0,
      pageSize: 8,
      zPageShow: true,
    };
  },

  mounted() {
    this.powerStationCountAxios();
    this.inverterPowerStationAxios();
  },

  methods: {
    async powerStationCountAxios() {
      let self = this;
      const data_back = await powerStationCountGET();
      console.log(data_back, "======powerStationCountGET");
      const { code, data } = data_back;
      if (code === 200) {
        self.$set(self.checkList[0], "num", data.allNum);
        self.$set(self.checkList[1], "num", data.runNum);
        self.$set(self.checkList[2], "num", data.stopNum);
        self.$set(self.checkList[3], "num", data.unknown);
      }
    },
    async inverterPowerStationAxios() {
      let self = this,
        sta;
      switch (self.checkIndex) {
        case 0:
          sta = "";
          break;
        case 1:
          sta = "1";
          break;
        case 2:
          sta = "2";
          break;
        case 3:
          sta = "0";
          break;
      }
      let params = {
        pageNum: self.currentPage,
        pageSize: self.pageSize,
        title: self.formInline.title,
        sn: self.formInline.sn,
        sta,
      };
      const data_back = await inverterPowerStationGET(params);
      console.log(data_back, "========inverterPowerStationGET");
      const { code, rows, total } = data_back;
      if (code === 200) {
        self.total = total;
        self.tableList = rows && rows.length ? rows : [];
      }
    },
    listInit(currentPage = 1, pageSize = 8, total = 0, tableList = []) {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.total = total;
      this.tableList = tableList;
      this.inverterPowerStationAxios();
    },
    onSubmit() {
      this.listInit();
    },
    clickCheckChange(row, index) {
      this.checkIndex = index;
      this.listInit();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.total = 0;
      this.tableList = [];
      this.inverterPowerStationAxios();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.total = 0;
      this.tableList = [];
      this.inverterPowerStationAxios();
    },
    clickRowD(row) {
      this.zPageShow = false;
      this.rowData = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1.0417vw /* 20px -> 1.0417vw */;
  &-form {
    &-submit {
      color: #02b5ff;
      font-size: 1.0417vw /* 20px -> 1.0417vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      background: url("~@/assets/icon/icon1.png") no-repeat;
      background-size: 100% 100%;
      width: 7.2917vw /* 140px -> 7.2917vw */;
      height: 2.6042vw /* 50px -> 2.6042vw */;
      line-height: 2.6042vw /* 50px -> 2.6042vw */;
      text-align: center;
      cursor: pointer;
      margin-left: 6.5104vw /* 125px -> 6.5104vw */;
    }
  }
  &-checklist {
    display: flex;
    flex-wrap: wrap;
    &-init {
      color: #02b5ff;
      font-size: 1.0417vw /* 20px -> 1.0417vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      background: url("~@/assets/icon/icon1.png") no-repeat;
      background-size: 100% 100%;
      width: 7.2917vw /* 140px -> 7.2917vw */;
      height: 2.6042vw /* 50px -> 2.6042vw */;
      line-height: 2.6042vw /* 50px -> 2.6042vw */;
      text-align: center;
      cursor: pointer;
      margin-right: 1.0417vw /* 20px -> 1.0417vw */;
    }
    &-change {
      color: #ffffff !important;
    }
    &-init:last-child {
      margin-right: 0;
    }
  }
  &-table {
    width: 100%;
    margin: 2.0833vw /* 40px -> 2.0833vw */ 0px;
    table {
      width: 100%;
      box-shadow: inset 0px 0px 1.0417vw /* 20px -> 1.0417vw */ 1.0417vw
        /* 20px -> 1.0417vw */ rgba(15, 53, 116, 0.6);
      border: 1px solid #286ae8;
      border-collapse: collapse;
      text-align: center;
      font-size: 0.8333vw /* 16px -> .8333vw */;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #ffffff;
      td {
        padding-top: 0.8333vw /* 16px -> .8333vw */;
        padding-bottom: 0.8333vw /* 16px -> .8333vw */;
      }
      tr {
        cursor: pointer;
      }
    }
    &-type {
      display: inline-block;
      width: 1.1458vw /* 22px -> 1.1458vw */;
      height: 1.1458vw /* 22px -> 1.1458vw */;
      border-radius: 50%;
      background-color: #6dd400;
    }
    &-typered {
      background-color: #f00;
    }
    &-typeyell {
      background-color: yellow;
    }
  }
}

::v-deep .el-form-item {
  margin-right: 2.6042vw /* 50px -> 2.6042vw */;
  .el-select-dropdown__item .selected,
  .el-select-dropdown__item {
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    width: 100%;
    height: 2.6042vw /* 50px -> 2.6042vw */;
    font-size: calc(100vw * 16 / 1920);
    box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
    border-radius: 0.1rem;
    border: 0.02rem solid #286ae8;
    background: none;
    color: #fff;
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
  .el-form-item__label {
    color: #fff;
    width: 7.5vw;
    height: 2.6042vw /* 50px -> 2.6042vw */;
    line-height: 2.6042vw /* 50px -> 2.6042vw */;
    text-align: left;
    font-size: calc(100vw * 16 / 1920);
  }
}

::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  .el-select,
  .btn-prev,
  .btn-next,
  .el-pagination__total {
    box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
    border-radius: 0.1rem;
    border: 0.02rem solid #286ae8;
    background: none;
    color: #fff;
  }
  .el-pager li {
    box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
    border-radius: 0.1rem;
    border: 0.02rem solid #286ae8;
    background: none;
    .active {
      color: pink !important;
    }
  }
  .el-pager li.active {
    color: #0855b3;
  }
  .number {
    color: #fff;
    margin-right: 0.8vw;
    .active {
      color: pink;
    }
  }
  .el-input {
    width: 7vw;
  }
  .more,
  .btn-prev,
  .btn-next {
    color: #fff;
    margin-right: 0.8vw;
  }
  .el-input .el-input--mini .el-input--suffix {
    background: none;
  }
  .el-input__inner {
    background: none;
    border: none;
    color: #fff;
  }
}
</style>
