<template>
  <div class="container">
    <div class="container-title">基础信息</div>
    <div class="container-table">
      <table border="1">
        <tr>
          <td>数据更新时间</td>
          <td>{{ pageData.dt }}</td>
          <td>错误信息</td>
          <td>{{ pageData.errorInfo }}</td>
          <td>运行状态</td>
          <td>{{ pageData.runState }}</td>
        </tr>
        <tr>
          <td>今日发电量</td>
          <td>{{ pageData.todayPower }}度</td>
          <td>累计发电量</td>
          <td>{{ pageData.allPower }}度</td>
          <td>累计发电小时</td>
          <td>{{ pageData.allTime }}小时</td>
        </tr>
        <tr>
          <td>A相电压</td>
          <td>{{ pageData.ru }}伏</td>
          <td>B相电压</td>
          <td>{{ pageData.su }}伏</td>
          <td>C相电压</td>
          <td>{{ pageData.tu }}伏</td>
        </tr>
        <tr>
          <td>A相电流</td>
          <td>{{ pageData.rl }}安</td>
          <td>B相电流</td>
          <td>{{ pageData.sl }}安</td>
          <td>C相电流</td>
          <td>{{ pageData.tl }}安</td>
        </tr>
        <tr>
          <td>A相频率</td>
          <td>{{ pageData.rf }}赫兹</td>
          <td>B相频率</td>
          <td>{{ pageData.sf }}赫兹</td>
          <td>C相频率</td>
          <td>{{ pageData.tf }}赫兹</td>
        </tr>
        <tr>
          <td>输出功率</td>
          <td>{{ pageData.allP }}千瓦</td>
          <td>机器内部温度</td>
          <td>{{ pageData.sf }}度</td>
          <td>品牌</td>
          <td>{{ pageData.type }}</td>
        </tr>
        <tr>
          <td>总视在功率</td>
          <td>{{ pageData.es }}VA</td>
          <td>总有功功率</td>
          <td>{{ pageData.ep }}W</td>
          <td>总无功功率</td>
          <td>{{ pageData.eq }}Var</td>
        </tr>
        <tr>
          <td>总功率因数</td>
          <td>{{ pageData.epf }}</td>
          <td>绝缘阻抗</td>
          <td>{{ pageData.ir }}kΩ</td>
          <td>漏电流</td>
          <td>{{ pageData.lc }}mA</td>
        </tr>
        <tr>
          <td>逆变器功率</td>
          <td>{{ pageData.einv }}%</td>
          <td>故障码</td>
          <td>{{ pageData.errVal }}</td>
          <td>母线电压</td>
          <td>{{ pageData.bv }}伏</td>
        </tr>
        <tr>
          <td>负极对地电压</td>
          <td colspan="5">{{ pageData.gndV }}伏</td>
        </tr>
      </table>
    </div>
    <div class="container-subtitle">MPPT信息</div>
    <div
      class="container-table container-tablea"
      v-if="pageData.strArray && pageData.strArray.length"
    >
      <table border="1">
        <tr>
          <td></td>
          <td>电压</td>
          <td>电流</td>
        </tr>
        <tr
          v-for="(item, index) in pageData.strArray.slice(0, 16)"
          :key="index"
        >
          <td>直流电压/电流{{ index + 1 }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[0] : "" }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[1] : "" }}</td>
        </tr>
      </table>
      <table border="1">
        <tr>
          <td></td>
          <td>电压</td>
          <td>电流</td>
        </tr>
        <tr
          v-for="(item, index) in pageData.strArray.slice(16, 32)"
          :key="index"
        >
          <td>直流电压/电流{{ index + 16 + 1 }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[0] : "" }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[1] : "" }}</td>
        </tr>
      </table>
    </div>
    <div class="container-title">组串信息</div>
    <div class="container-subtitle">MPPT信息</div>
    <div
      class="container-table container-tableb"
      v-if="pageData.groupIArray && pageData.groupIArray.length"
    >
      <table border="1">
        <tr>
          <td></td>
          <td>电流</td>
        </tr>
        <tr
          v-for="(item, index) in pageData.groupIArray.slice(0, 16)"
          :key="index"
        >
          <td>组串电流{{ index + 1 }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[0] : "" }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[1] : "" }}</td>
        </tr>
      </table>
      <table border="1">
        <tr>
          <td></td>
          <td>电流</td>
        </tr>
        <tr
          v-for="(item, index) in pageData.groupIArray.slice(16, 32)"
          :key="index"
        >
          <td>组串电流{{ index + 16 + 1 }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[0] : "" }}</td>
          <td>{{ item && item.split("/") ? item.split("/")[1] : "" }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { adapterInverterRealtimeDataGET } from "@/api/inverterAddition";
export default {
  name: "OverviewOfInverterseDetailsOverView",
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      pageData: {},
    };
  },

  mounted() {
    this.adapterInverterRealtimeDataAxios();
  },

  methods: {
    async adapterInverterRealtimeDataAxios() {
      let self = this;
      let params = {
        sn: self.rowData.itSlaveId,
      };
      const data_back = await adapterInverterRealtimeDataGET(params);
      console.log(data_back, "========adapterInverterRealtimeDataGET");
      const { code, data } = data_back;
      if (code === 200) {
        self.pageData = data || {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1.0417vw /* 20px -> 1.0417vw */ 0;
  &-title,
  &-subtitle {
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    font-size: 1.4583vw /* 28px -> 1.4583vw */;
    margin-bottom: 2.0833vw /* 40px -> 2.0833vw */;
  }
  &-title {
    color: #02b5ff;
  }
  &-subtitle {
    color: #ffffff;
  }
  &-table {
    width: 100%;
    margin: 2.0833vw /* 40px -> 2.0833vw */ 0px;
    display: flex;
    flex-wrap: wrap;
    table {
      width: 100%;
      box-shadow: inset 0px 0px 1.0417vw /* 20px -> 1.0417vw */ 1.0417vw
        /* 20px -> 1.0417vw */ rgba(15, 53, 116, 0.6);
      border: 1px solid #286ae8;
      border-collapse: collapse;
      text-align: center;
      font-size: 0.8333vw /* 16px -> .8333vw */;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #ffffff;
      td {
        padding-top: 0.8333vw /* 16px -> .8333vw */;
        padding-bottom: 0.8333vw /* 16px -> .8333vw */;
      }
    }
  }
  &-tablea {
    table {
      width: 23.4375vw /* 450px -> 23.4375vw */;
      margin: 0 2.0833vw /* 40px -> 2.0833vw */ 2.0833vw /* 40px -> 2.0833vw */
        0;
      td {
        width: 33.3%;
      }
    }
  }
  &-tableb {
    table {
      width: 15.7292vw /* 302px -> 15.7292vw */;
      margin: 0 11.75rem /* 188px -> 11.75rem */ 2.0833vw /* 40px -> 2.0833vw */
        0;
      td {
        width: 50%;
      }
    }
  }
}
</style>
