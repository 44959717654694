<template>
  <div class="container">
    <div class="left-title" v-show="type == 12">总用能排行</div>
    <div class="container-title" v-show="type == 12">总用能排行</div>
    <div class="left-title" style="width: 12.6vw" v-show="type == 13">
      市电太阳能用能台账
    </div>
    <div class="container-title" v-show="type == 13">市电太阳能用能台账</div>
    <div class="left-title" v-show="type == 1">电站离线率</div>
    <div class="container-title" v-show="type == 1">电站离线率</div>
    <div class="left-title" v-show="type == 2">节点离线时长</div>
    <div class="container-title" v-show="type == 2">节点离线时长</div>
    <div class="left-title" v-show="type == 15">分时电费</div>
    <div class="container-title" v-show="type == 15">分时电费</div>
    <div class="left-title" v-show="type == 4">发电量对比</div>
    <div class="container-title" v-show="type == 4">发电量对比</div>
    <div class="left-title" v-show="type == 5">功率</div>
    <div class="container-title" v-show="type == 5">功率</div>
    <div class="left-title" v-show="type == 6">发电量</div>
    <div class="container-title" v-show="type == 6">发电量</div>
    <div class="left-title" v-show="type == 18">逆变器</div>
    <div class="container-title" v-show="type == 18">逆变器发电量对比</div>
    <div class="left-title" v-show="type == 7">组串离散率</div>
    <div class="container-title" v-show="type == 7">逆变器组串离散率排行</div>
    <div class="left-title" style="width: 12.6vw" v-show="type == 9">
      逆变器等效时长
    </div>
    <div class="container-title" v-show="type == 9">
      同一电站逆变器等效小时对比
    </div>
    <div class="left-title" v-show="type == 19">直流电流</div>
    <div class="container-title" v-show="type == 19">
      同一逆变器的各组串直流电流对比
    </div>
    <div class="left-title" v-show="type == 20">直流电压</div>
    <div class="container-title" v-show="type == 20">
      同一逆变器的各组串直流电压对比
    </div>
    <div class="left-title" v-show="type == 21">损耗分析</div>
    <div class="container-title" v-show="type == 21">损耗分析</div>
    <div class="left-title" v-show="type == 8">逆变器温度</div>
    <div class="container-title" v-show="type == 8">逆变器温度对比</div>
    <div class="left-title" v-show="type == 3">发电量统计</div>
    <div class="container-title" v-show="type == 3">发电量统计</div>
    <div
      class="select"
      v-show="
        type == 14 ||
        type == 15 ||
        type == 2 ||
        type == 21 ||
        type == 19 ||
        type == 20 ||
        type == 4 ||
        type == 5 ||
        type == 6 ||
        type == 18 ||
        type == 8 ||
        type == 9 ||
        type == 7 ||
        type == 1 || 
        type == 12 ||
        type == 13
      "
    >
      <el-form :model="form">
        <el-form-item label="电站切换：" v-show="type != 4 && type != 1 && type != 12 && type != 13">
          <el-select
            filterable
            v-model="form.area"
            placeholder="请选择电站"
            style="width: 15.6vw; margin-right: 2vw"
            @change="changeOption"
          >
            <el-option
              v-for="(item, index) in stationList"
              :key="index"
              :label="item.psName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型：" v-show="type == 14 || type == 15">
          <el-select
            v-show="type == 14 || type == 15"
            v-model="form.type"
            placeholder="请选择类型"
            style="width: 15.6vw; margin-right: 2vw"
            @change="changeType"
          >
            <el-option label="总电表" value="1"></el-option>
            <el-option label="光伏电表" value="2"></el-option> </el-select
        ></el-form-item>
        <el-form-item label="逆变器：" v-show="type == 19 || type == 20">
          <el-select
            filterable
            v-model="form.inverter"
            placeholder="请选择逆变器"
            style="width: 15.6vw; margin-right: 2vw"
            @change="changeInverter"
          >
            <el-option
              v-for="(item, index) in inverterList"
              :key="index"
              :label="item.itName"
              :value="item.itSlaveId"
            ></el-option> </el-select
        ></el-form-item>
        <el-form-item
          label="日期："
          v-show="
            type == 21 ||
            type == 19 ||
            type == 20 ||
            type == 18 ||
            type == 8 ||
            type == 7 ||
            ((type == 14 || type == 15 || type == 1 || type ==2 || type == 6 || type == 4 || type == 12 || type == 13 || type == 9) && dateIndex == 0)
          "
        >
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form.time"
            type="date"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-show="
            (type == 14 || type == 15 || type == 1 || type == 2 || type == 6 || type == 4 || type == 12 || type == 13 || type == 9) && dateIndex == 1
          "
          label="日期："
        >
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form.time"
            type="month"
            format="yyyy-MM"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-show="(type == 14 || type == 15 || type == 1 || type == 2 || type == 6 || type == 4 || type == 12 || type == 13 || type == 9) && dateIndex == 2"
          label="日期："
        >
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form.time"
            type="year"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-show="type == 4" label="区域(省市县)：">
          <v-distpicker
            @province="provinceChange"
            @city="cityChange"
            @area="areaChange"
            :province="form.province"
            :city="form.city"
            :area="form.area"
            style="margin-right: 1vw;"
          ></v-distpicker>
        </el-form-item>
        <el-form-item
          label="容量："
          v-show="
            type == 4 ||
            type == 5 ||
            type == 18 ||
            type == 8 ||
            type == 9 ||
            type == 7
          "
        >
          <el-input
            v-model="form.capacity"
            placeholder="请输入容量"
            style="width: 8vw"
          ></el-input>
        </el-form-item>
        <el-button
          v-show="
            type == 4 ||
            type == 5 ||
            type == 18 ||
            type == 8 ||
            type == 9 ||
            type == 7
          "
          size="mini"
          style="margin-left: 3vw; margin-top: 0.5vh;height:100%;font-size:0.7vw"
          @click="searchList"
          >搜索</el-button
        >
      </el-form>
    </div>
    <div class="left-title" v-show="type == 14">分时用能</div>
    <div class="container-title" v-show="type == 14">分时用能</div>
    <div
      class="bottom-two-btn"
      v-show="
        type != 21 &&
        type != 19 &&
        type != 20 &&
        type != 5 &&
        type != 8 &&
        type != 7 &&
        type != 3 &&
        type != 18 
      "
    >
      <div
        v-for="(item, index) in btnList"
        :key="index"
        :class="dateIndex == index ? 'btn-content active' : 'btn-content'"
        @click="changeDate(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="echart"
      v-show="type != 13 && type != 14 && type != 15 && type != 3 && type != 1"
    >
      <div
        id="oneChart"
        style="width: 100%; height: 100%"
        @click="goNext"
      ></div>
    </div>
    <div
      class="echart"
      style="height: 48vh"
      v-show="type == 13"
      @click="goSunEnergy"
    >
      <div id="twoChart" style="width: 100%; height: 100%"></div>
    </div>
    <div class="echart" v-show="type == 1 || type == 3 || type == 2">
      <div
        id="threeChart"
        style="width: 100%; height: 100%"
        @click="goPower"
      ></div>
    </div>
    <!-- <div class="echart">
      <div id="fourChart" style="width: 100%; height: 100%"></div>
    </div> -->
    <div
      class="echart"
      style="height: 48vh; width: 84vw"
      v-show="type == 14 || type == 15"
      @click="goHourly"
    >
      <div id="fiveChart" style="width: 100%; height: 100%"></div>
    </div>
    <!-- <div class="echart" style="height:32vh">
      <div id="sixChart" style="width: 100%; height: 100%"></div>
    </div> -->
  </div>
</template>

<script>
import {
  getTimeSharing,
  getStationList,
  getElePrice,
  getTotalEle,
  getLossAnalysisChart,
  getInverterList,
  getVoltAmmeter,
  getVoltAmmeterV,
  getSameAreaEle,
  getPower,
  getInverterPower,
  getInverterTemperature,
  getInverterHour,
  getInverterDispersionRate,
} from "@/api/dataAnalysis";
import { getElePic, getStationEle_ } from "@/api/green";
import { getStationOffLine, getNodeOffLine } from "@/api/home";
import VDistpicker from "v-distpicker";
export default {
  name: "rankingTotalEnergy",
  components: { VDistpicker },
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      windowWidth: 1920,
      windowHeight: 1080,
      btnList: [
        { name: "日", value: "1" },
        { name: "月", value: "2" },
        { name: "年", value: "3" },
        { name: "历年", value: "4" },
      ],
      dateIndex: 0,
      form: {
        area: "", // 电站切换
        type: "",
        time: "2021",
        inverter: "", // 逆变器
        capacity: "", // 容量
        province: "",
        city: "",
        area: "",
        date: "",
      },
      type: "", // 传过来的参数判断显示哪个图
      stationList: [], // 电站列表
      inverterList: [], // 电站列表
      price: {
        jianPrice: "",
        fengPrice: "",
        pingPrice: "",
        guPrice: "",
      },
      psId: "",
    };
  },
  methods: {
    provinceChange(data) {
      //省
      console.log(data);
      this.form.province = data.value;
      this.form.city = "";
      this.form.area = "";
    },
    cityChange(data) {
      //市
      this.form.city = data.value;
      this.form.area = "";
    },
    areaChange(data) {
      //区、县
      this.form.area = data.value;
    },
    searchList() {
      this.getEchartOne();
    },
    // 切换逆变器
    changeInverter() {
      this.getEchartOne();
    },
    // 切换日期
    specifyPostingDateChange(val) {
      if (
        (this.type == 14 || this.type == 15 || this.type == 1 || this.type == 2 || this.type == 6 || this.type == 4 || this.type == 12 || this.type == 13 || this.type == 9) &&
        this.dateIndex == 0
      ) {
        val = this.formateDate(val);
      } else if (
        (this.type == 14 || this.type == 15 || this.type == 1 || this.type == 2 || this.type == 6 || this.type == 4 || this.type == 12 || this.type == 13 || this.type == 9) &&
        this.dateIndex == 1
      ) {
        val = this.formateMonth(val);
      } else if (
        (this.type == 14 || this.type == 15 || this.type == 1 || this.type == 2 || this.type == 6 || this.type == 4 || this.type == 12 || this.type == 13 || this.type == 9) &&
        this.dateIndex == 2
      ) {
        console.log(val, "四大怀湖底");
      } else {
        val = this.formateDate(val);
      }
      this.form.time = val;
      console.log(111111);
      if ((this.type != 14 && this.type != 15 && this.type != 1 && this.type != 2) || this.type == 6 || this.type == 12 || this.type == 9) {
        this.getEchartOne();
      } else if(this.type == 1 || this.type == 2) {
        this.getEchartThree()
      }else if(this.type == 13) {
        this.getEchartTwo()
      }else {
        this.getEchartFive();
      }
    },
    // 切换电站
    changeOption() {
      if (this.type == 14 || this.type == 15) {
        this.getEchartFive();
      } else if (
        this.type == 21 ||
        this.type == 19 ||
        this.type == 20 ||
        this.type == 4 ||
        this.type == 5 ||
        this.type == 6 ||
        this.type == 18 ||
        this.type == 8 ||
        this.type == 9 ||
        this.type == 7
      ) {
        console.log(1111111111);
        this.form.inverter = "";
        for (let i in this.stationList) {
          if (this.form.area == this.stationList[i].id) {
            this.psId = this.stationList[i].id;
            console.log(this.psId, "摔");
            getInverterList(this.psId).then((res) => {
              this.inverterList = res.data;
            });
          }
        }
        this.getEchartOne();
      } else if (this.type == 13) {
        this.getEchartTwo();
      } else {
        this.getEchartThree();
      }
    },
    changeType() {
      this.getEchartFive();
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    changeDate(index) {
      this.dateIndex = index;
      if (this.type == 14 || this.type == 15) {
        this.getEchartFive();
      } else if (
        this.type == 12 ||
        this.type == 4 ||
        this.type == 6 ||
        this.type == 18 ||
        this.type == 9
      ) {
        this.getEchartOne();
      } else if (this.type == 13) {
        this.getEchartTwo();
      } else if (this.type == 1 || this.type == 2) {
        this.getEchartThree();
      }
    },
    getEchartOne() {
      let myChart1 = this.$echarts.init(document.getElementById("oneChart"));
      if (this.dateIndex == 1) {
          this.form.date = this.formateMonth(this.form.time);
        } else if (this.dateIndex == 2) {
          this.form.date = this.formateYear(this.form.time);
          console.log(this.form.time, "当年");
        } else if (this.dateIndex == 0) {
          this.form.date = this.formateDate(this.form.time);
        }
      if (this.type == 12) {
        let type = this.btnList[this.dateIndex].value;
        getTotalEle(type,this.form.date).then((res) => {
          console.log(res, "使得靜安寺哦");
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].sumTe);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 21) {
        let bTime = this.form.time;
        let psId = this.form.area;
        getLossAnalysisChart(bTime, psId).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].mrMeName);
            val.push(res.data[i].lose);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 19) {
        let bTime = this.form.time;
        let sn = this.form.inverter;
        let type = "1";
        getVoltAmmeter(bTime, sn, type).then((res) => {
          console.log(res, "ws");
          let header = [];
          let val = [];
          for (let i in res.rows[0].ham1) {
            header.push(res.rows[0].ham1[i].type);
            val.push(res.rows[0].ham1[i].value);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "A";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "A",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 20) {
        let bTime = this.form.time;
        let sn = this.form.inverter;
        getVoltAmmeterV(bTime, sn).then((res) => {
          console.log(res, "ws");
          let header = [];
          let val = [];
          for (let i in res.rows[0].ham2) {
            header.push(res.rows[0].ham2[i].type);
            val.push(res.rows[0].ham2[i].value);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "V";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "V",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 4) {
        let type = this.btnList[this.dateIndex].value;
        let date = new Date();
        date = this.formateDate(date);
        let data = {
          type: type,
          psCapacity: this.form.capacity,
          dateStr: this.form.date,
          province: this.form.province,
          area: this.form.area,
          city: this.form.city,
        };
        getSameAreaEle(data).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].num);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw/h";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw/h",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 5) {
        let date = new Date();
        date = this.formateDate(date);
        let data = {
          bTime: date,
          psCapacity: this.form.capacity,
          psId: this.form.area,
        };
        getPower(data).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].num);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 6) {
        let type = this.btnList[this.dateIndex].value;
        let date = new Date();
        date = this.formateDate(date);
        let header = [];
        let val = [];
        let data = {
          psId: this.form.area,
          type: type,
          psCapacity: this.form.capacity,
          dateStr: this.form.date,
        };
        getStationEle_(data).then((res) => {
          for (let i in res.data) {
            header.push(res.data[i].hu);
            val.push(res.data[i].num);
            console.log(header, "大祭司熬多久哦啊是");
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw/h";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw/h",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
        // });
      } else if (this.type == 18) {
        let type = this.btnList[this.dateIndex].value;
        let date = "";
        if (type != "1") {
          type = "2";
        } else {
          type = "1";
        }
        let data = {
          type: type,
          itCapacity: this.form.capacity,
          bTime: this.form.time,
          psId: this.form.area,
        };
        getInverterPower(data).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].num);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw/h";
              },
            },
            xAxis: [
              {
                type: "category",

                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw/h",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 8) {
        let data = {
          psId: this.form.area,
          bTime: this.form.time,
          itCapacity: this.form.capacity,
        };
        getInverterTemperature(data).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].num);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "℃";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "℃",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 9) {
        let data = {
          psId: this.form.area,
          dateStr: this.form.date,
          itCapacity: this.form.capacity,
          type:this.btnList[this.dateIndex].value
        };
        getInverterHour(data).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].num);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "h";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "h",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 7) {
        let data = {
          psId: this.form.area,
          bTime: this.form.time,
          itCapacity: this.form.capacity,
        };
        getInverterDispersionRate(data).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.rows) {
            header.push(res.rows[i].psName);
            val.push(res.rows[i].num);
          }
          myChart1.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "%";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "%",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                barGap: 0,
                barWidth: 20,
                itemStyle: {
                  color: "#06B4FC",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      }
      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    },
    getEchartTwo() {
      console.log(111111);
      let myChart2 = this.$echarts.init(document.getElementById("twoChart"));
      if (this.dateIndex == 1) {
          this.form.date = this.formateMonth(this.form.time);
        } else if (this.dateIndex == 2) {
          this.form.date = this.formateYear(this.form.time);
          console.log(this.form.time, "当年");
        } else if (this.dateIndex == 0) {
          this.form.date = this.formateDate(this.form.time);
        }
      if (this.type == 13) {
        let type = this.btnList[this.dateIndex].value;
        getTotalEle(type,this.form.date).then((res) => {
          console.log(1111111);
          let header = [];
          let val = [];
          let val2 = [];
          let val3 = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].sumTe);
            val2.push(res.data[i].ste);
            val3.push(res.data[i].tte);
          }
          myChart2.setOption({
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["市电", "太阳能", "总耗电"],
              icon: "rect",
              itemHeight: 5,
              itemGap: 40,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                name: "kw",
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                // yAxisIndex: 0,
                name: "市电",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#06B4FC",
                },
                emphasis: {
                  focus: "series",
                },
                data: val2,
              },
              {
                type: "bar",
                // yAxisIndex: 0,
                name: "太阳能",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#286AE8",
                },
                emphasis: {
                  focus: "series",
                },
                data: val3,
              },
              {
                name: "总耗电",
                type: "line",
                itemStyle: {
                  color: "#A927A9",
                },
                symbol: "none",
                // yAxisIndex: 1,
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(70),
              top: this.nowHeightSize(50),
              right: this.nowWidthSize(48),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      }
      window.addEventListener("resize", function () {
        myChart2.resize();
      });
    },
    getEchartThree() {
      let myChart3 = this.$echarts.init(document.getElementById("threeChart"));
      if (this.dateIndex == 1) {
          this.form.date = this.formateMonth(this.form.time);
        } else if (this.dateIndex == 2) {
          this.form.date = this.formateYear(this.form.time);
          console.log(this.form.time, "当年");
        } else if (this.dateIndex == 0) {
          this.form.date = this.formateDate(this.form.time);
        }
      if (this.type == 1) {
        let type = this.btnList[this.dateIndex].value;
        getStationOffLine(type, this.form.date).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].psName);
            val.push(res.data[i].rank);
          }
          myChart3.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "%";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "%",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "line",
                symbol: "none",
                itemStyle: {
                  color: "#5B8FF9",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(50),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 2) {
        let type = this.btnList[this.dateIndex].value;
        getNodeOffLine(type, this.form.area,this.form.date).then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].ndName);
            val.push(res.data[i].offSum);
          }
          myChart3.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "%";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "小时",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "line",
                symbol: "none",
                itemStyle: {
                  color: "#5B8FF9",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(50),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else if (this.type == 3) {
        getElePic().then((res) => {
          let header = [];
          let val = [];
          for (let i in res.data) {
            header.push(res.data[i].hu);
            val.push(res.data[i].num);
          }
          myChart3.setOption({
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params[0].name + "：" + params[0].data + "kw";
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  show: false,
                  //   lineStyle: {
                  //     color: "#0B3A56",
                  //   },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: header,
              },
            ],
            yAxis: [
              {
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                name: "kw/h",
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "line",
                symbol: "none",
                itemStyle: {
                  color: "#5B8FF9",
                },
                data: val,
              },
            ],
            grid: {
              left: this.nowWidthSize(50),
              top: this.nowHeightSize(30),
              right: this.nowWidthSize(25),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      }
      window.addEventListener("resize", function () {
        myChart3.resize();
      });
    },
    getEchartFive() {
      console.log(this.btnList[this.dateIndex], "手段胡");
      let myChart5 = this.$echarts.init(document.getElementById("fiveChart"));
      if (this.dateIndex == 1) {
        this.form.date = this.formateMonth(this.form.time);
      } else if (this.dateIndex == 2) {
        this.form.date = this.formateYear(this.form.time);
        console.log(this.form.time, "当年");
      } else if (this.dateIndex == 0) {
        this.form.date = this.formateDate(this.form.time);
      }
      // if(this.dateIndex != 2){
      //   this.form.date = this.form.time
      // }else {
      //   this.form.date = this.formateYear(this.form.time)
      // }
      let data = {
        psId: this.form.area,
        type: this.btnList[this.dateIndex].value,
        mType: this.form.type,
        dateStr: this.form.date,
      };
      if (this.type == 14) {
        getTimeSharing(data).then((res) => {
          var xContent = res.data.header;
          var jian = res.data.val[1].data;
          var feng = res.data.val[2].data;
          var ping = res.data.val[3].data;
          var gu = res.data.val[4].data;
          myChart5.setOption({
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["尖", "峰", "平", "谷"],
              icon: "rect",
              itemHeight: 5,
              itemGap: 40,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: xContent,
              },
            ],
            yAxis: [
              {
                name: "kw",
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                name: "尖",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#06B4FC",
                },
                emphasis: {
                  focus: "series",
                },
                data: jian,
              },
              {
                type: "bar",
                name: "峰",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#286AE8",
                },
                emphasis: {
                  focus: "series",
                },
                data: feng,
              },
              {
                type: "bar",
                name: "平",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#A927A9",
                },
                emphasis: {
                  focus: "series",
                },
                data: ping,
              },
              {
                type: "bar",
                name: "谷",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#FE2727",
                },
                emphasis: {
                  focus: "series",
                },
                data: gu,
              },
            ],
            grid: {
              left: this.nowWidthSize(75),
              top: this.nowHeightSize(50),
              right: this.nowWidthSize(30),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      } else {
        getElePrice(this.form.area).then((res) => {
          this.price.jianPrice = res.data.epTipPrice;
          this.price.fengPrice = res.data.epPeakPrice;
          this.price.pingPrice = res.data.epFlatPrice;
          this.price.guPrice = res.data.epValleyPrice;
        });
        getTimeSharing(data).then((res) => {
          var xContent = res.data.header;
          var jian = res.data.val[0].data;
          for (let i in jian) {
            jian[i] = jian[i] * this.price.jianPrice;
          }
          var feng = res.data.val[1].data;
          for (let i in feng) {
            feng[i] = feng[i] * this.price.fengPrice;
          }
          var ping = res.data.val[2].data;
          for (let i in ping) {
            ping[i] = ping[i] * this.price.pingPrice;
          }
          var gu = res.data.val[3].data;
          for (let i in gu) {
            gu[i] = gu[i] * this.price.guPrice;
          }
          myChart5.setOption({
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["尖", "峰", "平", "谷"],
              icon: "rect",
              itemHeight: 5,
              itemGap: 40,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                axisTick: { show: false },
                axisLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#fff",
                },
                data: xContent,
              },
            ],
            yAxis: [
              {
                name: "元",
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                nameTextStyle: {
                  color: "#06B4FC",
                },
                type: "value",
                splitLine: {
                  lineStyle: {
                    color: "#0B3A56",
                  },
                },
                axisLabel: {
                  color: "#06B4FC",
                },
              },
            ],
            series: [
              {
                type: "bar",
                name: "尖",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#06B4FC",
                },
                emphasis: {
                  focus: "series",
                },
                data: jian,
              },
              {
                type: "bar",
                name: "峰",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#286AE8",
                },
                emphasis: {
                  focus: "series",
                },
                data: feng,
              },
              {
                type: "bar",
                name: "平",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#A927A9",
                },
                emphasis: {
                  focus: "series",
                },
                data: ping,
              },
              {
                type: "bar",
                name: "谷",
                barGap: 0,
                barWidth: 12,
                itemStyle: {
                  color: "#FE2727",
                },
                emphasis: {
                  focus: "series",
                },
                data: gu,
              },
            ],
            grid: {
              left: this.nowWidthSize(75),
              top: this.nowHeightSize(50),
              right: this.nowWidthSize(30),
              bottom: this.nowHeightSize(25),
            },
          });
        });
      }
      window.addEventListener("resize", function () {
        myChart5.resize();
      });
    },
    goNext() {
      let type = this.type;
      let title = "";
      for (let i in this.stationList) {
        if (this.form.area == this.stationList[i].id) {
          title = this.stationList[i].psName;
          console.log(title, "世界大会");
        }
      }
      let date = new Date();
      date = this.formateDate(date);
      if (type == 4) {
        if (this.btnList[this.dateIndex].value == "1") {
          this.$router.push({
            path: "/comparisonOfPowerTable",
            query: { type, date: 1, psCapacity: this.form.capacity },
          });
        } else {
          this.$router.push({
            path: "/comparisonOfPowerTable",
            query: { type, date: 2, psCapacity: this.form.capacity },
          });
        }
      } else if (type == 5) {
        this.$router.push({
          path: "/power",
          query: {
            type,
            date: date,
            psCapacity: this.form.capacity,
            title: title,
          },
        });
      } else if (type == 6) {
        if (this.btnList[this.dateIndex].value == "1") {
          this.$router.push({
            path: "/power",
            query: {
              type,
              date: "1",
              time: date,
              psId: this.form.area,
            },
          });
        } else {
          this.$router.push({
            path: "/power",
            query: {
              type,
              date: "2",
              psCapacity: this.form.capacity,
              time: date,
              psId: this.form.area,
            },
          });
        }
      } else if (type == 18) {
        if (this.btnList[this.dateIndex].value == "1") {
          this.$router.push({
            path: "/inverterPowerGeneration",
            query: {
              type,
              bTime: this.form.time,
              date: "1",
              title: title,
              itCapacity: this.form.capacity,
            },
          });
        } else {
          this.$router.push({
            path: "/inverterPowerGeneration",
            query: {
              type,
              bTime: this.form.time,
              date: "2",
              itCapacity: this.form.capacity,
            },
          });
        }
      } else if (type == 7 || type == 9) {
        this.$router.push({
          path: "/stringOfflineRate",
          query: {
            type,
            bTime: this.form.time,
            title: title,
            itCapacity: this.form.capacity,
          },
        });
      } else if (type == 8) {
        this.$router.push({
          path: "/inverterTemperature",
          query: {
            type,
            title: title,
            bTime: this.form.time,
            itCapacity: this.form.capacity,
          },
        });
      } else if (type == 19 || type == 20) {
        this.$router.push({
          path: "/directPower",
          query: {
            type,
            bTime: this.form.time,
            inverter: this.form.inverter,
            psId: this.form.area,
            fromType: "2",
          },
        });
      } else if (type == 21) {
        this.$router.push({
          path: "/lossAnalysis",
          query: { type, title: title },
        });
      } else if (type == 12) {
        if (this.btnList[this.dateIndex].value == "1") {
          this.$router.push({
            path: "/totalEnergyConsumption",
            query: { type, date: "1" },
          });
        } else {
          this.$router.push({
            path: "/totalEnergyConsumption",
            query: { type, date: "2" },
          });
        }
      }
      //   } else {
      //     return false;
      //   }
      // });
    },
    goPower() {
      let type = this.type;
      // this.$refs[form].validate((valid) => {
      //   if (valid) {
      if (type == 1 || type == 2) {
        if (this.btnList[this.dateIndex].value == "1") {
          this.$router.push({
            path: "/nodeOfflineRate",
            query: { type, psId: this.psId },
          });
        } else if (this.btnList[this.dateIndex].value == "2") {
          this.$router.push({
            path: "/nodeOfflineRate",
            query: { type, psId: this.psId },
          });
        } else if (this.btnList[this.dateIndex].value == "3") {
          this.$router.push({
            path: "/nodeOfflineRate",
            query: { type, psId: this.psId },
          });
        } else if (this.btnList[this.dateIndex].value == "4") {
          this.$router.push({
            path: "/nodeOfflpowerDetailineRate",
            query: { type, psId: this.psId },
          });
        }
      } else {
        this.$router.push({
          path: "/powerDetail",
          query: { type },
        });
      }
      //   } else {
      //     return false;
      //   }
      // });
    },
    // 市电太阳能跳转
    goSunEnergy() {
      let type = this.type;
      // this.$refs[form].validate((valid) => {
      //   if (valid) {
      if (this.btnList[this.dateIndex].value == "1") {
        this.$router.push({
          path: "/totalEnergyConsumption",
          query: { type, date: "1" },
        });
      } else {
        this.$router.push({
          path: "/totalEnergyConsumption",
          query: { type, date: "2" },
        });
      }
      //   } else {
      //     return false;
      //   }
      // });
    },
    // 分时用能、分时电费
    goHourly() {
      let title = "";
      for (let i in this.stationList) {
        if (this.form.area == this.stationList[i].id) {
          title = this.stationList[i].psName;
          console.log(title, "世界大会");
        }
      }
      let type = this.type;
      if (this.btnList[this.dateIndex].value == 1) {
        this.$router.push({
          path: "/hourlyChargeAndEnergy",
          query: { type, title: title, date: "1" },
        });
      } else {
        this.$router.push({
          path: "/hourlyChargeAndEnergy",
          query: { type, title: title, date: "2" },
        });
      }
    },
    // 获取电站列表
    getStation() {
      getStationList().then((res) => {
        console.log(res, "三年大家安静");
        this.stationList = res.data;
        this.form.area = this.stationList[0].id;
        getInverterList(this.form.area).then((res) => {
          this.inverterList = res.data;
          console.log(res, "大赛u都会");
        });
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    formateMonth(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() + "-" + addDateZero(d.getMonth() + 1);
      return formatdatetime;
    },
    formateYear(datetime) {
      let d = new Date(datetime);
      let formatdatetime = d.getFullYear();
      return formatdatetime;
    },
  },
  mounted() {
    console.log(this.$route, 111);
    this.type = this.$route.query.type;
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.getStation();
    this.form.type = "2";
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (this.dateIndex == 0) {
      this.form.time = this.formateDate(date);
    } else if (this.dateIndex == 1) {
      this.form.time = this.formateMonth(month);
    } else if (this.dateIndex == 2) {
      this.form.time = this.formateYear(year);
    }
    console.log(this.form.time, "晒hi哦");
    console.log(this.type, "北大博士空间");
    setTimeout(() => {
      this.getEchartOne();
    }, 500);
    setTimeout(() => {
      this.getEchartTwo();
    }, 500);
    setTimeout(() => {
      this.getEchartThree();
    }, 500);
    setTimeout(() => {
      this.getEchartFive();
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .select {
    position: absolute;
    left: 30vh;
    color: #fff;
    .el-form {
      display: flex;
      // align-items: ;
      flex-wrap: wrap;
      height: 4vh;
      .el-form-item__label {
        color: #fff;
        font-size: calc(100vw * 16 / 1920);
      }
    }
    .el-form-item {
      display: flex;
      align-items: center;
      height: 4.6vh;
      .el-select-dropdown__item .selected,
      .el-select-dropdown__item {
        font-size: calc(100vw * 16 / 1920);
      }
    }
    .el-input__inner {
      width: 100%;
      height: 4.6vh;
      font-size: calc(100vw * 16 / 1920);
      box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
      border-radius: 6px;
      border: 0.02rem solid #286ae8;
      background: none;
      color: #fff;
    }
  }
  .bottom-two-btn {
    display: flex;
    position: absolute;
    top: 10vh;
    right: 12.8vw;
    height: 2.5vh;
    .btn-content {
      background: url(@/assets/images/btnBg1.png);
      background-size: 100% 100%;
      width: 2.3vw;
      height: 100%;
      line-height: 2.5vh;
      margin-right: 1vw;
      color: #fff;
      text-align: center;
      font-size: calc(100vw * 11 / 1920);
      cursor: pointer;
    }
    .active {
      background: url(@/assets/images/btnBg2.png);
      background-size: 100% 100%;
    }
  }
  .container-title {
    position: absolute;
    top: 8.5vh;
    left: 0;
    right: 0;
    text-align: center;
    color: #02b5ff;
    font-size: calc(100vw * 26 / 1920);
  }
  .echart {
    position: absolute;
    top: 15vh;
    left: 0;
    right: 0;
    width: 84vw;
    height: 48vh;
    margin: 0 auto;
  }
}
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}
::v-deep .el-form-item__error {
  width: 10vw;
  margin-top: 3vh;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-left: 0.5vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
</style>
