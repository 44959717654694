<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">逆变器设备</div>
    </div>
    <div class="right-part">
      <div class="right-top">
        <el-form :model="form">
          <div style="display: flex; justify-content: space-between">
            <el-form-item label="全局搜索：">
              <el-input
                v-model="form.searchContent"
                placeholder="请输入企业名称或电站名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="区域：">
              <v-distpicker
                @province="provinceChange"
                :province="form.province"
                :city="form.city"
                :area="form.area"
                @city="cityChange"
                @area="areaChange"
              ></v-distpicker>
            </el-form-item>
          </div>
          <div
            style="width: 100%; display: flex; justify-content: space-between"
          >
            <el-form-item label="设备类型：">
              <el-select
                v-model="form.equipmentType"
                placeholder="请选择类型"
                style="width: 15.6vw"
              >
                <!-- <el-option
                  v-for="(item, index) in typeList"
                  :key="index"
                  :label="item.typeName"
                  :value="item.id"
                ></el-option> -->
                <el-option label="逆变器" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="MAC码：" style="margin-left: 5vh;">
              <el-input
                v-model="form.itMac"
                placeholder="请输入MAC码"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="SN号：" style="margin-left: 5vh;">
              <el-input
                v-model="form.itMeCard"
                placeholder="请输入SN号"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
      
          </div>
          <div style="display: flex;margin-bottom: 5vh;justify-content: right;">
              <div class="add-btn" @click="addNew">添加</div>
              <div class="add-btn" @click="query">查询</div>
              <div class="add-btn" @click="reset">重置</div>
            </div>
        </el-form>
      </div>
      <el-table
        :data="inverterList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column
          prop="gfEnterprise.etName"
          label="企业名称"
        ></el-table-column>
        <el-table-column
          prop="gfPowerStation.psName"
          label="电站名称"
        ></el-table-column>
        <el-table-column prop="itProvince" label="区域"></el-table-column>
        <el-table-column prop="itAddress" label="地址"></el-table-column>
        <el-table-column prop="gfType.typeName" label="设备类型"></el-table-column>
        <el-table-column prop="itName" label="逆变器名称"></el-table-column>
        <el-table-column prop="itMac" label="节点MAC码"></el-table-column>
        <el-table-column prop="itSlaveId" label="从机ID"></el-table-column>
        <el-table-column label="节点状态">
          <template slot-scope="scope">
            <span
              v-if="scope.row.gfNode != null && scope.row.gfNode.ndSmSta == 0"
              >正常</span
            >
            <span
              v-if="scope.row.gfNode != null && scope.row.gfNode.ndSmSta == 1"
              >异常</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="itUf" label="上传频率"></el-table-column>
        <el-table-column prop="itSicount" label="组串数"></el-table-column>
        <el-table-column prop="itAmcount" label="组件数"></el-table-column>
        <el-table-column prop="itAmWattage" label="组件瓦数"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)"
              >修改</el-button
            >
            <el-button size="mini" type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination

        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
    getInverter,
    getEquipmentType,
    deleteInverterEquipment
} from "@/api/equipment";
import VDistpicker from "v-distpicker";
export default {
  name: "inverterEquipment",
  components: { VDistpicker },
  data() {
    return {
      form: {
        searchContent: "", // 搜索内容
        province: "", // 省
        city: "", // 市
        area: "", // 区
        equipmentType: "", // 设备类型
        itMac:'', // Mac 码
        itMeCard:'',//SN号
      },
      typeList: [], // 设备类型
      inverterList: [],
      currentPage: 1,
      pageSize: 6,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 14 / 1920);";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    provinceChange(data) {
      //省
      console.log(data);
      this.form.province = data.value;
      this.form.city = '';
      this.form.area = '';
    },
    cityChange(data) {
      //市
      this.form.city = data.value;
      this.form.area = '';
    },
    areaChange(data) {
      //区、县
      this.form.area = data.value;
    },
    // 获取查询列表
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        title: this.form.searchContent,
        itType: this.form.equipmentType,
        itProvince: this.form.province,
        itCity: this.form.city,
        itArea: this.form.area,
        itMac:this.form.itMac,
        itMeCard:this.form.itMeCard
      };
      getInverter(data).then((res) => {
        console.log(res, "大家看吧");
        this.inverterList = res.rows;
        this.total = res.total;
      });
    },
    // 获取设备类型
    getTypeList() {
      getEquipmentType().then((res) => {
        console.log(res, "滴哦萨基哦");
        this.typeList = res.data;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    // 新增
    addNew() {
      this.$router.push("/addInverterEquipment");
    },
    // 修改
    handleEdit(row) {
      this.$router.push({
        path: "/addInverterEquipment",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteInverterEquipment(row.id).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    // 重置
    reset(){
        this.form.searchContent = ''
        this.form.province = ''
        this.form.city = ''
        this.form.area = ''
        this.form.equipmentType = ''
        this.form.itMac = ''
        this.form.itMeCard = ''
        this.getList()
    }
  },
  mounted() {
    this.getList();
    this.getTypeList();
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .left-part {
    position: absolute;
    left: 0;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .right-part {
    width: 84.5vw;
    position: absolute;
    left: -0.4vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      width: 65vw;
      margin-left: 12vw;
      // margin-bottom: 4.6vh;
      .add-btn {
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        width: 7.2vw;
        height: 4.6vh;
        font-size: calc(100vw * 18 / 1920);
        margin-left: 2vw;
        color: #02b5ff;
        text-align: center;
        line-height: 4.6vh;
        cursor: pointer;
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 7vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-left: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
