var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.path != '/login' && _vm.$route.path != '/' && _vm.$route.path != '/forgetPsd'
    && _vm.$route.path != '/housingAdd'&& _vm.$route.path != '/housingCunAdd'&& _vm.$route.path != '/landZhenPropertyAdd'
    && _vm.$route.path != '/landDongInfoAdd'&& _vm.$route.path != '/landZhuanAdd'&& _vm.$route.path != '/companyInfoAdd'
    && _vm.$route.path != '/companyMingAdd'&& _vm.$route.path != '/juAdd'&& _vm.$route.path != '/landZuAdd'
    && _vm.$route.path != '/housingAddInfo'&& _vm.$route.path != '/landZhenPropertyAddInfo'&& _vm.$route.path != '/landDongInfoAddInfo'
)?_c('Header'):_vm._e(),(_vm.$route.path == '/login' || _vm.$route.path == '/' || _vm.$route.path == '/forgetPsd'
    || _vm.$route.path == '/housingAdd'|| _vm.$route.path == '/housingCunAdd'|| _vm.$route.path == '/landZhenPropertyAdd'
    || _vm.$route.path == '/landDongInfoAdd'|| _vm.$route.path == '/landZhuanAdd'|| _vm.$route.path == '/companyInfoAdd'
    || _vm.$route.path == '/companyMingAdd'|| _vm.$route.path == '/juAdd'|| _vm.$route.path == '/landZuAdd'
    || _vm.$route.path == '/housingAddInfo'|| _vm.$route.path == '/landZhenPropertyAddInfo'|| _vm.$route.path == '/landDongInfoAddInfo')?_c('router-view'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }