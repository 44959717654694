/*
 * @Author: ‘巴博尔’ 2164119982@qq.com
 * @Date: 2023-04-27 13:07:10
 * @LastEditors: ‘巴博尔’ 2164119982@qq.com
 * @LastEditTime: 2023-04-28 10:51:16
 * @FilePath: \guangfu-pc\src\api\inverterAddition.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import requests from "@/api/request";

// 查询逆变器故障信息
const inverterMalfunctionsGET = (params) => {
  return requests.get("/statistics/inverter/malfunctions", {
    params,
  });
};

// 查询电站下所有逆变器最新信息
const inverterPowerStationGET = (params) => {
  return requests.get("/statistics/inverter/powerStation/", {
    params,
  });
};

// 概览
const adapterInverterRealtimeDataGET = (params) => {
  return requests.get("/custom/adapter/inverterRealtimeData", {
    params,
  });
};

// 设备信息--功率曲线
const adapterGetPvPvInverterGET = (params) => {
  return requests.get("/custom/adapter/getPvPvInverter", {
    params,
  });
};

// 数量
const powerStationCountGET = (params) => {
  return requests.get("/statistics/inverter/powerStation/count", {
    params,
  });
};

// 逆变器最新实时数据
const statisticsINverterDETailGET = (params) => {
  return requests.get("/statistics/inverter/detail", {
    params,
  });
};

// 逆变器通用数据 图表
const statisticsINverterDETailCHARTGET = (params) => {
  return requests.get("/statistics/inverter/chart", {
    params,
  });
};

export {
  inverterMalfunctionsGET,
  inverterPowerStationGET,
  adapterInverterRealtimeDataGET,
  adapterGetPvPvInverterGET,
  powerStationCountGET,
  statisticsINverterDETailGET,
  statisticsINverterDETailCHARTGET
};
