<template>
  <div class="container">
    <div class="station-container">
      <div class="left-title">电站管理</div>
      <div class="right-container">
        <div class="right-top">
          <el-form ref="form" :model="form">
            <el-form-item label="区域(省市县)：">
              <v-distpicker
                @province="provinceChange"
                @city="cityChange"
                @area="areaChange"
                :province="form.province"
                :city="form.city"
                :area="form.area"
              ></v-distpicker>
            </el-form-item>
            <div
              style="
                width: 62.5vw;
                display: flex;
                justify-content: space-between;
              "
            >
              <el-form-item label="全局搜索：">
                <el-input
                  v-model="form.searchContent"
                  placeholder="请输入企业名称或电站名称"
                  style="width: 15.6vw"
                ></el-input>
              </el-form-item>
              <div style="display: flex; height: 4.6vh">
                <div class="query" @click="query">查询</div>
                <div class="query" @click="reset">重置</div>
                <div class="reset" @click="exportData">导出</div>
              </div>
            </div>
          </el-form>
        </div>
        <table border="1">
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td colspan="3">电站统计</td>
            <td colspan="3">节点统计</td>
            <td colspan="3">设备统计</td>
          </tr>
          <tr>
            <td>序号</td>
            <td>企业名称</td>
            <td>区域</td>
            <td>总计</td>
            <td>装机容量</td>
            <td>运行状态</td>
            <td>总计</td>
            <td>在线数量</td>
            <td>在线率</td>
            <td>总计</td>
            <td>在线数量</td>
            <td>在线率</td>
          </tr>
          <tr v-for="(item, index) in stationList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.etName }}</td>
            <td>{{ item.etProvince }}</td>
            <td>{{ item.psSum }}</td>
            <td>{{ item.psCapacity }}</td>
            <td v-if="item.psSta == 0">正常</td>
            <td v-if="item.psSta == 1">异常</td>
            <td>{{ item.noSum }}</td>
            <td>{{ item.noOlSum }}</td>
            <td>{{ item.noOlRate }}</td>
            <td>{{ item.dvSum }}</td>
            <td>{{ item.dvOlSum }}</td>
            <td>{{ item.dvOlRate }}</td>
          </tr>
        </table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getStationList, exportStation } from "@/api/home";
import VDistpicker from "v-distpicker";
export default {
  name: "stationManagement",
  components: { VDistpicker },
  data() {
    return {
      form: {
        province: "", // 省
        city: "", // 市
        area: "", // 区
        searchContent: "", // 全局搜索
      },
      currentPage: 1,
      pageSize: 8,
      total: 0,
      stationList: [],
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    provinceChange(data) {
      //省
      console.log(data);
      this.form.province = data.value;
      this.form.city = '';
      this.form.area = '';
    },
    cityChange(data) {
      //市
      this.form.city = data.value;
      this.form.area = '';
    },
    areaChange(data) {
      //区、县
      this.form.area = data.value;
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        etProvince: this.form.province,
        etCity: this.form.city,
        etArea: this.form.area,
        etName: this.form.searchContent,
      };
      getStationList(data).then((res) => {
        this.stationList = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    // 重置
    reset() {
      this.form.searchContent = "";
      this.form.province = "";
      this.form.city = "";
      this.form.area = "";
      this.getList();
    },
    // 导出
    exportData() {
      let token = localStorage.getItem("token");
      let data = {
        province: this.form.province,
        city: this.form.city,
        area: this.form.area,
        title: this.form.searchContent,
      };

      this.$axios({
        method: "GET",
        url:
          "https://admin.pvshield.cn/guang/custom/enterprise/exportXls?province=" +
          data.province +
          "&city=" +
          data.city +
          "&area=" +
          data.area +
          "&title=" +
          data.title,
        responseType: "arraybuffer",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          let url = window.URL.createObjectURL(
            new Blob([res.data], { type: ".xls" })
          );
          let a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.setAttribute("download", "电站管理.xls");
          document.body.appendChild(a);
          a.click();
          url = window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          this.$message.error("导出失败");
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  // height: 100%;
  .station-container {
    display: flex;
    .left-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-right: 4vw;
    }
    .right-container {
      width: 62.5vw;
      .right-top {
        margin-bottom: 3vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-form-item {
          display: flex;
          align-items: center;
          height: 4.6vh;
          .el-select-dropdown__item .selected,
          .el-select-dropdown__item {
            font-size: calc(100vw * 16 / 1920);
          }
          .el-input__inner {
            width: 100%;
            height: 4.6vh;
            font-size: calc(100vw * 16 / 1920);
            box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
            border-radius: 0.1rem;
            border: 0.02rem solid #286ae8;
            background: none;
            color: #fff;
            border-radius: 6px;
            &::placeholder {
              color: #fff;
            }
          }
          .el-form-item__label {
            color: #fff;
            width: 7.5vw;
            text-align: left;
            font-size: calc(100vw * 16 / 1920);
          }
        }
        .query,
        .reset {
          width: 7.2vw;
          height: 100%;
          background: url(@/assets/images/updown.png);
          background-size: 100% 100%;
          color: #02b5ff;
          font-size: calc(100vw * 16 / 1920);
          line-height: 4.6vh;
          text-align: center;
          cursor: pointer;
        }
        .query {
          margin-right: 1.5vw;
        }
      }
      table {
        width: 100%;
        box-shadow: inset 0rem 0rem 0.33rem 0.35rem rgba(15, 53, 116, 0.6);
        border: 0.02rem solid #286ae8;
        margin-bottom: 2.7vh;
        color: #fff;
        font-size: calc(100vw * 16 / 1920);
        border-collapse: collapse;
        text-align: center;
        tr {
          height: 5vh;
        }
      }
      .el-pagination {
        display: flex;
        justify-content: flex-end;
        .el-select,
        .btn-prev,
        .btn-next,
        .el-pagination__total {
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 0.1rem;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
        }
        .el-pager li {
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 0.1rem;
          border: 0.02rem solid #286ae8;
          background: none;
          .active {
            color: pink !important;
          }
        }
        .el-pager li.active {
          color: #0855b3;
        }
        .number {
          color: #fff;
          margin-right: 0.8vw;
          .active {
            color: pink;
          }
        }
        .el-input {
          width: 7vw;
        }
        .more,
        .btn-prev,
        .btn-next {
          color: #fff;
          margin-right: 0.8vw;
        }
        .el-input .el-input--mini .el-input--suffix {
          background: none;
        }
        .el-input__inner {
          background: none;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
}
::v-deep .el-select .el-input .el-select__caret {
  margin-right: 0.5vw;
}
::v-deep .el-pagination {
  margin-bottom: 1vh;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  font-size: calc(100vw * 16 / 1920);
}
</style>
