<template>
  <div class="container">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回首页</span>
    </div>
    <div class="right-container">

      <div style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);text-align: center;padding-bottom: 4vh;">
        光辉村
      </div>
      <div class="right-top">
            <div style="display: flex; height: 4.6vh;padding-bottom: 3vh;">
              <div class="query" @click="addNew">镇属房屋资产点位图</div>
              <div class="query" @click="query">房屋资产核查汇总表</div>
              <div class="query" @click="clearSearch">房屋出租明细表</div>
              <div class="query" @click="clearSearch">房屋资产基本信息表</div>
              <div class="query" @click="clearSearch">限制面积</div>
              <div class="query" @click="clearSearch">存量安置房统计表</div>
            </div>
      </div>

      <div class="image-wrapper_1 flex-row">
        <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng632f3224fdac4fa4091658980aeb46031f443be415669d2f7e8e025f7ed8f36a"
        />
      </div>
      <div style="display: flex;justify-content: right;">
        <div class="del">
          删除
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserList, deleteUser, isSuperManager } from "@/api/jurisdiction";
export default {
  name: "userJurisdiction",
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      userList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickName: this.form.nickName,
        userName: this.form.userName,
        etName:this.form.etName, // 企业
      };
      getUserList(data).then((res) => {
        console.log(res, "阿什杜阿虎");
        this.userList = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    clearSearch() {
      this.form.nickName = "";
      this.form.userName = "";
      this.form.etName = ""; //企业
      this.getList();
    },
    // 新增
    addNew() {
      isSuperManager().then((res) => {
        let manager = res.data;
        this.$router.push({
          path: "/addAuthorization",
          query: {
            manager: manager,
          },
        });
      });
    },
    // 编辑
    handleEdit(row) {
      isSuperManager().then((res) => {
        let manager = res.data;
        console.log(manager, "后端会");
        this.$router.push({
          path: "/addAuthorization",
          query: {
            id: row.userId,
            manager: manager,
          },
        });
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUser(row.userId).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    // 修改密码
    handlePsd(row) {
      this.$router.push({
        path: "modifyPsd",
        query: {
          id: row.userId,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}

.del{
  width: 10vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;

}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}



.text_8 {
  width: 142px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(2, 181, 255, 1);
  font-size: 18px;
  font-family: AlibabaPuHuiTi-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 13px 0 0 49px;
}

.image-wrapper_1 {
  width: 100%;
  text-align: center;
}

.image_1 {
  width: 50vw;
}

</style>
