<template>
  <div class="container">
    <div class="top-container">
      <div class="top-left">
        <div class="top-left-one">
          <div class="left-title">总用能排行</div>
          <div
            style="
              width: 100%;
              height: 25vh;
              padding-top: 2.5vh;
              box-sizing: border-box;
            "
          >
            <div id="oneChart" style="width: 100%; height: 100%"></div>
          </div>
        </div>
        <div class="top-left-two">
          <div class="left-title">太阳能用量排行</div>
          <div
            style="
              width: 100%;
              height: 25vh;
              padding-top: 2.5vh;
              box-sizing: border-box;
            "
          >
            <div id="twoChart" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
      <div class="top-middle">
        <div class="top-middle-one"></div>
        <div class="top-middle-two">
          <div class="middle-title">用能占比</div>
          <table style="cursor: pointer" @click="goEnergyDetail">
            <tr>
              <td>排名</td>
              <td>电站名称</td>
              <td>总用电量</td>
              <td>太阳能比例</td>
            </tr>
            <tr v-for="(item, index) in energyList" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.psName }}</td>
              <td>{{ item.sumTe }}</td>
              <td>{{ item.tratio }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="top-right">
        <div class="top-right-one">
          <div class="right-title" style="right: 4vw">损耗排行</div>
          <div
            style="
              width: 100%;
              height: 25vh;
              padding-top: 2.5vh;
              box-sizing: border-box;
            "
          >
            <div id="threeChart" style="width: 100%; height: 100%"></div>
          </div>
        </div>
        <div class="top-right-two">
          <div class="right-title">市电用量排行</div>
          <div
            style="
              width: 100%;
              height: 25vh;
              padding-top: 2.5vh;
              box-sizing: border-box;
            "
          >
            <div id="fourChart" style="width: 100%; height: 100%"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="bottom-title">分时用能</div>
      <div
        id="fiveChart"
        style="
          width: 83vw;
          height: 100%;
          position: absolute;
          left: 1vw;
          right: 0;
          margin: 0 auto;
        "
      ></div>
      <div class="tip-btn">当日</div>
    </div>
  </div>
</template>

<script>
import {
  getTotalEnergy,
  getEnergyProportion,
  getEnergyConsumption,
  getMainsEnergyConsumption,
  getHoursEnergy,
  getLossAnalysis,
} from "@/api/dataAnalysis";
export default {
  name: "energyConsumption",
  data() {
    return {
      windowWidth: 1920,
      windowHeight: 1080,
      energyList: [], // 用能占比列表
    };
  },
  methods: {
    // 用能占比
    goEnergyDetail() {
      this.$router.push({
        path: "/totalEnergyConsumption",
        query: {
          type: 13,
          date: 1,
        },
      });
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    // 第一张图
    getEchartOne() {
      let myChart1 = this.$echarts.init(document.getElementById("oneChart"));
      getTotalEnergy().then((res) => {
        console.log(res, "后端会");
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].psName);
          val.push(res.data[i].sumTe);
        }
        myChart1.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "kw",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#06B4FC" },
                  { offset: 1, color: "#052654" },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(90),
            top: this.nowHeightSize(42),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(40),
          },
        });
        let that = this;
        myChart1.on("click", function (param) {
          that.$router.push({
            path: "/totalEnergyConsumption",
            query: {
              type: 12,
              date: 1,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart1.resize();
        });
      });
    },
    // 第二张图
    getEchartTwo() {
      let myChart2 = this.$echarts.init(document.getElementById("twoChart"));
      getEnergyConsumption().then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].psName);
          val.push(res.data[i].sumTe);
        }
        myChart2.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "kw",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#06B4FC" },
                  { offset: 1, color: "#052654" },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(80),
            top: this.nowHeightSize(42),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(40),
          },
        });
        let that = this;
        myChart2.on("click", function (param) {
          that.$router.push({
            path: "/totalEnergyConsumption",
            query: {
              type: 13,
              date: 1,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart2.resize();
        });
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    // 第三张图
    getEchartThree() {
      let myChart3 = this.$echarts.init(document.getElementById("threeChart"));
      // let date = new Date()
      // let bTime = this.formateDate(date)
      let bTime = "2022-10-27";
      getLossAnalysis(bTime).then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].psName);
          val.push(res.data[i].lose);
        }
        myChart3.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "kw",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#06B4FC" },
                  { offset: 1, color: "#052654" },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(80),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(20),
          },
        });
        let that = this;
        myChart3.on("click", function (param) {
          that.$router.push({
            path: "/lossAnalysis",
            query: {
              type: 21,
              date: 1,
              title: param.name,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart3.resize();
        });
      });
    },
    // 第四张图
    getEchartFour() {
      let myChart4 = this.$echarts.init(document.getElementById("fourChart"));
      getMainsEnergyConsumption().then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].psName);
          val.push(res.data[i].sumTe);
        }
        myChart4.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "kw",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#06B4FC" },
                  { offset: 1, color: "#052654" },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(80),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(25),
          },
        });
        let that = this;
        myChart4.on("click", function (param) {
          that.$router.push({
            path: "/totalEnergyConsumption",
            query: {
              type: 13,
              date: 1,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart4.resize();
        });
      });
    },
    // 分时用能
    getEchartFive() {
      let myChart5 = this.$echarts.init(document.getElementById("fiveChart"));
      getHoursEnergy().then((res) => {
        let header = res.data.header;
        let val1 = res.data.val[0].data;
        let val2 = res.data.val[1].data;
        let val3 = res.data.val[2].data;
        let val4 = res.data.val[3].data;
        myChart5.setOption({
          tooltip: {
            trigger: "axis",
          },
          legend: {
            top: this.nowHeightSize(10),
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
            data: ["尖", "峰", "平", "谷"],
          },
          xAxis: [
            {
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              type: "value",
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
                formatter: "{value} kw",
              },
            },
          ],
          series: [
            {
              name: "尖",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "#06B4FC",
              },
              emphasis: {
                focus: "series",
              },
              data: val1,
            },
            {
              name: "峰",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "#286AE8",
              },
              emphasis: {
                focus: "series",
              },
              data: val2,
            },
            {
              name: "平",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "#A927A9",
              },
              emphasis: {
                focus: "series",
              },
              data: val3,
            },
            {
              name: "谷",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "#FE2727",
              },
              emphasis: {
                focus: "series",
              },
              data: val4,
            },
          ],
          grid: {
            left: this.nowWidthSize(110),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(110),
            bottom: this.nowHeightSize(40),
          },
        });
        let that = this;
        myChart5.on("click", function (param) {
          console.log(param, "多少i大奖赛哦");
          that.$router.push({
            path: "/hourlyChargeAndEnergy",
            query: {
              type: 14,
              date: 1,
              title: param.name,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart5.resize();
        });
      });
    },
    // 用能占比
    getOne() {
      getEnergyProportion().then((res) => {
        this.energyList = res.data;
      });
    },
  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.getOne();
    this.getEchartOne();
    this.getEchartTwo();
    this.getEchartThree();
    this.getEchartFour();
    this.getEchartFive();
  },
};
</script>

<style lang="scss" scoped>
.left-title {
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding: 0.5vh 0 0 3.2vw;
  box-sizing: border-box;
}
.container {
  width: 100%;
  padding: 1vh 1.1vw 0 1vw;
  box-sizing: border-box;
  .top-container {
    width: 100%;
    height: 56.5vh;
    margin-bottom: 2.7vh;
    display: flex;
    justify-content: space-between;
    .top-left,
    .top-right {
      width: 23.9vw;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top-left-one,
      .top-left-two,
      .top-right-one,
      .top-right-two {
        width: 100%;
        height: 26.8vh;
      }
      .top-left-one,
      .top-left-two {
        background: url(@/assets/images/box13.png);
        background-size: 100% 100%;
      }
      .top-right-one,
      .top-right-two {
        position: relative;
        background: url(@/assets/images/box15.png);
        background-size: 100% 100%;
        .right-title {
          position: absolute;
          right: 3vw;
          top: 0.6vh;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
    }
    .top-middle {
      width: 45.8vw;
      height: 100%;
      margin: 0 1vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top-middle-one {
        height: 37vh;
        width: 100%;
        background: url(@/assets/images/1.png);
        background-size: 100% 100%;
      }
      .top-middle-two {
        height: 17vh;
        width: 100%;
        background: url(@/assets/images/box14.png);
        background-size: 100% 100%;
        position: relative;
        .middle-title {
          font-size: calc(100vw * 16 / 1920);
          color: #fff;
          position: absolute;
          right: 4vw;
          top: 0.4vh;
        }
        table {
          margin-left: 3vw;
          width: 30vw;
          height: 16vh;
          padding-top: 2vh;
          box-sizing: border-box;
          font-size: calc(100vw * 16 / 1920);
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  .bottom-container {
    width: 100%;
    height: 24vh;
    background: url(@/assets/images/box16.png);
    background-size: 100% 100%;
    display: flex;
    position: relative;
    .bottom-title {
      position: absolute;
      left: 0.4vw;
      top: 7.5vh;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      writing-mode: vertical-lr;
      text-align: center;
      letter-spacing: 5px;
    }
    .tip-btn {
      position: absolute;
      top: 1vh;
      left: 30vw;
      width: 5.2vw;
      height: 2.7vh;
      text-align: center;
      line-height: 2.7vh;
      box-shadow: inset 0px 0px 26px 10px rgba(44, 98, 230, 0.28);
      border-radius: 6px;
      font-size: calc(100vw * 14 / 1920);
      border: 1px solid #286ae8;
      color: #fff;
    }
  }
}
</style>
