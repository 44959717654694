<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">企业信息</div>
    </div>
    <div class="right-part">
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form-item">
          <el-form-item label="企业名称：" prop="etName">
            <el-input v-model="form.etName" style="width: 20vw" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="区域：">
            <v-distpicker
              @province="provinceChange"
              @city="cityChange"
              @area="areaChange"
              :province="form.etProvince"
              :city="form.etCity"
              :area="form.etArea"
            ></v-distpicker>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="详细地址：" prop="etAddress">
            <el-input v-model="form.etAddress" style="width: 20vw" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="企业联系人：" prop="etEc">
            <el-input v-model="form.etEc" style="width: 20vw" placeholder="请输入企业联系人"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="企业联系电话：" prop="etPhone">
            <el-input v-model="form.etPhone" style="width: 20vw" placeholder="请输入企业联系电话"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="行业：" prop="etIndustry">
            <el-input v-model="form.etIndustry" style="width: 20vw" placeholder="请输入行业"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="合同起始日期：" prop="etBetime">
            <el-date-picker
              style="width: 20vw"
              v-model="form.etBetime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="年/月/日"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="合同到期日期：" prop="etEntime">
            <el-date-picker
              style="width: 20vw"
              v-model="form.etEntime"
              type="datetime"
              format="yyyy-MM-dd  HH:mm:ss"
              placeholder="年/月/日"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div  style="display:flex;height:13.8vh;margin-bottom:5vh;">
          <el-form-item
            label="备注："
            style="display: flex; align-items: flex-start"
          >
            <textarea v-model="form.remark" placeholder="请输入备注"></textarea>
          </el-form-item>
          <el-form-item label="上传图标：" style="display:flex;align-items:flex-start">
            <el-upload
              :on-success="successUpload"
              class="avatar-uploader"
              :headers="header"
              action="https://admin.pvshield.cn/guang/common/upload"
              :show-file-list="false"
            >
              <img v-if="form.logo" :src="form.logo" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <div
          class="submit-btn"
          @click="addInfo('form')"
          v-if="$route.query.content == undefined"
        >
          添加
        </div>
        <div
          class="submit-btn"
          @click="modifyInfo('form')"
          v-if="$route.query.content != undefined"
        >
          修改
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import {
  addCompanyInfo,
  modifyCompanyInfo
} from "@/api/index";
export default {
  name: "addCompanyInfo",
  components: {
    VDistpicker,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      header:{Authorization: window.localStorage.getItem("token")},
      imageUrl: '',   // 
      form: {
        etName: "", // 企业名称
        etProvince: "", // 省
        etCity: "", // 市
        etArea: "", // 区
        etAddress: "", // 地址
        etEc: "", // 联系人
        etIndustry: "", // 行业
        etPhone: "", // 联系电话
        etBetime: "", // 合同起始日期
        etEntime: "", // 合同到期日期
        remark: "", // 备注
        logo:''
      },
      rules: {
        etName: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        etAddress: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        etEc: [
          { required: true, message: "企业联系人不能为空", trigger: "blur" },
        ],
        etIndustry: [
          { required: true, message: "行业不能为空", trigger: "blur" },
        ],
        etBetime: [
          { required: true, message: "合同起始日期不能为空", trigger: "blur" },
        ],
        etEntime: [
          { required: true, message: "合同到期日期不能为空", trigger: "blur" },
        ],
        etPhone: [
          {
            required: true,
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    provinceChange(data) {
      //省
      console.log(data);
      this.form.etProvince = data.value;
      this.form.etCity = '';
      this.form.etArea = '';
    },
    cityChange(data) {
      //市
      this.form.etCity = data.value;
      this.form.etArea = '';
    },
    areaChange(data) {
      //区、县
      this.form.etArea = data.value;
    },
    // 新增企业信息
    addInfo(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.etBetime = this.$formateDate(this.form.etBetime);
          this.form.etEntime = this.$formateDate(this.form.etEntime);
          addCompanyInfo(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/companyInfo");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改企业信息
    modifyInfo(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.etBetime = this.$formateDate(this.form.etBetime);
          this.form.etEntime = this.$formateDate(this.form.etEntime);
          console.log(this.form, "wasdas");
          modifyCompanyInfo(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/companyInfo");
            }
          });
        } else {
          return false;
        }
      });
    },
    successUpload(file){
      console.log(file,111)
      this.form.logo = file.url
    },
  },
  mounted() {
    if (this.$route.query.content != undefined) {
      this.form = JSON.parse(this.$route.query.content);
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .left-part {
    position: absolute;
    left: 0;
    // margin-right: 6vw;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .form-item {
    display: flex;
    height: 4.6vh;
    margin-bottom: 3vh;
    .tip {
      width: 3.8vw;
      line-height: 4.6vh;
      margin-left: 0.5vw;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      margin-right: 4.6vw;
    }
  }
  .submit-btn {
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    margin-left: 56.7vw;
    cursor: pointer;
  }
}
.right-part {
  width: 72vw;
  position: absolute;
  left: 13vw;
  right: 0;
  margin: 0 auto;
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 7.5vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
}
::v-deep .el-input__inner {
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  &::placeholder {
    color: #fff;
  }
}
textarea {
  width: 20vw;
  resize: none;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  border-radius: 6px;
  height: 13.8vh;
  padding: 0.7vw;
  box-sizing: border-box;
  outline: none;
  margin-right: 9.2vw;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  // border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 7.5vw;
  height: 13.8vh;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  line-height: 13.8vh;
  text-align: center;
}
::v-deep .avatar {
  width: 7.5vw;
  height: 13.8vh;
}
</style>
