<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title" v-if="$route.query.id == undefined">
        抄表规则录入
      </div>
      <div class="equipment-title" v-if="$route.query.id != undefined">
        抄表规则修改
      </div>
    </div>
    <div style="width: 80vw; height: 100%">
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form-item">
          <el-form-item label="规则名称：" prop="regulationName">
            <el-input
              placeholder="请输入规则名称："
              v-model="form.regulationName"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="正向总：" prop="forwardTotal">
            <el-input
              placeholder="请输入正向总"
              v-model="standData.forwardTotal"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入正向总"
              v-model="form.forwardTotal"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="正向尖：" prop="forwardTip">
            <el-input
              placeholder="请输入正向尖"
              v-model="standData.forwardTip"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入正向尖"
              v-model="form.forwardTip"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="正向峰：" prop="forwardPeak">
            <el-input
              placeholder="请输入正向峰"
              v-model="standData.forwardPeak"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入正向峰"
              v-model="form.forwardPeak"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="正向平：" prop="forwardFlat">
            <el-input
              placeholder="请输入正向平"
              v-model="standData.forwardFlat"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入正向平"
              v-model="form.forwardFlat"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="正向谷：" prop="forwardValley">
            <el-input
              placeholder="请输入正向谷"
              v-model="standData.forwardValley"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入正向谷"
              v-model="form.forwardValley"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="反向总：" prop="reverseTotal">
            <el-input
              placeholder="请输入反向总"
              v-model="standData.reverseTotal"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入反向总"
              v-model="form.reverseTotal"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="反向尖：" prop="reverseTip">
            <el-input
              placeholder="请输入反向尖"
              v-model="standData.reverseTip"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入反向尖"
              v-model="form.reverseTip"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="反向峰：" prop="reversePeak">
            <el-input
              placeholder="请输入反向峰"
              v-model="standData.reversePeak"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入反向峰"
              v-model="form.reversePeak"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="反向平：" prop="reverseFlat">
            <el-input
              placeholder="请输入反向平"
              v-model="standData.reverseFlat"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入反向平"
              v-model="form.reverseFlat"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="反向谷：" prop="reverseValley">
            <el-input
              placeholder="请输入反向谷"
              v-model="standData.reverseValley"
              style="width: 18vw"
              readonly=""
            ></el-input>
            <span>--</span>
            <el-input
              placeholder="请输入反向谷"
              v-model="form.reverseValley"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="备注：" prop="remark">
            <el-input
              placeholder="请输入备注"
              v-model="form.remark"
              style="width: 18vw"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div
        class="submit-btn"
        @click="addNew('form')"
        v-if="$route.query.id == undefined"
      >
        添加
      </div>
      <div
        class="submit-btn"
        @click="modify('form')"
        v-if="$route.query.id != undefined"
      >
        修改
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCompanyList,
  getStationList,
  addElePrice,
  getPriceDetail,
  modifyElePrice,
  regulationStandardGET,
  regulationPOST,
  regulationPUT,
  electricRegulationGET,
} from "@/api/equipment";
export default {
  name: "addPrice",
  data() {
    return {
      form: {
        etId: "", // 企业名称
        psId: "", // 电站名称
        epTipPrice: "", // 尖
        epPeakPrice: "", // 峰
        epFlatPrice: "", // 平
        epValleyPrice: "", // 谷
        epDiscount: "", // 电价折扣
        epPrice: "1", //上网电价
      },
      companyList: [], // 企业列表
      disabled: true, // 电站名称禁用
      stationList: [], // 电站列表
      standData: {},
      rules: {
        regulationName: [
          { required: true, message: "规则名称不能为空", trigger: "blur" },
        ],
        forwardTotal: [
          { required: true, message: "正向总不能为空", trigger: "blur" },
        ],
        forwardTip: [
          { required: true, message: "正向尖不能为空", trigger: "blur" },
        ],
        forwardPeak: [
          { required: true, message: "正向峰不能为空", trigger: "blur" },
        ],
        forwardFlat: [
          { required: true, message: "正向平不能为空", trigger: "blur" },
        ],
        forwardValley: [
          { required: true, message: "正向谷不能为空", trigger: "blur" },
        ],
        reverseTotal: [
          { required: true, message: "反向总不能为空", trigger: "blur" },
        ],
        reverseTip: [
          { required: true, message: "反向尖不能为空", trigger: "blur" },
        ],
        reversePeak: [
          { required: true, message: "反向峰不能为空", trigger: "blur" },
        ],
        reverseFlat: [
          { required: true, message: "反向平不能为空", trigger: "blur" },
        ],
        reverseValley: [
          { required: true, message: "反向谷不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async regulationStandardAxios() {
      const data_back = await regulationStandardGET({});
      console.log(data_back, "=========regulationStandardGET");
      const { code, data } = data_back;
      if (code === 200) {
        this.standData = data;
      }
    },
    // 获取企业列表
    getCompany() {
      getCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },
    // 选择企业名称时触发
    changeCompany(e) {
      this.disabled = false;
      console.log(e);
      let id = e;
      getStationList(id).then((res) => {
        this.stationList = res.data;
      });
    },
    // 新增
    addNew(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          regulationPOST(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/readerRules");
            }
          });
        } else {
          return false;
        }
      });
    },
    getDetail() {
      electricRegulationGET({ id: this.$route.query.id }).then((res) => {
        this.form = res.data;
      });
    },
    // 修改
    modify(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
            id: this.$route.query.id,
          };
          regulationPUT(params).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/readerRules");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.regulationStandardAxios();
    // this.getCompany();
    if (this.$route.query.id != undefined) {
      this.disabled = false;
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    margin-right: 3vw;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .form-item {
    display: flex;
    height: 4.6vh;
    margin-bottom: 3vh;
    .tip {
      width: 3.8vw;
      line-height: 4.6vh;
      margin-left: 0.5vw;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      margin-right: 4.6vw;
    }
  }
  .submit-btn {
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    margin: 26.1vh 0 0 56.1vw;
    cursor: pointer;
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 7.5vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
}
::v-deep .el-input__inner {
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  outline: none;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
.price-container::v-deep {
  width: 20.1vw;
  height: 4.6vh;
  position: relative;
  display: flex;
  align-items: center;
  background: #0f2455;
  border: 1px solid #286ae8;
  border-radius: 6px;
  .price-left {
    position: absolute;
    left: 0;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    width: 2vw;
    line-height: 4.6vh;
    height: 100%;
    text-align: center;
    border-right: 1px solid #286ae8;
    z-index: 999;
  }
  .el-input__inner {
    border: 0;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
</style>
