<template>
  <div class="container">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回首页</span>
    </div>

  <div class="right-container" v-if="card ==='Quan'">
    <div style="display: flex">
      <div class="reset1" v-bind:class=" card === 'Quan'?'light':''" style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);margin-bottom: 2vh;" @click="chooseCard('Quan')">
        权限管理
      </div>
      <div class="reset1" v-bind:class=" card === 'Ju'?'light':''" style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);margin-bottom: 2vh;margin-left: 2vh" @click="chooseCard('Ju')">
        角色管理
      </div>
    </div>
    <div class="right-top">
        <el-form ref="form" :model="form">
          <div style="display: flex;">
            <el-form-item label="账号名称">
              <el-input
                  placeholder="账号名称"
                  v-model="form.nickName"
                  style="width: 12vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="角色类型">
              <el-select
                  v-model="form.roleId"
                  placeholder="请选择类型"
              >
                <el-option label="超级管理员" value="1"></el-option>
                <el-option label="村级" value="2"></el-option>
                <el-option label="公司单位" value="3"></el-option>
                <el-option label="居委" value="4"></el-option>
                <el-option label="国有事业单位" value="5"></el-option>
              </el-select>
            </el-form-item>

            <div style="display: flex; height: 4.6vh;padding-left: 3vw">
              <div class="query" @click="goAdd()" >添加</div>
              <div class="query" @click="searchUser()">查询</div>

            </div>
          </div>
        </el-form>
      </div>
        <el-table
            :data="totalList"
            :header-cell-style="headerStyle"
            :cell-style="rowStyle"
        >
          <el-table-column prop="roleName" label="角色类型" ></el-table-column>
          <el-table-column prop="userName" label="登录账号"></el-table-column>
          <el-table-column prop="nickName" label=账号名称></el-table-column>
          <el-table-column label="账号状态" >
            <template slot-scope="scope">
              <div v-if="scope.row.status==0">启用</div>
              <div v-if="scope.row.status==1">停用</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" >
            <template slot-scope="scope">
                <el-button size="mini" type="text" v-if="isAdmin=='admin'" @click="edit(scope.row.userId)">编辑</el-button>
                <el-button size="mini" type="text" v-if="isAdmin=='admin'"  @click="del(scope.row.userId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10]"
            :page-size="100"
            layout="sizes, prev, pager, next , total"
            :total="total"
        >
        </el-pagination>
    </div>




    <div class="right-container" v-if="card ==='Ju'">
      <div style="display: flex">
        <div class="reset1" v-bind:class=" card === 'Quan'?'light':''" style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);margin-bottom: 2vh;" @click="chooseCard('Quan')">
          权限管理
        </div>
        <div class="reset1" v-bind:class=" card === 'Ju'?'light':''" style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);margin-bottom: 2vh;margin-left: 2vh" @click="chooseCard('Ju')">
          角色管理
        </div>
      </div>
      <div class="right-top">
        <el-form ref="form" :model="searchFrom">
          <div style="display: flex;">
            <el-form-item label="角色类型">
              <el-select
                  v-model="searchFrom.role"
                  placeholder="请选择类型"
              >
                <el-option label="超级管理员" value="1"></el-option>
                <el-option label="村级" value="2"></el-option>
                <el-option label="公司单位" value="3"></el-option>
                <el-option label="居委" value="4"></el-option>
                <el-option label="国有事业单位" value="5"></el-option>
              </el-select>
            </el-form-item>

            <div style="display: flex; height: 4.6vh;padding-left: 3vw">
              <div class="query" @click="searchRole()" >查询</div>
              <div class="query" @click="searchRole()">重置</div>

            </div>
          </div>
        </el-form>
      </div>
      <el-table
          :data="totalList"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
      >
        <el-table-column prop="roleName" label="角色类型" ></el-table-column>
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="text" v-if="isAdmin=='admin'" @click="roleEdit(scope.row)">编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10]"
          :page-size="100"
          layout="sizes, prev, pager, next , total"
          :total="total"
      >
      </el-pagination>

      <el-dialog title="编辑角色" :visible.sync="dialogFormVisible">
        <el-form :model="roleform">
          <el-form-item label="角色类型" label-width="100px" style = "width: 500px;">
            <el-input v-model="roleform.roleName"  disabled  label-width="100px"></el-input>
          </el-form-item>
          <el-form-item label="权限设置" label-width="100px">
<!--            <el-checkbox-group v-model="roleform.menuIds">-->
<!--              <el-checkbox label=1 >可视智能屏</el-checkbox>-->
<!--              <el-checkbox label=2>房屋信息</el-checkbox>-->
<!--              <el-checkbox label=3>土地信息</el-checkbox>-->
<!--              <el-checkbox label=4 >权限管理</el-checkbox>-->
<!--              <el-checkbox label=5 >日志管理</el-checkbox>-->
<!--            </el-checkbox-group>-->
            <el-checkbox-group v-model="roleform.menuIds" >
              <el-checkbox v-for="city in cityOptions" :label="city.key" :key="city.name">{{city.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="editRole">确 定</el-button>
        </div>
      </el-dialog>
    </div>



  </div>

</template>

<script>
import {getComTuList, getUserDel, logList, roleList,getUserList,getRoleEdit} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  data() {
    return {
      form: {
        nickName: "",
        roleId: "", // 角色类型
        pageSize: 10,
        pageNum: 1,
      },
      totalList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      card:'Quan',
      isAdmin:'',
      dialogFormVisible: false,
      roleform: {
        name: '',
        region: '',

      },
      searchFrom:{},
       cityOptions : [{key:1,name:"可视智能屏"},{key:2,name:"房屋信息"},{key:3,name:"土地信息"},{key:4,name:"权限管理"},{key:5,name:"日志管理"}],
      formLabelWidth: '120px'
    };
  },

  methods: {
    chooseCard(e){
      this.card = e;
      if (e==='Quan'){
        this.getList();
      }else {
        this.getRoleList()
      }
    },
    searchUser(){
      getUserList(this.form).then((res) => {
        this.totalList = res.rows;
        console.log(this.totalList,'inIdleArea')
      });
    },
    searchRole(){
      roleList(this.form).then((res) => {
        this.totalList = res.rows;
        console.log(this.totalList,'inIdleArea')
      });
    },
    backSearch(){
      this.form={}
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.rowId,
        landSubType: '',
        landCode: '',
      };
      getComTuList(data).then((res) => {
        this.totalList = res.rows;
        console.log(this.totalList,'inIdleArea')
      });
    },
    goIndex(){
      this.$router.push("/index");
    },
    goAdd(){
      this.$router.push({ path: '/roleAdd', query: { id: this.id}});
    },
    roleEdit(e){
      this.dialogFormVisible = true;
      this.roleform = e;
      console.log(this.roleform,"menuIds")
    },

    del(row){
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getUserDel(row).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    edit(row){
      console.log(row,"rowsss")
      this.$router.push({ path: '/roleAdd', query: { editId: row,id: this.rowId}});
    },
    editRole(){
      getRoleEdit(this.roleform).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "编辑成功!",
          });
        }
        this.dialogFormVisible = false
        this.getRoleList();
      });
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickName: '',
        roleId: '',
      };
      getUserList(data).then((res) => {
        this.totalList = res.rows;
        this.total = res.total;
      });
    },
    getRoleList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        userName: '',
        roleIds: [],
      };
      roleList(data).then((res) => {
        this.totalList = res.rows;
        this.total = res.total;
      });
    },

  },
  mounted() {
    this.getList();
    this.isAdmin = localStorage.getItem("userInfo");
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 70vw;

  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.reset1 {
  width: 10vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
}
.light{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;

}
</style>
