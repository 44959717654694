<template>
  <div class="container">
    <div style="display: flex; justify-content: space-between">
      <div style="flex: 50%">
        <div class="title">
          <div class="left-title">
            <div class="left-title-content">光伏电站</div>
          </div>
          <div class="right-title">
            <el-select
              placeholder="请选择电站"
              v-model="stationName"
              filterable
            >
              <el-option
                v-for="(item, index) in stationList"
                :key="index"
                @click.native="changeStation(index)"
                :label="item.psName"
                :value="item.psName"
              ></el-option>
            </el-select>
            <!-- <el-dropdown trigger="click">
              <div style="display: flex">
                <div class="right-title-content">{{ stationName }}</div>
                <img class="change" src="../assets/images/change.png" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in stationList"
                  :key="index"
                  @click.native="changeStation(index)"
                  >{{ item.psName }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </div>
        <div class="left-two">
          <div class="left-two-item" v-for="(item, index) in list" :key="index">
            <div class="left-two-top">{{ item.name }}</div>
            <div class="left-two-bottom" v-if="index != 2">{{ item.num }}</div>
            <div
              class="left-two-bottom"
              style="cursor: pointer"
              v-if="index == 2"
              @click="goDetail(item.num)"
            >
              {{ item.num }}
            </div>
          </div>
        </div>
        <div class="left-three">
          <div class="left-three-box" v-loading="show1">
            <div class="left-three-title">电站总览</div>
            <table>
              <tr>
                <td>今日</td>
                <td style="color: #30ebff">{{ overview.psDa }}</td>
                <td>环比</td>
                <td style="color: #30ebff">{{ overview.daSum }}</td>
              </tr>
              <tr>
                <td>本月</td>
                <td style="color: #30ebff">{{ overview.psMon }}</td>
                <td>环比</td>
                <td style="color: #30ebff">{{ overview.monSum }}</td>
              </tr>
              <tr>
                <td>年</td>
                <td style="color: #30ebff">{{ overview.psYea }}</td>
                <td>环比</td>
                <td style="color: #30ebff">{{ overview.yeaSum }}</td>
              </tr>
              <tr>
                <td>累计</td>
                <td>{{ overview.psSum }}</td>
                <td></td>
                <td>在线率</td>
              </tr>
            </table>
          </div>
          <div class="left-three-box">
            <div class="left-three-title">实时数据</div>
            <table>
              <tr>
                <td style="text-align: center">当前功率</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.cpower }}
                </td>
                <td style="text-align: center">kwh</td>
              </tr>
              <tr>
                <td style="text-align: center">今日最大功率</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.maxPower }}
                </td>
                <td style="text-align: center">kwh</td>
              </tr>
              <tr>
                <td style="text-align: center">辐射强度</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.radiation }}
                </td>
                <td style="text-align: center">%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div style="flex: 50%; padding-left: 0.3vw; box-sizing: border-box">
        <div class="right-one">
          <div
            class="right-one-item"
            v-for="(item, index) in standard"
            :key="index"
          >
            <div style="color: #fff">{{ item.title }}</div>
            <div>
              <img :src="item.pic" />
              <div style="color: #17e0fe" v-if="index != 2">
                {{ item.num }}吨
              </div>
              <div style="color: #17e0fe" v-if="index == 2">
                {{ item.num }}棵
              </div>
            </div>
          </div>
        </div>
        <div class="right-two">
          <div class="right-two-item">
            电站名称：
            <div style="color: #17e0fe">{{ detail.powerStationName }}</div>
          </div>
          <div class="right-two-item">
            合同起始：
            <div style="color: #17e0fe">{{ detail.startTime }}</div>
          </div>
          <div class="right-two-item">
            联系方式：
            <div style="color: #17e0fe">{{ detail.mobile }}</div>
          </div>
          <div class="right-two-item">
            服务商：
            <div style="color: #17e0fe">{{ detail.server }}</div>
          </div>
          <div class="right-two-item">
            电站地址：
            <div style="color: #17e0fe">{{ detail.address }}</div>
          </div>
          <div class="right-two-item"></div>
        </div>
        <div class="right-three">
          <div class="right-three-box" style="left: 0; margin-right: 0.2vw">
            <div class="left-three-title">PR值/等效时长</div>
            <table>
              <tr>
                <td></td>
                <td></td>
                <td style="text-align: center">同比</td>
                <td style="text-align: center">环比</td>
              </tr>
              <tr>
                <td style="text-align: center">今日PR值</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.pr1 }}
                </td>
                <td style="text-align: center">{{ real.prSum1 }}</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.prSum2 }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center">本月等效时长</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.da1 }}
                </td>
                <td style="text-align: center">{{ real.daSum1 }}</td>
                <td style="text-align: center; color: #30ebff">
                  {{ real.daSum2 }}
                </td>
              </tr>
            </table>
          </div>
          <div class="right-three-box" style="right: 0">
            <div class="left-three-title">累计收益</div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 24vh;
                align-items: center;
              "
            >
              <div class="left-three-top">
                <div v-for="(item, index) in profit" :key="index">
                  {{ item }}
                </div>
              </div>
              <div class="left-three-bottom">万元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-three-btn">
      <div
        :class="
          btnIndex == index
            ? 'bottom-three-btn-one active'
            : 'bottom-three-btn-one'
        "
        v-for="(item, index) in btn"
        :key="index"
        @click="changeBtn(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="bottom-two-btn" v-if="btnIndex != 2">
      <el-form :model="form2" style="margin-bottom:1vh">
        <el-form-item v-if="dateIndex == 0" label="日期：">
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form2.date"
            type="date"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="日期：" v-if="dateIndex == 1">
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form2.date"
            type="month"
            format="yyyy-MM"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="日期：" v-if="dateIndex == 2">
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form2.date"
            type="year"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div style="display: flex; align-items: center">
        <div
          v-for="(item, index) in btnList"
          :key="index"
          :class="dateIndex == index ? 'btn-content active' : 'btn-content'"
          @click="changeDate(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="bottom-two-btn" v-if="btnIndex == 2">
      <el-form :model="form">
        <el-form-item label="日期：">
          <el-date-picker
            style="width: 11vw; margin-right: 2vw"
            v-model="form.time"
            type="date"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions0"
            placeholder="选择日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="echart">
      <div
        id="oneChart"
        style="width: 100%; height: 100%"
        v-if="btnIndex == 0"
      ></div>
      <div
        id="twoChart"
        style="width: 100%; height: 100%"
        v-if="btnIndex == 1"
      ></div>
      <div
        id="threeChart"
        style="width: 100%; height: 100%"
        v-if="btnIndex == 2"
      ></div>
    </div>
  </div>
</template>

<script>
import { getStationList } from "@/api/dataAnalysis";
import {
  getHourTime,
  getStationEle,
  getConnectedPower,
  getGreenPower,
  getDefaultInfo,
  getStationOverview,
  getRealData,
} from "@/api/green";
export default {
  name: "equipmentOverview",
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      windowWidth: 1920,
      windowHeight: 1080,
      form: {
        time: "",
      },
      list: [
        { name: "安全运行(天)", num: "" },
        { name: "装机容量(kw)", num: "" },
        { name: "逆变器(台)", num: "" },
        { name: "汇流箱", num: "" },
        { name: "倾斜角度", num: "" },
        { name: "并网日期", num: "" },
      ],
      standard: [
        {
          title: "节约标准煤：",
          pic: require("@/assets/images/car.png"),
          num: "",
        },
        {
          title: "CO2减排量：",
          pic: require("@/assets/images/co2.png"),
          num: "",
        },
        {
          title: "等效植树量：",
          pic: require("@/assets/images/tree.png"),
          num: "",
        },
      ],
      detail: {
        powerStationName: "", // 站点名称
        startTime: "", // 合同起始
        mobile: "", // 联系方式
        server: "", // 服务商
        address: "", // 电站地址
      },
      profit: "", // 累计收益
      btnIndex: 0,
      btn: [{ name: "等效时长" }, { name: "发电量" }, { name: "并网功率" }],
      dateIndex: 0,
      btnList: [
        { name: "日", value: "1" },
        { name: "月", value: "2" },
        { name: "年", value: "3" },
        { name: "历年", value: "4" },
      ],
      stationList: [],
      stationIndex: 0,
      stationName: "",
      psId: "",
      overview: {}, // 电站总览
      real: {}, // 实时数据
      show1: true,
      form2: {
        date: "",
      },
    };
  },
  methods: {
    goDetail(num) {
      if (num != 0) {
        this.$router.push({
          path: "/equipmentMonitoring",
          query: { psId: this.psId },
        });
      }
    },
    specifyPostingDateChange(val) {
      console.log(val, "四大怀湖底");
      console.log(111111);
      if (this.dateIndex == 0) {
        val = this.formateDate(val);
      } else if (this.dateIndex == 1) {
        val = this.$formateMonth(val);
      } else if(this.dateIndex == 2) {
        console.log(val, "四大怀湖底");
      }else {
        val = this.formateDate(val);
      }
      this.form2.date = val;
      if (this.btnIndex == 0) {
        this.getEchartOne();
      } else if (this.btnIndex == 1) {
        this.getEchartTwo();
      } else {
        this.form.time = this.formateDate(val);
        this.getEchartThree();
      }
    },
    // 获取电站列表
    getStation() {
      getStationList().then((res) => {
        console.log(res, "三年大家安静");
        this.stationList = res.data;
        this.stationName = this.stationList[0].psName;
        this.psId = this.stationList[0].id;
        let date = new Date();
        this.form.time = this.formateDate(date);
        this.getEchartOne();
        this.getEchartTwo();
        this.getEchartThree();
        this.getGreen();
        this.getInfo();
        this.getOverview();
        this.getReal();
      });
    },
    changeStation(index) {
      this.stationIndex = index;
      this.stationName = this.stationList[this.stationIndex].psName;
      this.psId = this.stationList[this.stationIndex].id;
      console.log(1111111);
      if (this.btnIndex == 0) {
        this.getEchartOne();
      } else if (this.btnIndex == 1) {
        this.getEchartTwo();
      } else {
        this.getEchartThree();
      }
      this.getGreen();
      this.getInfo();
      this.getOverview();
      this.getReal();
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    changeBtn(index) {
      this.btnIndex = index;
      if (this.btnIndex == 0) {
        this.getEchartOne();
      } else if (this.btnIndex == 1) {
        this.getEchartTwo();
      } else {
        this.getEchartThree();
      }
    },
    changeDate(index) {
      this.dateIndex = index;
      if (this.btnIndex == 0) {
        this.getEchartOne();
      } else if (this.btnIndex == 1) {
        this.getEchartTwo();
      }
    },
    getEchartOne() {
      let date = ""
      if (this.dateIndex == 1) {
        date = this.$formateMonth(this.form2.date);
      } else if (this.dateIndex == 2) {
        date = this.$formateYear(this.form2.date);
        console.log(this.form2.date,'的视角的脚手架')
      } else if (this.dateIndex == 0) {
        date = this.formateDate(this.form2.date);
      }
      let data = {
        psId: this.psId,
        type: this.btnList[this.dateIndex].value,
        dateStr: date,
      };
      getHourTime(data).then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].hu);
          val.push(res.data[i].num);
        }
        let myChart1 = this.$echarts.init(document.getElementById("oneChart"));
        myChart1.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "h";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                show: false,
                //   lineStyle: {
                //     color: "#0B3A56",
                //   },
              },
              axisLabel: {
                color: "#fff",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "h",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: "#06B4FC",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(50),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(25),
          },
        });
        let title = "";
        for (let i in this.stationList) {
          if (this.stationList[i].id == this.psId) {
            title = this.stationList[i].psName;
          }
        }
        let that = this;
        let date = new Date();
        date = this.formateDate(date);
        console.log(this.btnList[this.dateIndex].value, "dshaiodjhoai");
        console.log(date, "11111");
        myChart1.on("click", function (param) {
          that.$router.push({
            path: "/stringOfflineRate",
            query: {
              type: 9,
              psName: title,
              dateTime: that.btnList[that.dateIndex].value,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart1.resize();
        });
      });
    },
    getEchartTwo() {
      let date = ""
      if (this.dateIndex == 1) {
        date = this.$formateMonth(this.form2.date);
      } else if (this.dateIndex == 2) {
        date = this.$formateYear(this.form2.date);
      } else if (this.dateIndex == 0) {
        date = this.formateDate(this.form2.date);
      }
      let data = {
        psId: this.psId,
        type: this.btnList[this.dateIndex].value,
        dateStr: date,
      };
      getStationEle(data).then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].hu);
          val.push(res.data[i].num);
        }
        let myChart2 = this.$echarts.init(document.getElementById("twoChart"));
        myChart2.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                show: false,
                //   lineStyle: {
                //     color: "#0B3A56",
                //   },
              },
              axisLabel: {
                color: "#fff",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "kw",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: "#06B4FC",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(50),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(25),
          },
        });
        let title = "";
        for (let i in this.stationList) {
          if (this.stationList[i].id == this.psId) {
            title = this.stationList[i].psName;
          }
        }
        let that = this;
        myChart2.on("click", function (param) {
          that.$router.push({
            path: "/powerDetail",
            query: {
              type: 3,
              title: title,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart2.resize();
        });
      });
    },
    getEchartThree() {
      let data = {
        psId: this.psId,
        bTime: this.form.time,
      };
      getConnectedPower(data).then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].hu);
          val.push(res.data[i].num);
        }
        let myChart3 = this.$echarts.init(
          document.getElementById("threeChart")
        );
        myChart3.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw/h";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                show: false,
                //   lineStyle: {
                //     color: "#0B3A56",
                //   },
              },
              axisLabel: {
                color: "#fff",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              name: "kw/h",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: "#06B4FC",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(50),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(25),
            bottom: this.nowHeightSize(25),
          },
        });
        let title = "";
        for (let i in this.stationList) {
          if (this.stationList[i].id == this.psId) {
            title = this.stationList[i].psName;
          }
        }
        let that = this;
        myChart3.on("click", function (param) {
          that.$router.push({
            path: "/power",
            query: {
              type: 5,
              title: title,
              date: that.form.time,
            },
          });
        });
        window.addEventListener("resize", function () {
          myChart3.resize();
        });
      });
    },
    getGreen() {
      getGreenPower(this.psId).then((res) => {
        this.standard[0].num = res.data.tce;
        this.standard[1].num = res.data.reduction;
        this.standard[2].num = res.data.plantation;
      });
    },
    getInfo() {
      getDefaultInfo(this.psId).then((res) => {
        this.detail.powerStationName = res.data.psName;
        this.detail.startTime = res.data.ems.etBetime;
        this.detail.mobile = res.data.ems.etPhone;
        this.detail.server = res.data.ems.etOmName;
        this.detail.address = res.data.ems.etAddress;
        this.list[0].num = res.data.runTimeSum;
        this.list[1].num = res.data.psCapacity;
        this.list[2].num = res.data.psInverterCount;
        this.list[3].num = res.data.runTimeSum;
        this.list[3].num = res.data.psCb;
        this.list[4].num = res.data.psTa;
        this.list[5].num = res.data.psGc;
      });
    },
    getOverview() {
      getStationOverview(this.psId).then((res) => {
        this.show1 = false;
        this.overview = res.data;
        this.profit = this.overview.psPriceSum;
        this.profit = this.profit.toString().split("");
      });
    },
    getReal() {
      getRealData(this.psId).then((res) => {
        this.real = res.data;
      });
    },
  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.getStation();
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if (this.dateIndex == 0) {
      this.form2.date = this.formateDate(date);
    } else if (this.dateIndex == 1) {
      this.form2.date = this.$formateMonth(month);
    } else if(this.dateIndex == 2) {
      this.form2.date = this.$formateYear(year);
      console.log(this.form2.date,'售价较低哦啊是降低哦')
    }
  },
};
</script>

<style lang="scss" scoped>
.left-three-title {
  width: 100%;
  text-align: center;
  padding-top: 1.1vh;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
}
table {
  width: 100%;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding-top: 2vh;
  height: 20vh;
  box-sizing: border-box;
  text-align: center;
}
.container::v-deep {
  width: 100%;
  padding: 1.5vh 1.3vw;
  box-sizing: border-box;
  .title {
    width: 26.2vw;
    height: 4.6vh;
    position: relative;
    margin-bottom: 1.8vh;
    .left-title,
    .right-title {
      position: absolute;
      width: 13.2vw;
      height: 100%;
      line-height: 4.6vh;
      .el-input__icon {
        line-height: 4.6vh;
      }
      .el-input__inner {
        background: none;
        color: #fff;
        border: none;
        padding-left: 3.2vw;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: calc(100vw * 18 / 1920);
      }
    }
    .left-title {
      left: 0;
      background: url(@/assets/images/box17.png);
      background-size: 100% 100%;
      .left-title-content {
        color: #fff;
        font-size: calc(100vw * 18 / 1920);
        position: absolute;
        left: 3.5vw;
        line-height: 4.6vh;
      }
    }
    .right-title {
      right: 1.6vw;
      background: url(@/assets/images/box18.png);
      background-size: 100% 100%;
      .right-title-content {
        color: #fff;
        font-size: calc(100vw * 18 / 1920);
        position: absolute;
        right: 4.8vw;
        line-height: 4.6vh;
      }
      .change {
        position: absolute;
        right: 2vw;
        top: 1vh;
        width: 1.35vw;
        height: 2.5vh;
        cursor: pointer;
      }
    }
  }
  .left-two {
    width: 100%;
    height: 24.6vh;
    background: url(@/assets/images/box19.png);
    background-size: 100% 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    margin-bottom: 1.8vh;
    .left-two-item {
      width: 33.3%;
      text-align: center;
      font-size: calc(100vw * 16 / 1920);
      &:nth-child(1) {
        margin-bottom: 2.5vh;
      }
      .left-two-top {
        color: #fff;
        margin-bottom: 2.4vh;
      }
      .left-two-bottom {
        color: #17e0fe;
      }
    }
  }
  .left-three {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 24.5vh;
    .left-three-box {
      background: url(@/assets/images/box20.png);
      background-size: 100% 100%;
      width: 24vw;
      height: 100%;
    }
  }
  .right-one {
    background: url(@/assets/images/box21.png);
    background-size: 100% 100%;
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: center;
    margin-bottom: 1.8vh;
    .right-one-item {
      flex: 33.3%;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: calc(100vw * 16 / 1920);
      img {
        width: 2.5vw;
        height: 4.3vh;
        margin-bottom: 0.4vh;
      }
    }
  }
  .right-two {
    padding-left: 3vw;
    width: 100%;
    height: 17.1vh;
    background: url(@/assets/images/box21.png);
    background-size: 100% 100%;
    // padding-left: 5vw;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    // justify-content: space-around;
    // align-items: flex-start;
    margin-bottom: 1.8vh;
    .right-two-item {
      box-sizing: border-box;
      display: flex;
      flex: 50%;
      align-items: center;
      justify-content: flex-start;
      font-size: calc(100vw * 16 / 1920);
      color: #fff;
    }
  }
  .right-three {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 24.5vh;
    .right-three-box {
      position: absolute;
      width: 49.4%;
      height: 100%;
      background: url(@/assets/images/box20.png);
      background-size: 100% 100%;
      color: #17e0fe;
      .left-three-top {
        display: flex;
        margin-bottom: 2vh;
        div {
          width: 1.6vw;
          height: 3vh;
          border-radius: 2px;
          border: 1px solid rgba(255, 255, 255, 0.24);
          text-align: center;
          line-height: 3vh;
        }
      }
    }
  }
  .bottom-three-btn {
    width: 100%;
    height: 4.6vh;
    display: flex;
    justify-content: center;
    margin-top: 1.2vh;
    cursor: pointer;
    .bottom-three-btn-one {
      background: url(@/assets/images/btnBg1.png);
      background-size: 100% 100%;
      width: 7.2vw;
      height: 100%;
      margin-right: 1.5vw;
      color: #fff;
      text-align: center;
      font-size: calc(100vw * 16 / 1920);
      line-height: 4.6vh;
    }
    .active {
      background: url(@/assets/images/btnBg2.png);
      background-size: 100% 100%;
    }
  }
  .bottom-two-btn {
    width: 90%;
    height: 2.5vh;
    display: flex;
    justify-content: space-between;
    .btn-content {
      background: url(@/assets/images/btnBg1.png);
      background-size: 100% 100%;
      width: 2.3vw;
      height: 100%;
      line-height: 2.5vh;
      margin-right: 1vw;
      color: #fff;
      text-align: center;
      font-size: calc(100vw * 11 / 1920);
      cursor: pointer;
    }
    .active {
      background: url(@/assets/images/btnBg2.png);
      background-size: 100% 100%;
    }
  }
  .echart {
    width: 80vw;
    height: 19.3vh;
    margin: 0 auto;
  }
}
.el-dropdown {
  display: block;
}
.el-dropdown-menu {
  top: 18vh !important;
  height: 21vh;
  overflow-y: auto;
}
.el-form::v-deep {
  display: flex;
  height: 4vh;
  .el-form-item__label {
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-form-item {
    display: flex;
    align-items: center;
    height: 4.6vh;
    .el-select-dropdown__item .selected,
    .el-select-dropdown__item {
      font-size: calc(100vw * 16 / 1920);
    }
  }
  .el-input__inner {
    width: 100%;
    height: 4.6vh;
    font-size: calc(100vw * 16 / 1920);
    box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
    border-radius: 6px;
    border: 0.02rem solid #286ae8;
    background: none;
    color: #fff;
  }
}
::v-deep .el-loading-mask {
  background: url(../assets/images/123.gif) no-repeat;
  background-size: 100% 100%;
  // opacity: 0.5;
}
::v-deep .circular {
  display: none;
}
::v-deep .el-form {
  margin-top: -1.5vh !important;
}
</style>
