import requests from './request'

// 房屋汇总
export function getTotalList(data){
    return requests.get('/cunzhen/houseBasicMessage/collecList?manageType=' + data )
}
// 房屋汇总
export function houseExportList(data){
    return requests.post('/cunzhen/houseBasicMessage/export?manageType=' + data )
}

// 房屋详情------------start》
export function getHousingInfo(data){
    return requests.get('/cunzhen/manage/' + data)
}
//房屋详情------------edit》
export function getHousingEdit(data){
    return requests.put('/cunzhen/manage' , data)
}
// 房屋汇总+房屋资产信息表统计
export function getHousingSummary(data){
    return requests.get('/cunzhen/houseBasicMessage/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize+ '&manageId=' + data.manageId , data)
}
// 房屋汇总+房屋资产信息表统计
export function getHousingSummaryExport(data){
    return requests.post('/cunzhen/houseBasicMessage/exportList?manageId=' + data)
}
// 存量安置房统计
export function getHousingCun(data){
    return requests.get('/cunzhen/settlementBuilding/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize+ '&manageId=' + data.manageId , data)
}
// 存量安置房统计
export function getHousingCunExport(data){
    return requests.post('/cunzhen/settlementBuilding/export?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize+ '&manageId=' + data.manageId , data)
}

// 出租明细表
export function getHousingZu(data){
    return requests.get('/cunzhen/rentOutDetail/list?pageNum=' + data.pageNum + '&manageId=' + data.manageId + '&pageSize=' + data.pageSize+ '&rentOutType=' + data.rentOutType , data)
}
// 出租明细表
export function getHousingZuExport(data){
    return requests.post('/cunzhen/rentOutDetail/export?pageNum=' + data.pageNum + '&manageId=' + data.manageId + '&pageSize=' + data.pageSize+ '&rentOutType=' + data.rentOutType , data)
}
// 闲置面积
export function getHousingXian(data){
    return requests.get('/cunzhen/houseBasicMessage/inidleAreaList?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize+ '&manageId=' + data.manageId , data)
}
// 闲置面积
export function getHousingXianExport(data){
    return requests.post('/cunzhen/houseBasicMessage/exportInidleAreaList?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize+ '&manageId=' + data.manageId , data)
}

//村级 房屋新增 add
export function getHosingAdd(data){
    return requests.post('/cunzhen/houseBasicMessage' , data)
}
//村级 房屋新增 info
export function getHosingInfo(data){
    return requests.get('/cunzhen/houseBasicMessage/'+ data)
}
//村级 房屋新增 edit
export function getHosingEdit(data){
    return requests.put('/cunzhen/houseBasicMessage' , data)
}
//村级 房屋新增 del
export function getHosingDel(data){
    return requests.delete('/cunzhen/houseBasicMessage/' + data);
}


//村级 存量安置房 add
export function getHosingCunAdd(data){
    return requests.post('/cunzhen/settlementBuilding' , data)
}
//村级 存量安置房 info
export function getHosingCunInfo(data){
    return requests.get('/cunzhen/settlementBuilding/' +data)
}
//村级 存量安置房 edit
export function getHosingCunEdit(data){
    return requests.put('/cunzhen/settlementBuilding' , data)
}
//村级 存量安置房 del
export function getHosingCunDel(data){
    return requests.delete('/cunzhen/settlementBuilding/' +data)
}


// 房屋详情------------end》




// 土地详情------------start》
// 土地资源基本情况统计表 管理单位类型：0汇总，1村级，2公司单位，3居委，4国有事业单位
export function getLandTotal(data){
    return requests.get('/cunzhen/resourcesBasicMessage/collecList?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//导出土地资源出租情况汇总表 管理单位类型：0汇总，1村级
export function landExport(data){
    return requests.post('/cunzhen/resourcesBasicMessage/export?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//导出土地资源出租情况汇总表 管理单位类型：0汇总，1村级
export function landExportRent(data){
    return requests.post('/cunzhen/resourcesBasicMessage/exportRent?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//导出土地资源出租情况汇总表 管理单位类型：0汇总，1村级
export function landExportJS(data){
    return requests.post('/cunzhen/resourcesBasicMessage/exportConstruct?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//村级 管理单位类型：0汇总，1村级
export function comExport(){
    return requests.post('/cunzhen/companyLandBasicMessage/export')
}
//居委 管理单位类型：0汇总，1村级
export function juExport(){
    return requests.post('/cunzhen/vacancyLand/export')
}


//土地资源出租情况汇总表 管理单位类型：0汇总，1村级
export function getLandRentTotal(data){
    return requests.get('/cunzhen/resourcesBasicMessage/rentList?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//土地资源出租情况汇总表 管理单位类型：0汇总，1村级
export function getLandJSTotal(data){
    return requests.get('/cunzhen/resourcesBasicMessage/constructList?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//土地资源出租情况汇总表 公司
export function getLandCompany(data){
    return requests.get('/cunzhen/companyLandBasicMessage/companyColltionList?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//土地资源出租情况汇总表 居委
export function getLandJu(data){
    return requests.get('/cunzhen/vacancyLand/collctionList?manageType=' + data.manageType +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}




//村级 村级土地资源基本情况统计表 村级
export function getCunTotal(data){
    return requests.get('/system/landBasicMessage/' + data.manageId)
}

//村级 村级土地资源基本情况统计表 村级
export function getCunLandEdit(data){
    return requests.put('/system/landBasicMessage' , data)
}


//村级 镇保土地资源基本信息表(村委会汇总) 村级
export function getZhen(data){
    return requests.get('/cunzhen/resourcesBasicMessage/queryByIdTotal/' + data.manageId , data)
}
//村级 镇堡土地资源基本信息表（生产组明细）
export function getZhenTotal(data){
    return requests.get('/cunzhen/resourcesBasicMessage/list/?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//村级 镇堡土地资源基本信息表（生产组明细） add
export function getZhenAdd(data){
    return requests.post('/cunzhen/resourcesBasicMessage' , data)
}
//村级 镇保土地资源基本信息表(村委会汇总) 详情
export function getZhenInfo(data){
    return requests.get('/cunzhen/resourcesBasicMessage/' + data)
}
//村级 镇保土地资源基本信息表(村委会汇总) 编辑
export function getZhenEdit(data){
    return requests.put('/cunzhen/resourcesBasicMessage' , data)
}
//村级 镇保土地资源基本信息表(村委会汇总) 删除
export function getZhenDel(data){
    return requests.delete('/cunzhen/resourcesBasicMessage/' + data)
}
//村级 镇堡土地资闲置面积
export function getZhenXian(data){
    return requests.get('/cunzhen/resourcesBasicMessage/inIdleArealist?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}

//村级 镇堡土地资闲置面积
export function getZhenXianExport(data){
    return requests.post('/cunzhen/resourcesBasicMessage/exportInIdleArealist?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 动迁形成存量宅基、建设用地统计表 查询储备、建设用地资源基本信息列表  +详情 两个接口
export function getCunDongTotal(data){
    return requests.get('/cunzhen/landBasicMessage/list?manageType=' + data.manageType+'&manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 动迁形成存量宅基、建设用地统计表 查询储备、建设用地资源基本信息列表  +详情 两个接口
export function getCunDongTotalExport(data){
    return requests.post('/cunzhen/landBasicMessage/export?manageType=' + data.manageType+'&manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 查询储备、建设用地资源基本信息列表 add
export function getCunDongAdd(data){
    return requests.post('/cunzhen/landBasicMessage'  , data)
}
//村级 查询储备、建设用地资源基本信息列表info
export function getCunDongInfo(data){
    return requests.get('/cunzhen/landBasicMessage/' +data)
}
//村级 查询储备、建设用地资源基本信息列表edit
export function getCunDongEdit(data){
    return requests.put('/cunzhen/landBasicMessage'  , data)
}
//村级 查询储备、建设用地资源基本信息列表del
export function getCunDongDel(data){
    return requests.delete('/cunzhen/landBasicMessage/' +data)
}
//村级 制集体土地等其他类信息登记表
export function getCunZhuanTotal(data){
    return requests.get('/cunzhen/collectiveLand/list?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 制集体土地等其他类信息登记表
export function getCunZhuanTotalExport(data){
    return requests.post('/cunzhen/collectiveLand/export?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 制集体土地等其他类信息登记表新增
export function getCunZhuanAdd(data){
    return requests.post('/cunzhen/collectiveLand', data)
}
//村级 制集体土地等其他类信息登记表详情
export function getCunZhuanInfo(data){
    return requests.get('/cunzhen/collectiveLand/'+data)
}
//村级 制集体土地等其他类信息登记表修改
export function getCunZhuanEdit(data){
    return requests.put('/cunzhen/collectiveLand', data)
}
//村级 制集体土地等其他类信息登记表删除
export function getCunZhuanDel(data){
    return requests.delete('/cunzhen/collectiveLand/'+ data)
}
//村级 土地地出租明细表list
export function getCunZuTotal(data){
    return requests.get('/cunzhen/rentOutDetail/list?rentOutType=' + data.rentOutType +'&manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 土地地出租明细表list
export function getCunZuTotalExport(data){
    return requests.post('/cunzhen/rentOutDetail/export?rentOutType=' + data.rentOutType +'&manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//村级 土地地出租明细表add
export function getCunZuAdd(data){
    return requests.post('/cunzhen/rentOutDetail', data)
}
//村级 土地地出租明细表info
export function getCunZuInfo(data){
    return requests.get('/cunzhen/rentOutDetail/'+data)
}
//村级 土地地出租明细表edit
export function getCunZuEdit(data){
    return requests.put('/cunzhen/rentOutDetail', data)
}
//村级 土地地出租明细表del
export function getCunZuDel(data){
    return requests.delete('/cunzhen/rentOutDetail/'+ data)
}
//公司 土地资源基本信息汇总表
export function getComTuTotal(data){
    return requests.get('/cunzhen/companyLandBasicMessage/sumList/?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//公司 土地资源基本信息明细表 / 土地资源基
export function getComTuList(data){
    return requests.get('/cunzhen/companyLandBasicMessage/list/?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize +'&landSubType=' + data.landSubType+'&landCode=' + data.landCode, data)
}
//公司 土地地出租明细表add
export function getComTuAdd(data){
    return requests.post('/cunzhen/companyLandBasicMessage', data)
}
//公司 土地地出租明细表info
export function getComTuInfo(data){
    return requests.get('/cunzhen/companyLandBasicMessage/'+data)
}
//公司 土地地出租明细表edit
export function getComTuEdit(data){
    return requests.put('/cunzhen/companyLandBasicMessage', data)
}
//公司 土地地出租明细表del
export function getComTuDel(data){
    return requests.delete('/cunzhen/companyLandBasicMessage/'+ data)
}
//公司 闲置面积
export function getComXian(data){
    return requests.get('/cunzhen/companyLandBasicMessage/inIdleList?manageType=' + data.manageType+'&manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//居委 区域内空置建设用地统计表
export function getJuTotal(data){
    return requests.get('/cunzhen/vacancyLand/list?manageId=' + data.manageId +'&pageNum=' + data.pageNum +'&pageSize=' + data.pageSize , data)
}
//居委 土地地出租明细表add
export function getJuAdd(data){
    return requests.post('/cunzhen/vacancyLand', data)
}
//居委 土地地出租明细表info
export function getJuInfo(data){
    return requests.get('/cunzhen/vacancyLand/'+data)
}
//居委 土地地出租明细表edit
export function getJuEdit(data){
    return requests.put('/cunzhen/vacancyLand', data)
}
//居委 土地地出租明细表del
export function getJuDel(data){
    return requests.delete('/cunzhen/vacancyLand/'+ data)
}


//大屏 房屋资产（大屏）
export function getIndexHouse(data){
    return requests.get('/cunzhen/houseBasicMessage/house?houseName='+data)
}
//大屏 土地资源（大屏）
export function getIndexLand(){
    return requests.get('/cunzhen/resourcesBasicMessage/landResource')
}

//大屏 土地资源（大屏）
export function getIndexHouseInfo(){
    return requests.get('/cunzhen/houseBasicMessage/rentoutList')
}
//大屏 土地资源（大屏）
export function getIndexLandInfo(){
    return requests.get('/cunzhen/rentOutDetail/rentoutList')
}




// 土地详情------------end》



// 日志
export function logList(data){
    return requests.get('/monitor/logininfor/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize, data)
}
//权限
export function roleList(data){
    return requests.get('/system/role/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize, data)
}
//权限
export function getRoleEdit(data){
    return requests.put('/system/role', data)
}

// 获取验证码
export function getSmsCode(data){
    return requests.get('/captchaSms?phonenumber='+data.phonenumber,data)
}
// 验证码登陆
export function getSmsLogin(data){
    return requests.post('/smsLogin',data)
}

//权限 账号add
export function getUserAdd(data){
    return requests.post('/system/user', data)
}
//权限 账号info
export function getUserInfo(data){
    return requests.get('/system/user/'+data)
}
//权限 账号edit
export function getUserEdit(data){
    return requests.put('/system/user', data)
}
//权限 账号del
export function getUserDel(data){
    return requests.delete('/system/user/'+ data)
}
//权限 账号del
export function getUserList(data){
    return requests.get('/system/user/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize+ '&nickName=' + data.nickName+ '&roleId=' + data.roleId, data)
}

//权限 账号info
export function getManageId(data){
    return requests.get('/cunzhen/manage/list?manageType=' +data +'&pageNum=1&pageSize=100')
}




