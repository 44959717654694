import requests from './request'

// 告警总数
export function getAlarmToal(){
    return requests.get('/custom/home/data/ttNumber')
}

// 电站告警年度排行
export function getAlarmYearly(){
    return requests.get('/custom/home/data/taRankings')
}

// 维修处理年度排行
export function getRepairYearly(){
    return requests.get('/custom/home/data/repairProcessing')
}

// 总维修数量
export function getRepairNum(){
    return requests.get('/custom/home/data/noMaintenance')
}

// 告警类型分布
export function getAlarmType(){
    return requests.get('/custom/home/data/partialType')
}

// 实时报警查询
export function getAlarmList(data){
    return requests.get('/system/alerter/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&title=' + data.title + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&province=' + data.province + '&city=' + data.city + '&area=' + data.area,data)
}

// 实时报警导出
export function getAlarmExport(data){
    return requests.get('/system/alerter/export?title=' + data.title + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&province=' + data.province + '&city=' + data.city + '&area=' + data.area,data)
}

// 报修管理查询
export function getRepairList(data){
    return requests.get('/system/alerter/miniList?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&title=' + data.title + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&province=' + data.province + '&city=' + data.city + '&district=' + data.district,data)
}

// 保修管理导出
export function getRepairExport(data){
    return requests.get('/system/alerter/miExport?title=' + data.title + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&province=' + data.province + '&city=' + data.city + '&district=' + data.district,data)
}

