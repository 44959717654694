<!--
 * @Author: ‘巴博尔’ 2164119982@qq.com
 * @Date: 2023-04-26 14:52:25
 * @LastEditors: ‘巴博尔’ 2164119982@qq.com
 * @LastEditTime: 2023-04-27 16:15:20
 * @FilePath: \guangfu-pc\src\pages\OverviewOfInverterseDetails.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="container">
    <div class="container-checklist">
      <div
        class="container-checklist-init"
        :class="checkIndex == index ? 'container-checklist-change' : ''"
        v-for="(item, index) in checkList"
        :key="index"
        @click="clickCheckChange(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <template>
      <OverviewOfInverterseDetailsOverView
        v-if="checkIndex == 0"
        :rowData="rowData"
      />
      <OverviewOfInverterseDetailsInfo
        v-if="checkIndex == 1"
        @checkParent="checkParent"
        :rowData="rowData"
      />
      <OverviewOfInverterseDetailsLine
        v-if="checkIndex == 2"
        :rowData="rowData"
      />
    </template>
  </div>
</template>

<script>
import OverviewOfInverterseDetailsOverView from "@/pages/OverviewOfInverterseDetailsOverView.vue";
import OverviewOfInverterseDetailsInfo from "@/pages/OverviewOfInverterseDetailsInfo.vue";
import OverviewOfInverterseDetailsLine from "@/pages/OverviewOfInverterseDetailsLine.vue";
export default {
  name: "OverviewOfInverterseDetails",
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  components: {
    OverviewOfInverterseDetailsOverView,
    OverviewOfInverterseDetailsInfo,
    OverviewOfInverterseDetailsLine,
  },
  data() {
    return {
      checkList: [
        {
          label: "概览",
          value: "",
        },
        {
          label: "设备信息",
          value: "",
        },
        {
          label: "曲线",
          value: "",
        },
      ],
      checkIndex: 0,
    };
  },

  mounted() {
    console.log(this.rowData, "ppppp");
  },

  methods: {
    clickCheckChange(row, index) {
      this.checkIndex = index;
    },
    checkParent() {
      this.checkIndex = 0;
      window.scrollTo({
        top: 0, // 滚动到页面顶部
        behavior: "smooth", // 添加平滑滚动效果
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &-checklist {
    display: flex;
    flex-wrap: wrap;
    &-init {
      color: #02b5ff;
      font-size: 1.0417vw /* 20px -> 1.0417vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      background: url("~@/assets/icon/icon1.png") no-repeat;
      background-size: 100% 100%;
      width: 7.2917vw /* 140px -> 7.2917vw */;
      height: 2.6042vw /* 50px -> 2.6042vw */;
      line-height: 2.6042vw /* 50px -> 2.6042vw */;
      text-align: center;
      cursor: pointer;
    }
    &-change {
      color: #ffffff !important;
    }
    &-init:nth-child(2) {
      margin: 0 1.0417vw /* 20px -> 1.0417vw */;
    }
  }
}
</style>
