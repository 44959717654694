import requests from './request'

// 查询节点列表
export function getNodeList(data){
    return requests.get('/custom/node/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&title=' + data.title + '&ndName=' + data.ndName + '&ndMac=' + data.ndMac,data)
}

// 新增节点
export function addNode(data){
    return requests.post('/custom/node',data)
}

// 获取企业列表
export function getCompanyList(){
    return requests.get('/custom/enterprise/listAll')
}

// 获取电站列表
export function getStationList(id){
    return requests.get('/custom/station/listAll/' + id)
}

// 查看详细信息
export function getNodeDetail(id){
    return requests.get('/custom/node/' + id)
}

// 修改节点
export function modifyNode(data){
    return requests.put('/custom/node',data)
}

// 删除节点
export function deleteNode(id){
    return requests.get('/custom/node/del/' + id)
}