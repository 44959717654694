<template>
  <div class="container">
    <div class="left-title" v-if="type == 7">组串离散率</div>
    <div class="left-title" v-if="type == 9">逆变器等效时长</div>
    <div class="back" @click="backPrev">返回</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div style="display: flex">
            <el-form-item label="容量：">
              <el-input
                v-model="form.capacity"
                placeholder="请输入容量"
                style="width: 15.6vw; margin-right: 2.5vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="报文时间：">
              <el-date-picker
                style="width: 15vw"
                v-model="form.startDate"
                type="date"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div
            style="width: 65vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="逆变器名称:">
              <el-input
                v-model="form.name"
                placeholder="请输入逆变器名称"
                style="width: 15.6vw; margin-right: 3vw"
              ></el-input>
            </el-form-item>
            <div style="display: flex">
              <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div>
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        v-if="type == 7"
        :data="powerGeneration"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="area" label="电站区域"></el-table-column>
        <el-table-column prop="itName" label="逆变器名称"></el-table-column>
        <el-table-column prop="itCapacity" label="容量"></el-table-column>
        <el-table-column prop="num" label="组串离散率"></el-table-column>
        <el-table-column prop="date" label="报文时间"></el-table-column>
      </el-table>
      <el-table
        v-if="type == 9"
        :data="powerGeneration"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="area" label="电站区域"></el-table-column>
        <el-table-column prop="itName" label="逆变器名称"></el-table-column>
        <el-table-column prop="num" label="容量"></el-table-column>
        <el-table-column prop="itCapacity" label="等效小时"></el-table-column>
        <el-table-column prop="date" label="报文时间"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getInverterHourList,
  exportInverterHourList,
  getInverterDispersionRateList,
  exportInverterDispersionRateList,
} from "@/api/dataAnalysis";
export default {
  name: "stringOfflineRate",
  data() {
    return {
      form: {
        capacity: "", // 容量
        name: "", // 逆变器名称
        startDate: "", // 报文时间
      },
      powerGeneration: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      type: "",
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    backPrev() {
      let type = this.type;
      if (this.fromPage == "/equipmentOverview") {
        this.$router.push('/equipmentOverview')
      } else {
        this.$router.push({
          path: "/rankingTotalEnergy",
          query: { type },
        });
      }
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getList() {
      if (this.$route.query.bTime != undefined && this.form.startDate == "") {
        this.form.startDate = this.$route.query.bTime;
      } else {
        this.form.startDate = this.formateDate(this.form.startDate);
      }
      if (
        this.$route.query.itCapacity != undefined &&
        this.form.capacity == ""
      ) {
        this.form.capacity = this.$route.query.itCapacity;
      }
      let title = "";
      if (this.$route.query.title == undefined) {
        title = this.$route.query.psName;
      } else {
        title = this.$route.query.title;
      }
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        bTime: this.form.startDate,
        title: title,
        itName: this.form.name,
        itCapacity: this.form.capacity
      };
      if (this.type == 9) {
        getInverterHourList(data).then((res) => {
          this.powerGeneration = res.rows;
          this.total = res.total;
        });
      } else {
        getInverterDispersionRateList(data).then((res) => {
          this.powerGeneration = res.rows;
          this.total = res.total;
        });
      }
    },
    query() {
      this.currentPage = 1
      this.getList();
    },
    reset() {
      this.form.capacity = "";
      this.form.name = "";
      this.form.startDate = this.$route.query.bTime;
      this.getList();
    },
    exportData() {
      let data = {
        bTime: this.form.startDate,
        title: this.$route.query.title,
        itName: this.form.name,
        itCapacity: this.form.capacity,
        type: this.$route.query.date,
      };
      if (this.type == 9) {
        exportInverterHourList(data).then((res) => {
          console.log(res, "的撒很激动i");
          let name = res.msg;
          if (res.code == 200) {
            this.$axios({
              url:
                "https://admin.pvshield.cn/guang/common/download?fileName=" +
                name +
                "&delete=true",
              methods: "GET",
              responseType: "blob",
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              console.log(res, "sdhuahui");
              const blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              console.log(blob, "----------0990");
              const fileName = name + ".xls";
              const linkNode = document.createElement("a");

              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击

              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            });
          }
        });
      } else {
        exportInverterDispersionRateList(data).then((res) => {
          console.log(res, "的撒很激动i");
          let name = res.msg;
          if (res.code == 200) {
            this.$axios({
              url:
                "https://admin.pvshield.cn/guang/common/download?fileName=" +
                name +
                "&delete=true",
              methods: "GET",
              responseType: "blob",
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              console.log(res, "sdhuahui");
              const blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              console.log(blob, "----------0990");
              const fileName = name + ".xls";
              const linkNode = document.createElement("a");

              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击

              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            });
          }
        });
      }
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromPage = from.path;
    });
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
  }
  .back {
    position: absolute;
    top: 8vh;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
  }
  .right-container {
    width: 66.4vw;
    position: absolute;
    left: 5.5vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      width: 100%;
      margin-bottom: 3vh;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 4.6vh;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
::v-deep .el-form-item__label {
  width: 7.2vw;
  text-align: left;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-range-input {
  background: none;
}
::v-deep .el-date-editor .el-range-separator {
  color: #fff;
  font-size: calc(100vw * 17 / 1920);
  line-height: 4.3vh;
  height: 4.6vh;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: calc(100vw * 17 / 1920);
  line-height: 4.6vh;
  height: 4.6vh;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-date-editor .el-range-input {
  font-size: calc(100vw * 16 / 1920);
  color: #fff;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .el-pager li.active {
  color: #409eff;
}
</style>
