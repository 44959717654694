<template>
  <div class="container">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goBack()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="right-container">
      <div style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);text-align: center;padding-bottom: 4vh;">
        {{info.manageName}}
      </div>
      <div class="right-top">
            <div style="display: flex; height: 4.6vh;padding-bottom: 3vh;">

              <div class="query" v-bind:class=" card === 'Point'?'light':''" @click="chooseCard('Point')">镇属房屋资产点位图</div>
              <div class="query"  v-bind:class=" card === 'Summary'?'light':''" @click="chooseCard('Summary')">房屋资产核查汇总表</div>
              <div class="query" v-bind:class=" card === 'Zu'?'light':''" @click="chooseCard('Zu')">房屋出租明细表</div>
              <div class="query" v-bind:class="card === 'Property'?'light':''" @click="chooseCard('Property')">房屋资产基本信息表</div>
              <div class="query" v-bind:class=" card === 'Xian'?'light':''" @click="chooseCard('Xian')">闲置面积</div>
              <div class="query" v-bind:class=" card === 'Cun'?'light':''" @click="chooseCard('Cun')" >存量安置房统计表</div>
            </div>
      </div>
      <!--点位-->
      <div v-if="card === 'Point'" style="width: 100%; text-align: center">
        <div class="image-wrapper_1 flex-row">
          <img
              v-if="info.housePic"
              class="image_1"
              referrerpolicy="no-referrer"
              :src="info.housePic"
              style="width: 68%;height: 68%"
          />
          <div v-if="!info.housePic&&isAdmin=='admin'">
            <div style="width: 100%;padding-bottom: 20px">上传附件</div>
            <el-upload
                class="upload-demo"
                action="https://townassets.zhishang2017.com/api/cunzhen/accessory/uploadFile"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-success="uploadSuc"
                multiple
                :limit="1"
                :on-exceed="handleExceed">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">暂无图片，请上传 只能上传jpg/png文件，且不超过20M</div>
            </el-upload>
          </div>
        </div>
        <div style="display: flex;justify-content: left; ">
          <a class="del"  @click="downPic" v-if="info.housePic">下载</a>
          <div class="del" @click="delPic" v-if="isAdmin=='admin'"  >删除</div>
        </div>
      </div>
<!--     <Point v-if="card === 'Point'" :rowId = "$route.query.id"></Point>-->
      <!--汇总-->
      <Summary v-if="card === 'Summary'" :rowId = "$route.query.id"></Summary>
      <!--资产-->
      <Property v-if="card === 'Property'" :rowId = "$route.query.id" :isAdmin ="isAdmin" ></Property>
      <!--汇总-->
      <Xian v-if="card === 'Xian'" :rowId = "$route.query.id"></Xian>
      <!--汇总-->
      <Zu v-if="card === 'Zu'" :rowId = "$route.query.id"></Zu>
      <!--汇总-->
      <Cun v-if="card === 'Cun'" :rowId = "$route.query.id" :isAdmin ="isAdmin" ></Cun>
    </div>
  </div>

</template>

<script>
import { getUserList, deleteUser, isSuperManager } from "@/api/jurisdiction";
import  Point  from "@/components/housingPoint.vue";
import  Summary  from "@/components/housingSummary.vue";
import  Property  from "@/components/housingProperty.vue";
import  Xian  from "@/components/housingXian.vue";
import  Cun  from "@/components/housingCun.vue";
import  Zu  from "@/components/housingZu.vue";
import {getHousingEdit, getHousingInfo} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      userList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      card:'Point',
      cardLight:true,
      info:{},
      isAdmin:''
    };
  },
  components:{
    Point,
    Summary,
    Property,
    Xian,
    Cun,
    Zu,
  },
  methods: {
    chooseCard(e){
      this.card = e;
    },
    goBack(){
      this.$router.push("/housingTotal");
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickName: this.form.nickName,
        userName: this.form.userName,
        etName:this.form.etName, // 企业
      };
      getUserList(data).then((res) => {
        console.log(res, "阿什杜阿虎");
        this.userList = res.rows;
        this.total = res.total;
      });
    },
    getInfo() {
      getHousingInfo(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.info = res.data
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
      console.log(222);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    uploadSuc(response, file, fileList){
      if (response.code===200){

        let data = this.info
        data.housePic = response.data.url;
        console.log(data,'data')
        getHousingEdit(data).then((res) => {
          if (res.code == 200) {
            this.getInfo();
            // this.info = res.data
          }
        });
      }
      console.log(response);
      console.log(file);
      console.log(fileList);

    },
    delPic(){
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = this.info
        data.housePic = '';
        getHousingEdit(data).then((res) => {
          if (res.code == 200) {
            this.getInfo();
          }
        });
      });
    },
     async downPic(){
      // console.log("ImageDownload`https://townassets.zhishang2017.com/pic/test.xlsx`" );
       console.log("ImageDownload`" +this.info.housePic +"`" );
       // var image = new Image();
       // image.src = this.info.housePic;
       // image.setAttribute("crossOrigin", "anonymous");
       // var _this = this;
       // image.onload = function () {
       //   var canvas = document.createElement("canvas");
       //   canvas.width = image.width;
       //   canvas.height = image.height;
       //   var context = canvas.getContext("2d");
       //   context.drawImage(image, 0, 0, image.width, image.height);
       //   var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
       //   var a = document.createElement("a"); // 生成一个a元素
       //   var event = new MouseEvent("click"); // 创建一个单击事件
       //   a.download = _this.projectName || "photo"; // 设置图片名称
       //   a.href = url; // 将生成的URL设置为a.href属性
       //   a.dispatchEvent(event); // 触发a的单击事件
       // };

      // window.open(this.info.housePic);


       // this.downloadBase64Image(this.urlToBase64(this.info.housePic),'点位图')

    },
     downloadBase64Image(base64Data, filename) {
        // 创建一个隐藏的 a 标签
        let a = document.createElement('a');
        a.style.display = 'none';

        // 设置 a 标签的 href 属性为 base64 图片数据
        // 假设 base64Data 是 "data:image/png;base64,..." 这样的字符串
        a.href = base64Data;

        // 设置下载文件的名称
        a.download = filename;

        // 将 a 标签添加到 DOM 中
        document.body.appendChild(a);

        // 触发 a 标签的点击事件
        a.click();

        // 从 DOM 中移除 a 标签
        document.body.removeChild(a);
    },
    async urlToBase64(url) {
      return fetch(url)
          .then(response => response.blob())
          .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob); // 注意这里我们实际上读取为DataURL，它已经包含了base64编码的前缀
          }))
          .then(dataUrl => {
            // 如果你只需要base64编码的部分（不包括MIME类型和前缀），可以这样处理：
            const base64Data = dataUrl.split(',')[1];
            return base64Data;
          })
          .catch(error => {
            console.error('Error fetching image as base64:', error);
            throw error;
          });
    }


  },
  mounted() {
    console.log(this.$route.query,"Eeeee")
     this.getInfo();
    this.isAdmin = localStorage.getItem("userInfo");
    console.log(this.isAdmin,"isAdmin")
    if (this.$route.query.card){
      this.card=this.$route.query.card
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.light{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;

}

.del{
  width: 6vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
  margin-left: 1vh;
}
</style>
