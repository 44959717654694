/*
 * @Author: ‘巴博尔’ 2164119982@qq.com
 * @Date: 2023-04-06 17:48:05
 * @LastEditors: ‘巴博尔’ 2164119982@qq.com
 * @LastEditTime: 2023-04-27 13:20:14
 * @FilePath: \guangfu-pc\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import {MessageBox,Message} from 'element-ui'

const service = axios.create({
  // baseURL: "http://collect.yyinhong.cn/guang",
  // baseURL:"https://admin.pvshield.cn/guang",
  // baseURL:"https://admin.pvshield.cn/guang",
  // baseURL:"http://123.206.117.218/api",
  baseURL:"https://townassets.zhishang2017.com/api",
  // baseURL:"http://192.168.0.182:8993",
  // baseURL:"http://yellow-lion-30.n.gaoyixia.com:8081",
  // baseURL:"http://192.168.0.144:8993",
  timeout: 30000, // 过期时间

});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 发送请求之前做的
    const Authorization = localStorage.getItem("token")
    if (Authorization) {
      config.headers["Authorization"] = Authorization;
    }
    if (/get/i.test(config.method)) {
      config.params = config.params || {};
      config.params.temp = Date.parse(new Date()) / 1000;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    console.log(response, "wwwww");
    const res = response.data;
    const code = response.status;
    //if the custom code is not 20000, it is judged as an error.
    if (res.msg.indexOf('认证失败') != -1 || code == 401) {
      // to re-login
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        location.href = "/login";
      }).catch(() => {
        location.href = "/login";

      })
    }
    if (response.data.code == 500) {
      Message({
        message: res.msg,
        type: 'error'
      })
    }
    if (code !== 200 && code !== 401) {
      return Promise.reject();
    } else {
      return res;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
