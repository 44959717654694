<template>
  <div style="background: #F7F7F7;width: 100%;height: 100%;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">基本信息</div>
      <el-form ref="form" :model="form"  >
        <el-row >
          <el-col :span="6">
            <el-form-item label="编号" label-width="60px">
              <el-input v-model="form.houseCode" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用情况(可多选)" label-width="120px">
              <el-checkbox-group v-model="form.houseUses">
                <el-checkbox-button label="1"  name="type">自用</el-checkbox-button>
                <el-checkbox-button label="2" name="type">无偿提供使用</el-checkbox-button>
                <el-checkbox-button label="3" name="type">闲置</el-checkbox-button>
                <el-checkbox-button label="4" name="type">被占用</el-checkbox-button>
                <el-checkbox-button label="5" name="type">出租</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="房屋资产名称" label-width="120px">
              <el-input v-model="form.houseName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="有无产权证" label-width="100px">
              <el-radio-group v-model="form.haveTitleCertificate">
                <el-radio label="0" name="type">有</el-radio>
                <el-radio label="1" name="type">无</el-radio>
                <el-radio label="2" name="type">部分有</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="建筑位置" label-width="80px">
              <el-input v-model="form.position"></el-input>
<!--              <el-select v-model="form.region" placeholder="请选择活动区域">-->
<!--                <el-option label="区域一" value="shanghai"></el-option>-->
<!--                <el-option label="区域二" value="beijing"></el-option>-->
<!--              </el-select>-->
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="权证登记单位" label-width="120px">
              <el-input v-model="form.registrationUnit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="投入使用时间" label-width="120px">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.comeIntoUseDate" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row >
          <el-col :span="6">
            <el-form-item label="权证登记号码" label-width="110px">
              <el-input v-model="form.warrantRegistrationNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="管理单位" label-width="80px">
              <el-input v-model="form.managementUnit" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
          <el-form-item label="村资镇管" label-width="80px">
            <el-radio-group v-model="form.villageTown" >
              <el-radio label="0" name="type">是</el-radio>
              <el-radio label="1" name="type">否</el-radio>
            </el-radio-group>
          </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发证时间" label-width="80px">

                <el-date-picker type="date" placeholder="选择日期" v-model="form.dateOfIssue" style="width: 100%;"></el-date-picker>

            </el-form-item>
          </el-col>



        </el-row>

        <el-row>
          <el-col :span="6">
            <el-form-item label="经纬度" label-width="80px">
              <el-input v-model="form.lnglat"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生产组资产" label-width="100px">
              <el-radio-group v-model="form.productionGroupAssets">
                <el-radio label="0" name="type">是</el-radio>
                <el-radio label="1" name="type">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" label-width="60px">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
  <!--      <el-form-item>-->
  <!--        <el-button type="primary" @click="onSubmit">立即创建</el-button>-->
  <!--        <el-button>取消</el-button>-->
  <!--      </el-form-item>-->
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">房屋建筑幢层数</div>
      <el-form ref="form" :model="form"  >
        <el-row >
          <el-col :span="6">
            <el-form-item label="建筑面积(m²)" label-width="100px">
              <el-input v-model="buildTotal" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="占地面积(m²)" label-width="100px">
              <el-input v-model="floorTotal"  disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="房屋幢,层数" label-width="100px">
              <el-input v-model="form.floorNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="有证建筑面积(m²)" label-width="130px">
              <el-input v-model="form.certifiedBuildingArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="无证建筑面积(m²)" label-width="130px">
              <el-input v-model="form.unlicensedFloorSpace"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="有证占地面积(m²)" label-width="130px">
              <el-input v-model="form.certifiedFloorSpace"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="无证占地面积(m²)" label-width="130px">
              <el-input v-model="form.uncensedFloorSpace"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row >
          <el-col :span="6">
            <el-form-item label="房屋建筑结构" label-width="100px">
              <el-radio-group v-model="form.buildingStructure">
                <el-radio label="1" name="type">砖混</el-radio>
                <el-radio label="2" name="type">框架</el-radio>
                <el-radio label="3" name="type">钢结构</el-radio>
                <el-radio label="4" name="type">临时</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产权人" label-width="80px">
              <el-input v-model="form.propertyOwner"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="使用人" label-width="80px">
              <el-input v-model="form.useUser"></el-input>
            </el-form-item>
          </el-col>
        </el-row>



        <el-form-item label="备注" label-width="60px">
          <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="房屋性质" label-width="100px">
          <el-radio-group v-model="form.typeOfTheHouse">
            <el-radio label="1" name="type">国有</el-radio>
            <el-radio label="2" name="type">事业单位</el-radio>
            <el-radio label="3" name="type">镇级集体</el-radio>
            <el-radio label="4" name="type">村级集体</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="取得方式" label-width="100px">
          <el-radio-group v-model="form.getMethod">
            <el-radio label="1" name="type">购置</el-radio>
            <el-radio label="2" name="type">自建</el-radio>
            <el-radio label="3" name="type">接受捐赠</el-radio>
            <el-radio label="4" name="type">调拨</el-radio>
            <el-radio label="5" name="type">租入或借入</el-radio>
            <el-radio label="6" name="type">提前动迁</el-radio>
            <el-radio label="7" name="type">收储</el-radio>
            <el-radio label="8" name="type">未安置房源</el-radio>
            <el-radio label="9" name="type">托管（区级）</el-radio>
            <el-radio label="10" name="type">托管（镇级）</el-radio>
            <el-radio label="11" name="type">托管（村级）</el-radio>
            <el-radio label="12" name="type">公建配套</el-radio>
            <el-radio label="13" name="type">应拆未拆</el-radio>
            <el-radio label="14" name="type">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="是否入账" label-width="100px">
              <el-radio-group v-model="form.isInAccount">
                <el-radio label="0" name="type">是</el-radio>
                <el-radio label="1" name="type">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账面原值（万元）" label-width="120px">
              <el-input v-model="form.originalBookValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否录入经发中心、财政所平台" label-width="220px">
              <el-radio-group v-model="form.isInFinancePlatform">
                <el-radio label="0" name="type">是</el-radio>
                <el-radio label="1" name="type">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

          <el-form-item label="抵押贷款情况" label-width="100px">
            <el-radio-group v-model="form.isMortgageLoan">
              <el-radio label="0" name="type">是</el-radio>
              <el-radio label="1" name="type">否</el-radio>
            </el-radio-group>
          </el-form-item>
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">自用面积</div>
      <el-button type="primary" style="margin-bottom: 16px" @click="addOwn('ownUseArea')">新增</el-button>
      <el-form ref="form" v-if="form.ownUseArea!=null" >
        <template v-for="(item ,index) in form.ownUseArea"  >
          <el-row  >
            <el-col :span="6">
              <el-form-item label="幢号" label-width="100px">
                <el-input v-model="item.buildingNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="楼层" label-width="100px">
                <el-input v-model="item.floor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="室号" label-width="100px">
                <el-input v-model="item.roomNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="使用人信息" label-width="130px">
                <el-input v-model="item.useUser"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="自用房建筑面积(m²)" label-width="200px">
                <el-input v-model="item.area"></el-input>
              </el-form-item>
            </el-col>
              <el-button type="primary" style="margin-left: 50px" @click="delOwn(index,'ownUseArea')">删除</el-button>
          </el-row>
        </template>
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">无偿使用面积</div>
      <el-button type="primary" style="margin-bottom: 16px" @click="addOwn('gratisArea')">新增</el-button>
      <el-form ref="form" v-if="form.gratisArea!=null" >
        <template v-for="(item ,index) in form.gratisArea"  >
          <el-row  >
            <el-col :span="6">
              <el-form-item label="幢号" label-width="100px">
                <el-input v-model="item.buildingNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="楼层" label-width="100px">
                <el-input v-model="item.floor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="室号" label-width="100px">
                <el-input v-model="item.roomNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="使用人信息" label-width="130px">
                <el-input v-model="item.useUser"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="无偿使用面积(m²)" label-width="200px">
                <el-input v-model="item.area"></el-input>
              </el-form-item>
            </el-col>
            <el-button type="primary" style="margin-left: 50px" @click="delOwn(index,'gratisArea')">删除</el-button>
          </el-row>
        </template>
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">闲置面积</div>
      <el-button type="primary" style="margin-bottom: 16px" @click="addOwn('inIdleArea')">新增</el-button>
      <el-form ref="form" v-if="form.inIdleArea!=null"  >
        <template v-for="(item ,index) in form.inIdleArea"  >
          <el-row  >
            <el-col :span="6">
              <el-form-item label="幢号" label-width="100px">
                <el-input v-model="item.buildingNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="楼层" label-width="100px">
                <el-input v-model="item.floor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="室号" label-width="100px">
                <el-input v-model="item.roomNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="使用人信息" label-width="130px">
                <el-input v-model="item.useUser"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="闲置面积(m²)" label-width="200px">
                <el-input v-model="item.area"></el-input>
              </el-form-item>
            </el-col>
            <el-button type="primary" style="margin-left: 50px" @click="delOwn(index,'inIdleArea')">删除</el-button>
          </el-row>
        </template>
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">被占用面积</div>
      <el-button type="primary" style="margin-bottom: 16px" @click="addOwn('beOccupiedArea')">新增</el-button>
      <el-form ref="form" v-if="form.beOccupiedArea!=null" >
        <template v-for="(item ,index) in form.beOccupiedArea"  >
          <el-row  >
            <el-col :span="6">
              <el-form-item label="幢号" label-width="100px">
                <el-input v-model="item.buildingNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="楼层" label-width="100px">
                <el-input v-model="item.floor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="室号" label-width="100px">
                <el-input v-model="item.roomNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="使用人信息" label-width="130px">
                <el-input v-model="item.useUser"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="被占用面积(m²)" label-width="200px">
                <el-input v-model="item.area"></el-input>
              </el-form-item>
            </el-col>
            <el-button type="primary" style="margin-left: 50px" @click="delOwn(index,'beOccupiedArea')">删除</el-button>
          </el-row>
        </template>
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">出租面积</div>
      <el-button type="primary" style="margin-bottom: 16px" @click="addOwn('landRentOutDetailBo')">新增</el-button>
      <el-form ref="form" :model="form"  >
        <template v-for="(item ,index) in form.landRentOutDetailBo"  >
          <el-row >
            <el-col :span="6">
              <el-form-item label="幢号" label-width="100px">
                <el-input v-model="item.buildingNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="楼层" label-width="100px">
                <el-input v-model="item.floor"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="室号" label-width="100px">
                <el-input v-model="item.roomNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="承租单位名称" label-width="130px">
                <el-input v-model="item.lessee"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人/负责人" label-width="130px">
                <el-input v-model="item.legalPerson"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系方式" label-width="130px">
                <el-input v-model="item.telphone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用途" label-width="130px">
                <el-input v-model="item.useFor"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row >
            <el-col :span="6">
              <el-form-item label="建筑物位置" label-width="130px">
                <el-input v-model="item.addressDetail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="出租面积(m²)" label-width="130px">
                <el-input v-model="item.landArea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="租金单价(元/m²/天)" label-width="130px">
                <el-input v-model="item.rentUnitPrice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="年租金（万元）" label-width="130px">
                <el-input v-model="item.yearRent"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="出租是否按流程审批" label-width="200px">
            <el-radio-group v-model="item.rentOutIsProcessApprovel">
              <el-radio label="0" name="type">是</el-radio>
              <el-radio label="1" name="type">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="如大体量，是否按集资委或经联社审批" label-width="200px">
            <el-radio-group v-model="item.bigIsApprovel">
              <el-radio label="0" name="type">是</el-radio>
              <el-radio label="1" name="type">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-form-item label="是否存在欠付情况" label-width="200px">
              <el-radio-group v-model="item.arrears">
                <el-radio label="0" name="type">是</el-radio>
                <el-radio label="1" name="type">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是（欠费月数）" label-width="200px">
              <el-input v-model="item.isArrears"></el-input>
<!--              <el-radio-group v-model="item.isArrears">-->
<!--                <el-radio label="0" name="type">是</el-radio>-->
<!--                <el-radio label="1" name="type">否</el-radio>-->
<!--              </el-radio-group>-->
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span = 6>
              <el-form-item label="合同编号" label-width="100px">
                <el-input v-model="item.contractCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span = 6>
            <el-form-item label="合同开始" label-width="100px">
              <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="item.contractBeginDate"   style="width: 100%;"></el-date-picker>
            </el-form-item>
            </el-col>
            <el-col :span = 6>
            <el-form-item label="合同结束" label-width="100px">
              <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="item.contractEndDate"   style="width: 100%;"></el-date-picker>
            </el-form-item>
            </el-col>
            <el-button type="primary" style="margin-left: 50px" @click="delOwn(index,'landRentOutDetailBo')">删除</el-button>
          </el-row>


        </template>
      </el-form>
    </div>

    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">上传附件</div>
      <el-upload
          class="upload-demo"
          action="https://townassets.zhishang2017.com/api/cunzhen/accessory/uploadFile"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="uploadSuc"
          multiple
          :limit="9"
          :on-exceed="handleExceed"
          :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">文件不超过20M</div>
      </el-upload>

    <div style="width: 100%;text-align: right;">
      <el-button type="primary" @click="onSubmit">确定</el-button>
      <el-button @click="onBack">取消</el-button>
    </div>



      <el-dialog
          style="top:5%"
          :visible.sync="dialogImg"

      >
        <div style="text-align: center">
          <img
              class="image_1"
              referrerpolicy="no-referrer"
              :src="showImg"
              style="width: 68%;height: 68%"
          />

        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="downThis">下 载</el-button>
        </div>

      </el-dialog>

    </div>
  </div>

</template>
<script>
import {getHosingAdd, getHosingInfo, getHosingEdit, getJuEdit, getJuAdd, getHousingEdit} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        houseUses: [],
        resource: '',
        desc: '',
        haveTitleCertificate:'',
        buildingStructure:'',
        typeOfTheHouse:'',
        getMethod:'',
        isInFinancePlatform:'',
        isMortgageLoan:'',
        manageId:'',
        ownUseArea:[],
        gratisArea:[],
        inIdleArea:[],
        beOccupiedArea:[],
        landRentOutDetailBo:[],
        picList:[],
        certifiedBuildingArea:'',
        unlicensedFloorSpace:'',
        certifiedFloorSpace:'',
        uncensedFloorSpace:'',
        buildingArea:'',
        floorSpace:'',
      },
      id:'',
      dialogImg:false,
      showImg:'',
      fileList: [],
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now();
        // },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  computed:{
    buildTotal: function () {
      let num = Number(this.form.certifiedBuildingArea) + Number(this.form.unlicensedFloorSpace)
      this.form.buildingArea = num;
      return   num
    },
    floorTotal: function () {
      let num = Number(this.form.certifiedFloorSpace) + Number(this.form.uncensedFloorSpace)
      this.form.floorSpace = num;
      return   num
    },
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/housing', query: {id: this.form.manageId,card: 'Property'}});
    },
    onSubmit() {
      this.form.manageId=this.id;
      let data = this.form;
      console.log(this.fileList,'file')
      if (this.fileList){
        data.picList = this.fileList;
      }
      if (this.form.id){
        getHosingEdit(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.$router.push({ path: '/housing', query: {id: this.form.manageId,card: 'Property'}});
          }
        });
      }else {
        getHosingAdd(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.$router.push({ path: '/housing', query: { card: 'Property',id: this.form.manageId}});
          }
        });
      }

      // this.$router.push('/housingTotal');
    },


    addOwn(listName) {
      console.log(listName,'listName')
       if (listName==='ownUseArea'){
        this.form.ownUseArea.push({
          buildingNumber: "",
          floor: "",
          roomNumber: "",
          useUser: "",
          area: ""
        });
      }else if (listName==='gratisArea'){
        this.form.gratisArea.push({
          buildingNumber: "",
          floor: "",
          roomNumber: "",
          useUser: "",
          area: ""
        });
      }else if (listName==='inIdleArea'){
        this.form.inIdleArea.push({
          buildingNumber: "",
          floor: "",
          roomNumber: "",
          useUser: "",
          area: ""
        });
      }else if (listName==='beOccupiedArea'){
        this.form.beOccupiedArea.push({
          buildingNumber: "",
          floor: "",
          roomNumber: "",
          useUser: "",
          area: ""
        });
      }else {
         this.form.landRentOutDetailBo.push({
           buildingNumber: "",
           goodsCode: "",
           goodsName: "",
           goodsCategory: "",
           purchaseDiscountRates: "",
           lessee:'',
           legalPerson:'',
           telphone:'',
           useFor:'',
           landAddress:'',
           landArea:'',
           rentUnitPrice:'',
           yearRent:'',
           rentOutIsProcessApprovel:'',
           bigIsApprovel:'',
           arrears:'',
           isArrears:'',
           contractCode:'',
           contractBeginDate:'',
           contractEndDate:'',

         });
       }
    },
    delOwn(index,listName){
      if (listName==='ownUseArea'){
        this.form.ownUseArea.splice(index, 1);
      }else if (listName==='gratisArea'){
        this.form.gratisArea.splice(index, 1);
      }else if (listName==='inIdleArea'){
        this.form.inIdleArea.splice(index, 1);
      }else if(listName==='beOccupiedArea') {
        this.form.beOccupiedArea.splice(index, 1);
      }else{
        this.form.landRentOutDetailBo.splice(index, 1);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList,'handleRemove');
      console.log(this.fileList,'handleRemove');
      this.fileList = this.fileList.filter(item => item !== file);
      // this.fileList.splice(file);
    },
    handlePreview(file) {
      let isShow = file.url.slice(-3);
      console.log(isShow,'isShow')
      if (isShow==="png"||isShow==="jpg"){
        this.showImg = file.url;
        this.dialogImg = true;
      }else{
        console.log("ImageDownload`" +file.url +"`" );
      }
    },
    downThis(){
      console.log("ImageDownload`" +this.showImg +"`" );
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      console.log(file,'beforeRemove');
      console.log(this.fileList,'this.fileList');
      console.log(fileList,'fileList');
      // return this.fileList.splice(file.url);
    },
    uploadSuc(response, file, fileList){
      if (response.code===200){
        let data = {name: response.data.originalFilename,url:response.data.url}
        this.fileList.push(data)
        // fileList.push(file)
      }
    },
    onBack(){
      this.$router.go(-1);
    },
    initData(){
      getHosingInfo( this.form.id).then((res) => {
        if (res.code===200){
          this.form=res.data;
          if (this.form.picList){
            this.fileList =this.form.picList;
            console.log(this.fileList ,'this.fileList ')
          }

        }
      });
    },
  },
  mounted() {
    this.form.manageId = this.$route.query.id;
    this.id = this.$route.query.id;
    if (this.$route.query.editId){
      this.id  = this.$route.query.id;
      this.form.id = this.$route.query.editId;
      this.initData();
    }
  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}
.text_1 {

  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: calc(100vw * 16 / 1920);
  letter-spacing: 0.20000000298023224px;
  font-family: AlibabaPuHuiTi-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 3px;
}

</style>
