<template>
  <div class="container">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="right-container">
      <div style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 24 / 1920);text-align: center;padding-bottom: 4vh;">
        {{info.manageName}}
      </div>
      <div class="right-top">
            <div style="display: flex; height: 4.6vh;padding-bottom: 3vh;">
              <div class="query" v-bind:class=" card === 'Point'?'light':''" @click="chooseCard('Point')">镇属土地资源分布图</div>
              <div class="query queryLong " v-bind:class=" card === 'cunLand'?'light':''" @click="chooseCard('cunLand')">村级土地资源基本情况统计表</div>
              <div class="query" v-bind:class=" card === 'zhenLand'?'light':''" @click="chooseCard('zhenLand')">镇保土地资源基本信息表</div>
              <div class="query" v-bind:class="card === 'landXian'?'light':''" @click="chooseCard('landXian')">镇保土地闲置面积</div>
              <div class="query queryLong" v-bind:class=" card === 'landDong'?'light':''" @click="chooseCard('landDong')">集中居住、减量化土地统计表</div>
              <div class="query queryLong" v-bind:class=" card === 'landZhuan'?'light':''" @click="chooseCard('landZhuan')">转制集体土地等其他类信息统计表</div>
              <div class="query" v-bind:class=" card === 'landZu'?'light':''" @click="chooseCard('landZu')">土地出租明细表</div>
            </div>
      </div>
      <!--点位-->
      <div v-if="card === 'Point'" style="width: 100%; text-align: center">
        <div class="image-wrapper_1 flex-row">
          <img
              v-if="info.landPic"
              class="image_1"
              referrerpolicy="no-referrer"
              :src="info.landPic"
              style="width: 68%;height: 68%"
          />
          <div v-if="!info.landPic&&isAdmin=='admin'">
            <div style="width: 100%;padding-bottom: 20px">上传附件</div>
            <el-upload
                class="upload-demo"
                action="https://townassets.zhishang2017.com/api/cunzhen/accessory/uploadFile"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-success="uploadSuc"
                multiple
                :limit="1"
                :on-exceed="handleExceed">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">暂无图片，请上传 只能上传jpg/png文件，且不超过20M</div>
            </el-upload>
          </div>
        </div>
        <div style="display: flex;justify-content: left; ">
          <a class="del" @click="downPic" v-if="info.landPic">下载</a>
          <div class="del" @click="delPic" v-if="isAdmin=='admin'"  >删除</div>
        </div>
      </div>

      <cunLand v-if="card === 'cunLand'" :rowId = "$route.query.id" :isAdmin ="isAdmin"></cunLand>
      <zhenLand v-if="card === 'zhenLand'" :rowId = "$route.query.id"></zhenLand>
      <landXian v-if="card === 'landXian'" :rowId = "$route.query.id"></landXian>
      <landDong v-if="card === 'landDong'" :rowId = "$route.query.id"></landDong>
      <landZhuan v-if="card === 'landZhuan'" :rowId = "$route.query.id" :isAdmin ="isAdmin"></landZhuan>
      <landZu v-if="card === 'landZu'" :rowId = "$route.query.id" :isAdmin ="isAdmin"></landZu>
    </div>
  </div>

</template>

<script>
import { getUserList, deleteUser, isSuperManager } from "@/api/jurisdiction";
import  cunLand  from "@/components/land/cunLand.vue";
import  zhenLand  from "@/components/land/zhenLand.vue";
import  zhenProperty  from "@/components/land/zhenProperty.vue";
import  landXian  from "@/components/land/landXian.vue";
import  landDong  from "@/components/land/landDong.vue";
import  landZhuan  from "@/components/land/landZhuan.vue";
import  landZu  from "@/components/land/landZu.vue";
import {getHousingInfo,getHousingEdit} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      userList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
      card:'Point',
      cardLight:true,
      info:{},
      isAdmin:''
    };
  },
  components:{

    cunLand,
    zhenLand,
    zhenProperty,
    landXian,
    landDong,
    landZhuan,
    landZu
  },
  methods: {
    chooseCard(e){
      this.card = e;
    },
    goIndex(){
      this.$router.push("/landTotal");
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickName: this.form.nickName,
        userName: this.form.userName,
        etName:this.form.etName, // 企业
      };
      getUserList(data).then((res) => {
        console.log(res, "阿什杜阿虎");
        this.userList = res.rows;
        this.total = res.total;
      });
    },
    getInfo() {
      getHousingInfo(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.info = res.data
        }

      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("ImageDownload`" +file.url +"`" );
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    uploadSuc(response, file, fileList){
      if (response.code===200){

        let data = this.info
        data.landPic = response.data.url;
        console.log(data,'data')
        getHousingEdit(data).then((res) => {
          if (res.code == 200) {
            this.getInfo();
            // this.info = res.data
          }
        });
      }
      console.log(response);
      console.log(file);
      console.log(fileList);

    },
    delPic(){
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = this.info
        data.landPic = '';
        getHousingEdit(data).then((res) => {
          if (res.code == 200) {
            this.getInfo();
          }
        });
      });
    },
    downPic(){
      console.log("ImageDownload`" +this.info.landPic +"`" );
    }

  },
  mounted() {
    if (this.$route.query.card){
      this.card=this.$route.query.card
    }
    console.log(this.$route.query.id,"Eeeee")
     this.getInfo();
    this.isAdmin = localStorage.getItem("userInfo");
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1vw;
      }
      .queryLong {
        width: 14vw;
      }
    }
  }
}
//::v-deep .el-form {
//  width: 65vw;
//  padding-left: 10vw;
//  box-sizing: border-box;
//}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.light{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;

}


.del{
  width: 6vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
  margin-left: 1vh;
}
</style>
