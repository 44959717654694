<template>
  <div style="background: #F7F7F7;width: 100%;height: 100vh;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >
      <el-form ref="form" :model="form"  >
        <div style="width: 100%;padding-bottom: 20px">基本信息</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="地理位置" label-width="100px">
              <el-input v-model="form.address" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="面积" label-width="100px">
              <el-input v-model="form.area"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="width: 100%;padding-bottom: 20px">使用情况</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="自用(写明用途)" label-width="120px">
              <el-input v-model="form.ownUse"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否出租" label-width="100px">
              <el-radio-group v-model="form.isRentOut">
                <el-radio label="1" name="type">是</el-radio>
                <el-radio label="0" name="type">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="100px">
              <el-input v-model="form.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元)" label-width="100px">
              <el-input v-model="form.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="是否被占用" label-width="100px">
          <el-radio-group v-model="form.isOccupy">
            <el-radio label="1" name="type">是</el-radio>
            <el-radio label="0" name="type">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" label-width="60px">
          <el-input v-model="form.remark" type="textarea" ></el-input>
        </el-form-item>



        <div style="width: 100%;padding-bottom: 20px">上传附件</div>
        <el-upload
            class="upload-demo"
            action="https://townassets.zhishang2017.com/api/cunzhen/accessory/uploadFile"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="uploadSuc"
            multiple
            :limit="9"
            :on-exceed="handleExceed"
            :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">文件不超过20M</div>
        </el-upload>

        <div style="width: 100%;text-align: right;">
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="onBack">取消</el-button>
        </div>
      </el-form>
    </div>

    <el-dialog
        style="top:5%"
        :visible.sync="dialogImg"

    >
      <div style="text-align: center">
        <img
            class="image_1"
            referrerpolicy="no-referrer"
            :src="showImg"
            style="width: 68%;height: 68%"
        />

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downThis">下 载</el-button>
      </div>

    </el-dialog>

  </div>

</template>
<script>
import { getJuEdit, getJuInfo, getJuAdd} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {
      },
      id:'',
      dialogImg:false,
      showImg:'',
      fileList: []
    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/ju', query: {id: this.id}});
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      let isShow = file.url.slice(-3);
      console.log(isShow,'isShow')
      if (isShow==="png"||isShow==="jpg"){
        this.showImg = file.url;
        this.dialogImg = true;
      }else{
        console.log("ImageDownload`" +file.url +"`" );
      }
    },
    downThis(){
      console.log("ImageDownload`" +this.showImg +"`" );
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      console.log(file,'file');
      console.log(fileList,'fileList');
      return this.fileList.splice(file.url);
    },
    uploadSuc(response, file, fileList){
      if (response.code===200){
        let data = {name: response.data.originalFilename,url:response.data.url}
        this.fileList.push(data)
      }
    },
    onSubmit() {
      this.form.manageId=this.id;
      let data = this.form;
      if (this.fileList){
        data.picList = this.fileList;
      }
      if (this.form.id){
        getJuEdit(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.$router.push({ path: '/ju', query: {id: this.form.manageId}});
          }
        });
      }else {
        getJuAdd(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.$router.push({ path: '/ju', query: {id: this.form.manageId}});
          }
        });
      }
    },
    initData(){
      getJuInfo( this.form.id).then((res) => {
        if (res.code===200){
          this.form=res.data;
          if (this.form.picList){
            this.fileList =this.form.picList;
            console.log(this.fileList ,'this.fileList ')
          }
        }
      });
    },
    onBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.$route.query.editId){
      this.id  = this.$route.query.id;
      this.form.id = this.$route.query.editId;
      this.initData();
    }

  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}

</style>
