<template>
  <div class="container">
    <div class="station-container">
      <div class="left-title">电量报表</div>
      <el-form :model="form">
        <el-form-item label="电站名称：">
          <el-input
            style="width: 16.5vw"
            v-model="form.psName"
            placeholder="请输入电站名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="电表名称：">
          <el-input
            style="width: 16.5vw"
            v-model="form.sn"
            placeholder="请输入sn码"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
            style="width: 11vw"
            v-model="form.reportingTime"
            :picker-options="pickerOptions1"
            type="month"
            placeholder="日期"
            @change="specifyPostingDateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn-container">
        <div class="btn" @click="query">查询</div>
        <div class="btn" @click="reset">重置</div>
        <div class="btn" @click="exportData">导出电表数据</div>
        <div class="btn" @click="exportData(1)">导出结算统计</div>
      </div>
      <div class="bottom-container">
        <table border="1">
          <tr>
            <td rowspan="2">序号</td>
            <td rowspan="2">电表名称</td>
            <td rowspan="2">电站名称</td>
            <td rowspan="2">时段</td>
            <td colspan="2">正向有功</td>
            <td colspan="2">反向有功</td>
            <td rowspan="2">电表倍率</td>
            <td rowspan="2">电表类型</td>
            <td rowspan="2">本期正向电量(kwh)</td>
            <td rowspan="2">本期反向电量(kwh)</td>
            <td rowspan="2">本期发电量(kwh)</td>
            <td rowspan="2">时间</td>
            <!-- <td rowspan="2">操作</td> -->
          </tr>
          <tr>
            <td>上期读数</td>
            <td>本期读数</td>
            <td>上期读数</td>
            <td>本期读数</td>
          </tr>
          <tbody v-for="(item, index) in tableList" :key="item.id">
            <tr>
              <td rowspan="5">{{ index + 1 }}</td>
              <td rowspan="5">
                <div style="margin-bottom: 0.8vh" v-if="item.gr != null">
                  {{ item.gr.mrMeName }}
                </div>
                <div style="margin-bottom: 0.8vh" v-if="item.gr == null"></div>
                <div v-if="item.sn != null">({{ item.sn }})</div>
                <div v-if="item.sn == null"></div>
              </td>
              <td rowspan="5">
                <div v-if="item.gr != null && item.gr.gfPowerStation != null">
                  {{ item.gr.gfPowerStation.psName }}
                </div>
                <div
                  v-if="item.gr == null || item.gr.gfPowerStation == null"
                ></div>
              </td>
              <td>总</td>
              <td>{{ item.lom[0].num }}</td>
              <td>{{ item.ml[0].num }}</td>
              <td>{{ item.lom[5].num }}</td>
              <td>{{ item.ml[5].num }}</td>
              <td rowspan="5">
                <span v-if="item.gr != null">{{
                  item.gr.mrMagnification
                }}</span>
                <span v-if="item.gr == null"></span>
              </td>
              <td rowspan="5">
                <span v-if="item.gr != null && item.gr.mrWhType == '1'"
                  >总电表</span
                >
                <span v-if="item.gr != null && item.gr.mrWhType == '2'"
                  >光伏电表</span
                >
                <span v-if="item.gr == null"></span>
              </td>
              <td>{{ item.ml[0].electric }}</td>
              <td>{{ item.ml[5].electric }}</td>
              <td>{{ item.ml[0].eec }}</td>
              <td rowspan="5" v-if="item.date != null">{{ item.date }}</td>
              <td rowspan="5" v-if="item.date == null"></td>
              <!-- <td rowspan="5">
                <div class="detail" @click="goBill(index)">查看账单详情</div>
              </td> -->
            </tr>
            <tr>
              <td>尖</td>
              <td>{{ item.lom[1].num }}</td>
              <td>{{ item.ml[1].num }}</td>
              <td>{{ item.lom[6].num }}</td>
              <td>{{ item.ml[6].num }}</td>
              <td>{{ item.ml[1].electric }}</td>
              <td>{{ item.ml[6].electric }}</td>
              <td>{{ item.ml[1].eec }}</td>
            </tr>
            <tr>
              <td>峰</td>
              <td>{{ item.lom[2].num }}</td>
              <td>{{ item.ml[2].num }}</td>
              <td>{{ item.lom[7].num }}</td>
              <td>{{ item.ml[7].num }}</td>
              <td>{{ item.ml[2].electric }}</td>
              <td>{{ item.ml[7].electric }}</td>
              <td>{{ item.ml[2].eec }}</td>
            </tr>
            <tr>
              <td>平</td>
              <td>{{ item.lom[3].num }}</td>
              <td>{{ item.ml[3].num }}</td>
              <td>{{ item.lom[8].num }}</td>
              <td>{{ item.ml[8].num }}</td>
              <td>{{ item.ml[3].electric }}</td>
              <td>{{ item.ml[8].electric }}</td>
              <td>{{ item.ml[3].eec }}</td>
            </tr>
            <tr>
              <td>谷</td>
              <td>{{ item.lom[4].num }}</td>
              <td>{{ item.ml[4].num }}</td>
              <td>{{ item.lom[9].num }}</td>
              <td>{{ item.ml[9].num }}</td>
              <td>{{ item.ml[4].electric }}</td>
              <td>{{ item.ml[9].electric }}</td>
              <td>{{ item.ml[4].eec }}</td>
            </tr>
          </tbody>
        </table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[2, 4, 6, 8]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getEleTable } from "@/api/home";
export default {
  name: "electricityReport",
  data() {
    return {
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      form: {
        psName: "",
        reportingTime: "", // 报文时间
        sn: "", // 电表名称
      },
      tableList: [],
      currentPage: 1,
      pageSize: 2,
      total: 0,
      NOW_MONTHS_AGO: "",
      year: "",
      month: "",
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() + "-" + addDateZero(d.getMonth() + 1);
      return formatdatetime;
    },
    getMonthFinalDay(year, month) {
      var day = "";
      if (year == null || year == undefined || year == "") {
        year = new Date().getFullYear();
      }
      if (month == null || month == undefined || month == "") {
        month = new Date().getMonth() + 1;
      }
      day = new Date(new Date(year, month).setDate(0)).getDate();
      return year + "-" + month + "-" + day;
    },
    specifyPostingDateChange(val) {
      this.form.reportingTime = this.formateDate(val);
      let arr = this.form.reportingTime.split("-");
      this.year = arr[0];
      this.month = arr[1];
      this.NOW_MONTHS_AGO = this.getMonthFinalDay(this.year, this.month);
      // console.log(time, "sjai");
      // console.log(val, "ashduash");
      // this.form.reportingTime = val
      // if (val) {
      //   const date = new Date(val);
      //   date.setDate(28);
      //   date.setMonth(date.getMonth() + 1);
      //   // 日期设置为0号, 0表示1号的前一天
      //   this.NOW_MONTHS_AGO = this.formateDate(date.setDate(0));
      //   console.log(this.NOW_MONTHS_AGO, "hdusahuid");
      //   if (this.NOW_MONTHS_AGO > time) {
      //     console.log(111);
      //     const date = new Date(val);
      //     date.setDate(28);
      //     date.setMonth(date.getMonth());
      //     // 日期设置为0号, 0表示1号的前一天
      //     this.NOW_MONTHS_AGO = this.formateDate(date.setDate(0));
      //   }
      // }
    },
    // 获取当前月的最后一天
    getLastDay() {
      var year = new Date().getFullYear(); //获取年份
      var month = new Date().getMonth() + 1; //获取月份
      var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
      month = month < 10 ? "0" + month : month; //月份补 0
      return [year, month, lastDate].join("-");
    },
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        meTime: this.NOW_MONTHS_AGO,
        sn: this.form.sn,
        title: this.form.psName,
      };
      getEleTable(data).then((res) => {
        this.tableList = res.rows;
        this.total = res.total;
      });
    },
    // 进入页面时获取上个月最后一天
    // getDefaultTime() {
    //   this.form.reportingTime = this.NOW_MONTHS_AGO;
    //   console.log(this.form.reportingTime, "sduashu");
    //   const date = new Date(this.form.reportingTime);
    //   date.setDate(28);
    //   date.setMonth(date.getMonth());
    //   this.NOW_MONTHS_AGO = this.formateDate(date.setDate(0));
    //   this.form.reportingTime = this.NOW_MONTHS_AGO;
    //   console.log(this.NOW_MONTHS_AGO, "手段hi");
    // },
    // 获取当前时间
    getNowDate() {
      const timeOne = new Date();
      const year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      let day = timeOne.getDate();
      month = month < 10 ? "0" + month : month;
      this.NOW_MONTHS_AGO = `${year}-${month}`;
      // return NOW_MONTHS_AGO
      console.log(this.NOW_MONTHS_AGO, "和杜萨会对撒");
    },
    // 查询
    query() {
      this.currentPage = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.form.psName = "";
      this.form.reportingTime = "";
      // this.NOW_MONTHS_AGO = "";
      // if(this.form.reportingTime == ''){
      this.getNowDate();
      this.form.reportingTime = this.getLastDay(this.NOW_MONTHS_AGO);
      //   console.log(this.form.reportingTime,'大海哦多少')
      // }
      this.form.sn = "";
      this.getNowDate();
      // this.getDefaultTime();
      this.getList();
    },
    // 跳转账单详情页
    goBill(index) {
      for (let i in this.tableList) {
        var sn = this.tableList[index].sn;
        var meTime = this.tableList[index].date;
      }
      this.$router.push({
        path: "/userBill",
        query: {
          sn: sn,
          meTime: meTime,
        },
      });
    },
    // 导出
    exportData(form) {
      let token = localStorage.getItem("token");
      let data = {
        title: this.form.psName,
        meTime: this.NOW_MONTHS_AGO,
        sn: this.form.sn,
      };

      let url = `https://admin.pvshield.cn/guang/custom/cbq/everyDayCbqDataDtoExport?title=`;
      if (form === 1) {
        url = `https://admin.pvshield.cn/guang/custom/adapter/meterReading/data/month/export?title=`;
      }
      this.$axios({
        method: "GET",
        url: url + data.title + "&sn=" + data.sn + "&meTime=" + data.meTime,
        responseType: "arraybuffer",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          let url = window.URL.createObjectURL(
            new Blob([res.data], { type: ".xls" })
          );
          let a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.setAttribute("download", "电量报表.xls");
          document.body.appendChild(a);
          a.click();
          url = window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => {
          this.$message.error("导出失败");
        });
    },
  },
  mounted() {
    this.getNowDate();
    this.form.reportingTime = this.NOW_MONTHS_AGO;
    this.NOW_MONTHS_AGO = this.getLastDay(this.NOW_MONTHS_AGO);
    // this.getDefaultTime();
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  .station-container {
    position: relative;
    .left-title {
      position: absolute;
      left: 0;
      top: 0;
      background: url(@/assets/images/box22.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-right: 7.5vw;
    }
    .el-form {
      position: absolute;
      left: 13vw;
      top: 0;
      display: flex;
    }
    .btn-container {
      position: absolute;
      // right: 19.3vw;
      margin-left: 47.5vw;
      top: 7.5vh;
      display: flex;
      .btn {
        width: 7.2vw;
        height: 4.6vh;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        margin-right: 1.5vw;
        text-align: center;
        line-height: 4.6vh;
        color: #02b5ff;
        font-size: calc(100vw * 18 / 1920);
        cursor: pointer;
      }
    }
    .bottom-container {
      width: 75.6vw;
      margin: 0 auto;
      position: absolute;
      top: 14vh;
      left: 0;
      right: 0;
      table {
        width: 100%;
        box-shadow: inset 0rem 0rem 0.33rem 0.35rem rgba(15, 53, 116, 0.6);
        border: 1px solid #286ae8;
        margin-bottom: 1.5vh;
        color: #fff;
        font-size: calc(100vw * 16 / 1920);
        border-collapse: collapse;
        text-align: center;
        tr {
          height: 5vh;
          .detail {
            font-size: calc(100vw * 14 / 1920);
            text-decoration: underline;
            color: #286ae8;
            cursor: pointer;
          }
        }
      }
      .el-pagination {
        display: flex;
        justify-content: flex-end;
        .el-select,
        .btn-prev,
        .btn-next {
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 0.1rem;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
        }
        .el-pager li {
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 0.1rem;
          border: 0.02rem solid #286ae8;
          background: none;
          .active {
            color: pink !important;
          }
        }
        .el-pager li.active {
          color: #0855b3;
        }
        .number {
          color: #fff;
          margin-right: 0.8vw;
          .active {
            color: pink;
          }
        }
        .el-input {
          width: 7vw;
        }
        .more,
        .btn-prev {
          color: #fff;
          margin-right: 0.8vw;
        }
        .el-input .el-input--mini .el-input--suffix {
          background: none;
        }
        .el-input__inner {
          background: none;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  margin-right: 2vw;
  .el-form-item__label {
    width: 6vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-pager li.active {
  color: #409eff;
}
</style>
