<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">设备类型</div>
    </div>
    <div class="right-part">
      <div class="add-btn" @click="addContent">添加</div>
      <el-table
        :data="typeList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="typeName" label="设备名称"></el-table-column>
        <!-- <el-table-column prop="sn" label="SN码"></el-table-column> -->
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button size="mini" type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
    <div style="flex: 20%"></div>
  </div>
</template>

<script>
import { getEquipmentList, deleteEquipment } from "@/api/equipment";
export default {
  name: "equipmentType",
  data() {
    return {
      typeList: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList()
    },
    // 获取设备列表
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      };
      getEquipmentList(data).then((res) => {
        console.log(res, "www");
        this.typeList = res.rows;
        this.total = res.total;
      });
    },
    // 跳转至新增页面
    addContent() {
      this.$router.push("/addEquipment");
    },
    handleEdit(row) {
      this.$router.push({
        path: "/addEquipment",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteEquipment(row.id).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    flex: 40%;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .right-part {
    flex: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .add-btn {
      width: 7.2vw;
      height: 4.6vh;
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      color: #02b5ff;
      text-align: center;
      font-size: calc(100vw * 18 / 1920);
      line-height: 4.6vh;
      margin-bottom: 4.6vh;
      cursor: pointer;
    }
  }
}
::v-deep .el-table {
  width: 99.8%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
.el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
