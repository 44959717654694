<template>
  <div >
    <div style="padding-bottom: 3vh">
          <div style="display: flex; height: 4.6vh;padding-left: 3vw">
            <div class="query"  style="width: 16vw;" @click="goZhenProperty">镇保土地资源基本信息表(生产组明细)</div>
<!--            <div class="query" >保存</div>-->
        </div>
    </div >

    <el-form ref="form" :model="form"   >

      <el-row >
        <el-col :span="4">
          <el-form-item label="村委会名称" label-width="120px" style="color: #00c0ff">
            <el-input v-model="form.manageName" disabled ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="镇保土地总面积" label-width="120px">
            <el-input v-model="form.totalArea" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

        <div class="rowTitle">{{ form.agriculture.manageUnit }}</div>
        <el-row >
          <el-col :span="4">
            <el-form-item label="总管理面积(亩)" label-width="120px" style="color: #00c0ff">
              <el-input v-model="form.agriculture.totalArea" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="自用土地(亩)" label-width="120px">
              <el-input v-model="form.agriculture.selfUseArea" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="出租面积(亩)" label-width="120px">
              <el-input v-model="form.agriculture.rentOutArea" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="闲置面积(亩)" label-width="120px">
              <el-input v-model="form.agriculture.inIdleArea" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="年租金(亩)" label-width="120px">
              <el-input v-model="form.agriculture.yearRent" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      <div class="rowTitle">{{ form.city.manageUnit }}</div>
      <el-row >
        <el-col :span="4">
          <el-form-item label="总管理面积(亩)" label-width="120px" style="color: #00c0ff">
            <el-input v-model="form.city.totalArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="自用土地(亩)" label-width="120px">
            <el-input v-model="form.city.selfUseArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="出租面积(亩)" label-width="120px">
            <el-input v-model="form.city.rentOutArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="闲置面积(亩)" label-width="120px">
            <el-input v-model="form.city.inIdleArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年租金(亩)" label-width="120px">
            <el-input v-model="form.city.yearRent" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="rowTitle">{{ form.sanzaoPark.manageUnit }}</div>
      <el-row >
        <el-col :span="4">
          <el-form-item label="总管理面积(亩)" label-width="120px" style="color: #00c0ff">
            <el-input v-model="form.sanzaoPark.totalArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="自用土地(亩)" label-width="120px">
            <el-input v-model="form.sanzaoPark.selfUseArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="出租面积(亩)" label-width="120px">
            <el-input v-model="form.sanzaoPark.rentOutArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="闲置面积(亩)" label-width="120px">
            <el-input v-model="form.sanzaoPark.inIdleArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年租金(亩)" label-width="120px">
            <el-input v-model="form.sanzaoPark.yearRent" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="rowTitle">{{ form.south.manageUnit }}</div>
      <el-row >
        <el-col :span="4">
          <el-form-item label="总管理面积(亩)" label-width="120px" style="color: #00c0ff">
            <el-input v-model="form.south.totalArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="自用土地(亩)" label-width="120px">
            <el-input v-model="form.south.selfUseArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="出租面积(亩)" label-width="120px">
            <el-input v-model="form.south.rentOutArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="闲置面积(亩)" label-width="120px">
            <el-input v-model="form.south.inIdleArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年租金(亩)" label-width="120px">
            <el-input v-model="form.south.yearRent" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="rowTitle">{{ form.burg.manageUnit }}</div>
      <el-row >
        <el-col :span="4">
          <el-form-item label="总管理面积(亩)" label-width="120px" style="color: #00c0ff">
            <el-input v-model="form.burg.totalArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="自用土地(亩)" label-width="120px">
            <el-input v-model="form.burg.selfUseArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="出租面积(亩)" label-width="120px">
            <el-input v-model="form.burg.rentOutArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="闲置面积(亩)" label-width="120px">
            <el-input v-model="form.burg.inIdleArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年租金(亩)" label-width="120px">
            <el-input v-model="form.burg.yearRent" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="rowTitle">{{ form.other.manageUnit }}</div>
      <el-row >
        <el-col :span="4">
          <el-form-item label="总管理面积(亩)" label-width="120px" style="color: #00c0ff">
            <el-input v-model="form.other.totalArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="自用土地(亩)" label-width="120px">
            <el-input v-model="form.other.selfUseArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="出租面积(亩)" label-width="120px">
            <el-input v-model="form.other.rentOutArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="闲置面积(亩)" label-width="120px">
            <el-input v-model="form.other.inIdleArea" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="年租金(亩)" label-width="120px">
            <el-input v-model="form.other.yearRent" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>


    </el-form>

    
  </div>
</template>

<script>
import {getLandRentTotal, getLandTotal, getZhen} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  props:{
    rowId:String
  },
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      totalList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
      btn:0
    };
  },

  methods: {
    goIndex(){
      this.$router.push("/index");
    },
    goZhenProperty(){
      this.$router.push({ path: '/landZhenProperty', query: {id: this.rowId}});
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    chooseBtn(e){
      this.btn = e;
    },
    getList() {
      let data = {
        manageId: this.rowId,
      };
      getZhen(data).then((res) => {
        this.form = res.data;
        this.total = res.total;
      });
    },


  },
  mounted() {
     this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .item.el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #f0c78a !important;
          font-size: calc(100vw * 16 / 1920);

        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 85vw;
  padding-left: 3vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.lightBtn{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;
  width: 12vw;
  height: 100%;
  background-size: 100% 100%;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;

}
.btn{
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  width: 12vw;
  height: 100%;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;

}



::v-deep .el-form-item__label {
    color: #00c0ff;

  }

.query,
.reset {
  width: 10vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
}
.query {
  margin-right: 3vw;
}
.rowTitle{
  color: #00c0ff;
  font-size: calc(100vw * 16 / 1920);
  padding-bottom: 1vh;
  font-weight: 600;
  padding-left: 0vw;
}

::v-deep .el-input.is-disabled .el-input__inner {
  color: rebeccapurple;
}
</style>
