<template>
  <div class="container">
    <div class="form">
      <el-form :model="form" ref="form" :rules="rules">
        <div style="display: flex">
          <div style="margin-right: 5vw">
            <el-form-item label="姓名：" prop="nickName">
              <el-input
                v-model="form.nickName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="userName">
              <el-input
                v-model="form.userName"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
              <el-select v-model="form.sex" placeholder="请选择性别">
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="密码："
              prop="password"
              v-if="$route.query.id == undefined"
            >
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业名称：" prop="etId">
              <el-select
                @change="towerChange"
                v-model="form.etId"
                placeholder="请选择企业"
              >
                <el-option
                  v-for="(item, index) in companyList"
                  :label="item.etName"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="到期时间：" prop="enTime">
              <el-date-picker
                v-model="form.enTime"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="年/月/日"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="角色：" prop="roleIds">
              <el-select
                v-model="form.roleIds"
                placeholder="请选择角色"
                @change="changeRole"
              >
                <el-option
                  :label="item.roleName"
                  :value="item.roleId"
                  v-for="(item, index) in roleList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="备注："
              style="display: flex; align-items: flex-start; height: 13.8vh"
            >
              <textarea v-model="form.remark"></textarea>
            </el-form-item>
            <el-form-item label="电站">
              <el-select
                v-model="form.station"
                multiple
                placeholder="请选择电站"
                collapse-tags
              >
                <el-option
                  v-for="item in stationList"
                  :key="item.id"
                  :label="item.psName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分公司权限">
              <el-switch v-model="form.branchSta"> </el-switch>
            </el-form-item>
          </div>
          <div v-if="$route.query.manager">
            <el-form-item label="logo状态：">
              <el-switch v-model="form.logoSta" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="logo名称：" v-if="form.logoSta == '1'">
              <el-input v-model="form.logoName"></el-input>
            </el-form-item>
            <el-form-item
              v-if="form.logoSta == '1'"
              label="logo名称："
              style="display: flex; align-items: flex-start"
            >
              <el-upload
                class="avatar-uploader"
                :headers="header"
                action="https://admin.pvshield.cn/guang/common/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="form.logoVar" :src="form.logoVar" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div
      class="submit-btn"
      @click="addUser('form')"
      v-if="$route.query.id == undefined"
    >
      添加
    </div>
    <div
      class="submit-btn"
      v-if="$route.query.id != undefined"
      @click="modify('form')"
    >
      修改
    </div>
  </div>
</template>

<script>
import {
  getCompanyInfo,
  addUser,
  getUserRoleList,
  modifyCompanyInfo,
  getCompanyDetail,
  getDefaultCompany,
  getDefaultCompanyNotM,
  customStationGET,
} from "@/api/jurisdiction";
import index from "./index.vue";
export default {
  components: { index },
  name: "addAuthorization",
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      //显示上传按钮
      form: {
        nickName: "", // 姓名
        userName: "", // 手机号
        enTime: "", // 到期时间
        roleIds: "", // 角色权限
        sex: "", // 性别
        remark: "", // 备注
        etId: "", // 企业ID
        logoSta: '', // logo状态
        logoName: "", // logo名
        logoVar: "", // logo
        station: [], //电站
        branchSta: false, //分公司权限
      },
      stationList: [], //电站列表
      companyList: [], // 企业
      roleList: [], // 角色
      rules: {
        nickName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        userName: [
          {
            required: true,
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        sex: [{ required: true, message: "性别不能为空", trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        etId: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        enTime: [
          { required: true, message: "到期时间不能为空", trigger: "blur" },
        ],
        roleIds: [{ required: true, message: "角色不能为空", trigger: "blur" }],
      },
      userId: "",
    };
  },
  methods: {
    changeRole(e) {
      //获取的值和id
      console.log(e, "sdhisahjoi");
      let obj = {};
      console.log(this.roleList, "hdusahu");
      obj = this.roleList.find((item) => {
        return item.roleId === e;
      });
      console.log(obj, "zxc");
      this.form.roleIds = obj.roleId;
      //   this.form.etOmId = obj.id
    },
    towerChange(e) {
      //获取的值和id
      console.log(e, "============towerChange");
      let self = this;
      self.$set(self.form, "station", []);
      let obj = {};
      obj = self.companyList.find((item) => {
        return item.id === e;
      });
      self.$set(self.form, "etName", obj.etName);
      self.customStationGETAxios(e);
    },
    async customStationGETAxios(id, PowerStationIds) {
      console.log(id, PowerStationIds, "==========customStationGETAxios");
      let self = this;
      const data_back = await customStationGET({ id: id });
      console.log(data_back, "===============customStationGET");
      const { data, code } = data_back;
      if (code === 200) {
        self.stationList = data;
        if (data && data.length && PowerStationIds && PowerStationIds.length) {
          self.$set(self.form, "station", PowerStationIds);
        }
      }
    },
    getCompany() {
      getCompanyInfo().then((res) => {
        console.log(res);
        this.companyList = res.data;
      });
    },
    //上传logo
    handleAvatarSuccess(file) {
      console.log(file, 111);
      this.form.logoVar = file.url;
      console.log(this.form.logo);
    },
    // 新增
    addUser(form) {
      let self = this;
      this.$refs[form].validate((valid) => {
        if (valid) {
          // console.log(this.form.roleIds * 1)
          let arr = [];
          arr.push(this.form.roleIds);
          this.form.roleIds = arr;
          this.form.enTime = this.$formateDate(this.form.enTime);
          this.form.postIds = [];
          if (!this.$route.query.manager) {
            getDefaultCompanyNotM().then((res) => {
              this.form.logoName = res.date.logoName;
              this.form.logoVar = res.date.logo;
            });
          }
          let params = this.form;
          params.branchSta = this.form.branchSta ? 1 : 0;
          params.psIds = this.form.station;
          self.$delete(params, "station");
          console.log(params, "新增");
          // return;
          addUser(params).then((res) => {
            this.form.roleIds = this.form.roleIds[0];
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/userJurisdiction");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取角色下拉列表
    getUserRole() {
      getUserRoleList().then((res) => {
        console.log(res, "五九七五年间");
        this.roleList = res.roles;
      });
    },
    // 获取用户详情
    getDetail() {
      let self = this;
      getCompanyDetail(this.userId).then((res) => {
        console.log(res);
        // this.form = res.data
        this.form.nickName = res.data.nickName;
        this.form.userName = res.data.userName;
        this.form.sex = res.data.sex;
        this.form.etId = res.data.etId;
        this.form.remark = res.data.remark;
        this.form.roleIds = res.roleIds[0];
        this.form.enTime = res.data.enTime;
        this.form.logoName = res.data.logoName;
        this.form.logoVar = res.data.logoVar;
        this.form.logoSta = res.data.logoSta
        self.$set(self.form,'branchSta',res.data.branchSta == 1 ? true : false)
        this.customStationGETAxios(res.data.etId, res.PowerStationIds);
      });
    },
    // 修改
    modify(form) {
      let self = this;
      this.$refs[form].validate((valid) => {
        if (valid) {
          let arr = [];
          arr.push(this.form.roleIds);
          this.form.roleIds = arr;
          this.form.enTime = this.$formateDate(this.form.enTime);
          this.form.userId = this.userId;
          if (!this.$route.query.manager) {
            getDefaultCompanyNotM().then((res) => {
              this.form.logoName = res.date.logoName;
              this.form.logoVar = res.date.logo;
            });
          }
          let params = this.form;
          params.branchSta = this.form.branchSta ? 1 : 0;
          params.psIds = this.form.station;
          self.$delete(params, "station");
          console.log(params, "修改");
          modifyCompanyInfo(params).then((res) => {
            this.form.roleIds = this.form.roleIds[0];
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/userJurisdiction");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取默认企业
    getDefault() {
      getDefaultCompany().then((res) => {
        console.log(res, "时代ihu");
        this.form.logoName = res.date.logoName;
        this.form.logoVar = res.date.logo;
        console.log(this.form.logoName, "是你大号的");
      });
    },
    // 获取非超管时
    getDefaultNot() {
      getDefaultCompanyNotM().then((res) => {
        this.form.logoName = res.date.logoName;
        this.form.logoVar = res.date.logo;
      });
    },
  },
  mounted() {
    this.getCompany();
    this.getUserRole();
    if (this.$route.query.id != undefined) {
      this.userId = this.$route.query.id;
      this.getDetail();
      this.getDefault();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  width: 100%;
  position: relative;
  .form {
    position: absolute;
    top: 3.7vh;
    left: 18.75vw;
    .el-form-item {
      display: flex;
      height: 4.6vh;
      align-items: center;
      margin-bottom: 3vh;
      .el-form-item__label {
        width: 7vw;
        text-align: left;
        color: #fff;
        font-size: calc(100vw * 16 / 1920);
      }
    }
    .el-input__inner {
      width: 20vw;
      height: 4.6vh;
      background: #0f2455;
      border: 1px solid #286ae8;
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
      &::placeholder {
        color: #fff;
      }
    }
  }
  .submit-btn {
    position: absolute;
    top: 72vh;
    right: 18.75vw;
    color: #fff;
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    cursor: pointer;
  }
  .logo_box {
    width: 7.5vw;
    height: 13.8vh;
    background: #fff;
  }
}
.el-tree::v-deep {
  width: 100%;
  background: none;
  .el-tree-node__label {
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-tree-node is-expanded is-focusable {
    background: none;
  }
}
::v-deep .el-tree-node:focus > .el-tree-node__content,
::v-deep .el-tree-node:hover > .el-tree-node__content {
  background-color: transparent !important;
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
textarea {
  width: 20vw;
  resize: none;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  border-radius: 6px;
  height: 13.8vh;
  padding: 0.7vw;
  box-sizing: border-box;
  outline: none;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  // border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 7.5vw;
  height: 13.8vh;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  line-height: 13.8vh;
  text-align: center;
}
::v-deep .avatar {
  width: 7.5vw;
  height: 13.8vh;
}
</style>
