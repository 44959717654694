<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">电价设置</div>
    </div>
    <div class="right-part">
      <div class="right-top">
        <el-form :model="form">
          <div style="display: flex; justify-content: space-between">
            <el-form-item label="全局搜索：">
              <el-input
                v-model="form.searchContent"
                placeholder="请输入企业名称或电站名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <div style="display: flex">
              <div class="add-btn" @click="addNew">添加</div>
              <div class="add-btn" @click="query">查询</div>
              <div class="add-btn" @click="reset">重置</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="priceList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column
          prop="gfEnterprise.etName"
          label="企业名称"
        ></el-table-column>
        <el-table-column
          prop="gfPowerStation.psName"
          label="电站名称"
        ></el-table-column>
        <el-table-column prop="epDiscount" label="电价折扣"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getElePrice, deletePrice } from "@/api/equipment";
export default {
  name: "priceSetting",
  data() {
    return {
      form: {
        searchContent: "", // 搜索内容
      },
      priceList: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 14 / 1920);";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    // 获取列表
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        title: this.form.searchContent
      };
      getElePrice(data).then((res) => {
        this.priceList = res.rows;
        this.total = res.total;
      });
    },
    // 新增
    addNew() {
      this.$router.push("/addPrice");
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    // 重置
    reset() {
      this.form.searchContent = "";
      this.getList()
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        path: "/addPrice",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePrice(row.id).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .left-part {
    position: absolute;
    left: 0;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .right-part {
    width: 62.5vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    .right-top {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 1vh;
      .add-btn {
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        width: 7.2vw;
        height: 4.6vh;
        font-size: calc(100vw * 16 / 1920);
        margin-left: 2vw;
        color: #02b5ff;
        text-align: center;
        line-height: 4.6vh;
        cursor: pointer;
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 6vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  height: 4.6vh;
  font-size: calc(100vw * 14 / 1920) !important;
  margin-left: 1vw;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
