<template>
  <div class="container">
    <table border="1" v-for="(item, index) in list" :key="index">
      <tr>
        <td></td>
        <td>{{item.itName}}</td>
        <td>运行状态</td>
        <td>
            <div  v-if="item.runState == 'WaitMode'">待机</div>
            <div  v-if="item.runState == 'cNormalMode'">正常</div>
            <div  v-if="item.runState == 'FaultMode'">故障</div>
        </td>
        <!-- <td v-if="index == 1 || index == 4"><div class="red"></div></td> -->
      </tr>
      <tr>
        <td>温度</td>
        <td>{{item.temp}}</td>
        <td>输出功率</td>
        <td>{{item.allP}}</td>
      </tr>
      <tr>
        <td>总发电量</td>
        <td>{{item.allPower}}</td>
        <td>频率</td>
        <td>{{item.rf}},{{item.sf}},{{item.tf}}</td>
      </tr>
      <tr>
        <td>PV电压</td>
        <td>{{item.pvU}}</td>
        <td>PV电流</td>
        <td>{{item.pvL}}</td>
      </tr>
      <tr>
        <td>电压平均值</td>
        <td>{{item.pvuAvg}}</td>
        <td>电流平均值</td>
        <td>{{item.pvlAvg}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getDevice } from "@/api/green";
export default {
  name: "equipmentMonitoring",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getList() {
      getDevice(this.$route.query.psId).then((res) => {
        this.list = res.rows;
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.green,
.red {
  width: 1.1vw;
  height: 2vh;
  border-radius: 10vw;
  margin: 0 auto;
}
.green {
  background: #6dd400;
}
.red {
  background: #e02020;
}
.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2vh 4.6vw;
  box-sizing: border-box;
  table {
    width: 32%;
    border-collapse: collapse;
    height: 23.1vh;
    box-shadow: inset 0rem 0rem 0.33rem 0.35rem rgba(15, 53, 116, 0.6);
    border: 0.02rem solid #286ae8;
    margin-bottom: 4.6vh;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-collapse: collapse;
    text-align: center;
  }
}
</style>
