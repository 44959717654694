<template>
  <div style="background: #F7F7F7;width: 100%;height: 100vh;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >
      <div style="width: 100%;padding-bottom: 20px">基本信息</div>
      <el-form ref="form" :model="form"  >
        <el-row >
          <el-col :span="6">
            <el-form-item label="小区名称" label-width="80px">
              <el-input v-model="form.plotName" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="地理位置" label-width="120px">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="存量安置房屋数量(套)" label-width="180px">
              <el-input v-model="form.houseNum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="存量安置房屋面积(m²)" label-width="160px">
              <el-input v-model="form.totalUnitArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="分布幢、楼号" label-width="120px">
              <el-input v-model="form.distributionNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" label-width="60px">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div style="width: 100%;text-align: right;">
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="onBack">取消</el-button>
      </div>
    </div>
  </div>

</template>
<script>
import {getHosingCunEdit, getHosingCunAdd, getHosingCunInfo} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {
      },
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/housing', query: {id: this.form.manageId,card: 'Cun'}});
    },
    onSubmit() {
      let data = this.form;
      if (this.form.id){
        getHosingCunEdit(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.$router.push({ path: '/housing', query: { card: 'Cun',id: this.form.manageId}});
          }
        });
      }else {
        getHosingCunAdd(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.$router.push({ path: '/housing', query: { card: 'Cun',id: this.form.manageId}});
          }
        });
      }

    },
    initData(){
      getHosingCunInfo( this.form.id).then((res) => {
        if (res.code===200){
         this.form=res.data;
        }
      });
    },
    onBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    this.form.manageId = this.$route.query.id;
    if (this.$route.query.editId){
      this.form.id = this.$route.query.editId;
      this.initData();
    }

  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}

</style>