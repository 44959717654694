<template>
  <div>
    <div style="color: rgba(2, 181, 255, 1);font-size: calc(100vw * 16 / 1920);line-height:4.6vh;text-align: center;padding-bottom: 5vh;padding-top: 2vh;width: 60%;margin-left: 20%">
      <div style="display: flex; height: 4.6vh;justify-content: center;">
        <div class="btn" >区域内控制建设用地统计表</div>
      </div>
    </div>
    <div class="query light" style="width: 70px;text-align: center;" @click="exportList()">导出</div>
      <el-table
          :data="totalList"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
          @row-click="handleRowClick"
      >
        <el-table-column prop="manageName" label="居委名称" ></el-table-column>
        <el-table-column prop="vacancyNumber" label="处数" ></el-table-column>
        <el-table-column prop="area" label="面积"></el-table-column>
        <el-table-column prop="vacancyNumber" label=空置处数></el-table-column>
        <el-table-column prop="vacancyArea" label=空置面积></el-table-column>
<!--        <el-table-column prop="isOwnUse" label=是否自用>-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.isOwnUse===false">否</span>-->
<!--            <span v-if="scope.row.isOwnUse===true">是</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="isRentOut" label=是否出租>-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.isOwnUse===false">否</span>-->
<!--            <span v-if="scope.row.isOwnUse===true">是</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="isOccupy" label="是否被占用">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-if="scope.row.isOwnUse===false">否</span>-->
<!--            <span v-if="scope.row.isOwnUse===true">是</span>-->
<!--          </template>-->
<!--        </el-table-column>-->

      </el-table>


    </div>


</template>

<script>
import {juExport, getHousingCun, getLandJu, getLandTotal, landExport, landExportRent} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  props:{
    rowId:String
  },
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      totalList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
    };
  },

  methods: {
    goIndex(){
      this.$router.push("/index");
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    exportList(){

      juExport() .then((res) => {
          console.log("ImageDownload`" +res.msg +"`" );
        });

    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    handleRowClick(row, column, event) {
      console.log(row); // 被点击的行数据对象，包含了行中的所有数据。可以在这里进行你需要的操作。例如：打印行数据。
      console.log(column); // 被点击的列数据对象，包含了列的属性信息。可以在这里进行你需要的操作。例如：打印列属性。
      console.log(event); // 原生的事件对象，包含了事件的一些信息。可以在这里进行你需要的操作。例如：阻止事件的默认行为。
      if(row.manageName==='总计'){
        return;
      }
      this.$router.push({ path: '/ju', query: { id: row.manageId }});
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageType: 3,
      };
      getLandJu(data).then((res) => {
        this.totalList = res.data;
        this.total = res.total;
      });
    },

  },
  mounted() {
     this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
.btn{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;
  width: 12vw;
  height: 100%;
  background-size: 100% 100%;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;

}

.light{
  background: url(@/assets/use/btn3.png) !important;
  background-size: 100% 100% !important;
  color: #2EE6FE !important;
  margin-bottom: 20px;
}
</style>
