<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">电站信息</div>
    </div>
    <div style="width: 76vw; height: 100%">
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form-item">
          <el-form-item label="电站名称：" prop="psName">
            <el-input v-model="form.psName" style="width: 20vw" placeholder="请输入电站名称"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="装机容量(MWp)：" prop="psCapacity">
            <el-input type="number" v-model="form.psCapacity" style="width: 20vw" placeholder="请输入装机容量"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="业主姓名：" prop="ownerName">
            <el-input v-model="form.ownerName" style="width: 20vw" placeholder="请输入业主姓名"></el-input>
          </el-form-item>
          <div class="tip" style="margin-right: 6.1vw"></div>
          <el-form-item label="业主手机号：" prop="ownerPhone">
            <el-input v-model="form.ownerPhone" style="width: 20vw" placeholder="请输入业主手机号"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="区域：" prop="psProvince">
            <v-distpicker
              @province="provinceChange"
              @city="cityChange"
              @area="areaChange"
              :province="form.psProvince"
              :city="form.psCity"
              :area="form.psArea"
            ></v-distpicker>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="详细地址：" prop="psAddress">
            <el-input v-model="form.psAddress" style="width: 20vw" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="汇流箱(台)：" prop="psCb">
            <el-input type="number" v-model="form.psCb" style="width: 20vw" placeholder="请输入汇流箱"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="排放量：">
            <el-input v-model="form.psDc" style="width: 20vw" placeholder="请输入排放量"></el-input>
          </el-form-item>
          <div class="tip" style="margin-right: 6.1vw"></div>
          <el-form-item label="并网日期：" prop="psGc">
            <el-date-picker
              style="width: 20vw"
              v-model="form.psGc"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="年/月/日"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="倾斜角度：">
            <el-input v-model="form.psTa" style="width: 20vw" placeholder="请输入倾斜角度"></el-input>
          </el-form-item>
          <div class="tip" style="margin-right: 6.1vw"></div>
          <el-form-item label="指标值：">
            <el-input v-model="form.psIq" style="width: 20vw" placeholder="请输入指标值"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="电站类型" prop="psType" > 
          <el-select  v-model="form.psType" placeholder="请选择电站类型" style="width:20vw;">
            <el-option
              label="光伏站"
              value="0"
            >
            </el-option>
            <el-option
              label="储能站"
              value="1"
            >
            </el-option>
          </el-select>
        </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="逆变器(台)：" prop="psInverterCount">
            <el-input
            type="number"
              v-model="form.psInverterCount"
              style="width: 20vw"
              placeholder="请输入逆变器"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="合同起始日期：" prop="psBetime">
            <el-date-picker
              style="width: 20vw"
              v-model="form.psBetime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="年/月/日"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="合同到期日期：" prop="psEntime">
            <el-date-picker
              style="width: 20vw"
              v-model="form.psEntime"
              type="datetime"
              format="yyyy-MM-dd  HH:mm:ss"
              placeholder="年/月/日"
            >
            </el-date-picker>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="维修公司：" prop="psOmId">
            <el-select
            placeholder="请选择维修公司"
              @change="towerChange"
              v-model="form.psOmId"
              style="width: 20vw"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in server"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item" style="height: 13.8vh">
          <el-form-item
            label="备注："
            style="display: flex; align-items: flex-start"
          >
            <textarea v-model="form.remarks" placeholder="请输入备注"></textarea>
          </el-form-item>
          <div
            class="submit-btn"
            v-if="$route.query.content == undefined"
            @click="addStation('form')"
          >
            添加
          </div>
          <div
            class="submit-btn"
            v-if="$route.query.content != undefined"
            @click="modifyInfo('form')"
          >
            修改
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import { addStationInfo, modifyStationInfo,getRepairCompanyList } from "@/api/index";
export default {
  name: "addStationInfo",
  components: {
    VDistpicker,
  },
  data() {
    return {
      form: {
        psName: "", // 电站名称
        psCapacity: 0, // 装机容量
        ownerName:"",   // 业主姓名
        ownerPhone:"",   // 业主手机号
        psProvince: "", // 省
        psCity: "", // 市
        psArea: "", // 区
        psAddress: "", // 地址
        psCb: 0, // 汇流箱
        psDc: "", // 排放量
        psGc: "", // 并网日期
        psTa: "", // 倾斜角度
        psIq: "", // 指标值
        psInverterCount: 0, // 逆变器
        psBetime: "", // 合同起始日期
        psEntime: "", // 合同到期日期
        remarks: "", // 备注
        psType:"",
        psOmName: "", // 服务商
        psOmId: "", // 服务商id

      },
      server: [], // 服务商
      companyId: "",
      rules: {
        psName: [
          { required: true, message: "电站名称不能为空", trigger: "blur" },
        ],
        psCapacity: [
          { required: true, message: "装机容量不能为空", trigger: "blur" },
        ],
        ownerName:[
          { required: true, message: "业主姓名不能为空", trigger: "blur" },
        ],
        ownerPhone:[
          { required: true, message: "业主手机号不能为空", trigger: "blur" },
        ],
        psAddress: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        psCb: [{ required: true, message: "汇流箱不能为空", trigger: "blur" }],
        psGc: [
          { required: true, message: "并网日期不能为空", trigger: "blur" },
        ],
        psInverterCount: [
          { required: true, message: "逆变器不能为空", trigger: "blur"},
        ],
        psBetime: [
          { required: true, message: "合同起始日期不能为空", trigger: "blur" },
        ],
        psEntime: [
          { required: true, message: "合同到期日期不能为空", trigger: "blur" },
        ],
        psType:[
            { required: true, message: "电站类型不能为空", trigger: "blur" },
        ],
        psOmId: [
          { required: true, message: "维修公司不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 服务商列表
    getServer() {
      getRepairCompanyList().then((res) => {
        console.log(res, "asdz");
        this.server = res.data;
      });
    },
    towerChange(e) {
      //获取的值和id
      console.log(e);
      let obj = {};
      obj = this.server.find((item) => {
        return item.id === e;
      });
      console.log(obj, "zxc");
      this.form. psOmName = obj.name;
      //   this.form.etOmId = obj.id
    },
    provinceChange(data) {
      //省
      console.log(data);
      this.form.psProvince = data.value;
      this.form.psCity = '';
      this.form.psArea = '';
    },
    cityChange(data) {
      //市
      this.form.psCity = data.value;
      this.form.psArea = '';
    },
    areaChange(data) {
      //区、县
      this.form.psArea = data.value;
    },
    // 新增
    addStation(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (
            this.companyId == "" ||
            this.companyId == undefined ||
            this.companyId == null
          ) {
            this.$message({
              showClose: false,
              message: "参数异常",
              type: "error",
            });
            return;
          }
          this.form.psBetime = this.$formateDate(this.form.psBetime);
          this.form.psEntime = this.$formateDate(this.form.psEntime);
          this.form.psGc = this.$formateDate(this.form.psGc);
          this.form.etId = this.companyId;
          addStationInfo(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push({
                path: "/stationInfo",
                query: {
                  id: this.companyId,
                },
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改电站信息
    modifyInfo(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.psBetime = this.$formateDate(this.form.psBetime);
          this.form.psEntime = this.$formateDate(this.form.psEntime);
          console.log(this.form.psGc);
          this.form.psGc = this.$formateDate(this.form.psGc);
          modifyStationInfo(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push({
                path: "/stationInfo",
                query: {
                  id: this.companyId,
                },
              });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    console.log(this.$route.query);
    this.getServer();
    this.companyId = this.$route.query.id;
    if (this.$route.query.content != undefined) {
      this.form = JSON.parse(this.$route.query.content);
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    margin-right: 1vw;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .form-item {
    display: flex;
    height: 4.6vh;
    margin-bottom: 2.7vh;
    .tip {
      // width: vw;
      line-height: 4.6vh;
      margin-left: 0.5vw;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      margin-right: 3vw;
    }
  }
  .submit-btn {
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    margin-top: 8.9vh;
    margin-left: 29.3vw;
    cursor: pointer;
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 9.5vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
}
::v-deep .el-input__inner {
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
textarea {
  width: 20vw;
  resize: none;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  border-radius: 6px;
  height: 13.8vh;
  padding: 0.7vw;
  box-sizing: border-box;
  outline: none;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
</style>
