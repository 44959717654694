<template>
  <div style="background: #F7F7F7;width: 100%;height: 100vh;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >

      <el-form ref="form" :model="form"  >
        <div style="width: 100%;padding-bottom: 20px">基本信息</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="编号" label-width="100px">
              <el-input v-model="form.landCode" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="土地类别" label-width="100px">
              <div style="display: flex">
                <el-select v-model="form.landType" @change="typeChange" placeholder="土地类型" >
                  <el-option label="建设用地" value="1"></el-option>
                  <el-option label="非建设用地" value="2"></el-option>
                </el-select>
                <el-select v-model="form.landSubType" placeholder="土地子类型">
                  <el-option
                      v-for="(item, index) in subTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="土地位置" label-width="100px">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="土地面积(亩)" label-width="100px">
              <el-input v-model="form.area"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="自用面积(亩)" label-width="100px">
              <el-input v-model="form.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积(亩)" label-width="100px">
              <el-input v-model="form.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租单价(元/亩/年)" label-width="150px">
              <el-input v-model="form.rentUnitPrice"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="年租金(万元)" label-width="100px">
              <el-input v-model="form.yearRent"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.inIdleArea"></el-input>
            </el-form-item>
          </el-col>


        </el-row>
        <el-form-item label="备注" label-width="100px">
          <el-input v-model="form.remark"  type="textarea" ></el-input>
        </el-form-item>

        <div style="width: 100%;padding-bottom: 20px">上传附件</div>
        <el-upload
            class="upload-demo"
            action="https://townassets.zhishang2017.com/api/cunzhen/accessory/uploadFile"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="uploadSuc"
            multiple
            :limit="9"
            :on-exceed="handleExceed"
            :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">文件不超过20M</div>
        </el-upload>

        <div style="width: 100%;text-align: right;">
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="onBack">取消</el-button>
        </div>
      </el-form>
    </div>

    <el-dialog
        style="top:5%"
        :visible.sync="dialogImg"

    >
      <div style="text-align: center">
        <img
            class="image_1"
            referrerpolicy="no-referrer"
            :src="showImg"
            style="width: 68%;height: 68%"
        />

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downThis">下 载</el-button>
      </div>

    </el-dialog>

  </div>

</template>
<script>
import { getComTuInfo, getComTuAdd, getComTuEdit} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {},
      id:'',
      fileList: [],
      dialogImg:false,
      showImg:'',
      subTypeList:[]
    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/company', query: {id: this.id,card: 'companyInfo'}});
    },
    handleRemove(file, fileList) {
      console.log(file, fileList,'handleRemove');
      console.log(this.fileList,'handleRemove');
      this.fileList = this.fileList.filter(item => item !== file);
      // this.fileList.splice(file);
    },
    handlePreview(file) {
      let isShow = file.url.slice(-3);
      console.log(isShow,'isShow')
      if (isShow==="png"||isShow==="jpg"){
        this.showImg = file.url;
        this.dialogImg = true;
      }else{
        console.log("ImageDownload`" +file.url +"`" );
      }
    },
    downThis(){
      console.log("ImageDownload`" +this.showImg +"`" );
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      console.log(file,'beforeRemove');
      console.log(this.fileList,'this.fileList');
      console.log(fileList,'fileList');
      // return this.fileList.splice(file.url);
    },
    uploadSuc(response, file, fileList){
      if (response.code===200){
        let data = {name: response.data.originalFilename,url:response.data.url}
        this.fileList.push(data)
        // fileList.push(file)
      }
    },
    typeChange(e){
      if (e==1){
        this.subTypeList =[{name:"储备用地",value:"1-1"},{name:"未开发建设用地",value:"1-2"},{name:"五为四必形成的存量建设用地",value:"1-3"},{name:"出让到期国土及其他特殊建设用地",value:"1-4"}];
        if (this.form.landSubType == '2-1'){
          this.form.landSubType = '';
        }
      }else {
        this.subTypeList =[{name:"非建设镇保等",value:"2-1"}]
        if (this.form.landSubType != '2-1'){
          this.form.landSubType = '';
        }
      }

    },
    onSubmit() {
      this.form.manageId=this.id;
      let data = this.form;
      if (this.fileList){
        data.picList = this.fileList;
      }
      if (this.form.id){
        if (this.form.landSubType){
          getComTuEdit(data).then((res) => {
            if (res.code===200){
              this.$message({
                type: "success",
                message: "编辑成功!",
              });
              this.$router.push({ path: '/company', query: {id: this.form.manageId,card:'companyInfo'}});
            }
          });
        }else {
          this.$message({
            type: "warning",
            message: "土地子类别不能为空",
          });
        }
      }else {
        if (this.form.landSubType){
          getComTuAdd(data).then((res) => {
            if (res.code===200){
              this.$message({
                type: "success",
                message: "新增成功!",
              });
              this.$router.push({ path: '/company', query: {id: this.form.manageId,card:'companyInfo'}});
            }
          });
        }else {
          this.$message({
            type: "warning",
            message: "土地子类别不能为空",
          });
        }

      }
    },
    initData(){
      getComTuInfo( this.form.id).then((res) => {
        if (res.code===200){
          this.form=res.data;
          this.typeChange(this.form.landType);
          if (this.form.picList){
            this.fileList =this.form.picList;
            console.log(this.fileList ,'this.fileList ')
          }
        }
      });
    },
    onBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.$route.query.editId){
      this.id  = this.$route.query.id;
      this.form.id = this.$route.query.editId;
      this.initData();
    }

  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}

</style>
