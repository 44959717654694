<template>
  <div>
    <div style="display: flex">
      <div class="add" v-bind:class=" show === 'total'?'add2':''"   @click="showBtn('total')">汇总表</div>
      <div class="add" v-bind:class=" show === 'info'?'add2':''"  @click="showBtn('info')">明细表</div>
    </div>


<!--     :tree-props="{children: 'subList', hasChildren:'true' }"-->
    <div style="padding-bottom: 2vh;"></div>
    <el-table
        :data="totalList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
        row-key="landType"
        v-if=" show === 'total'">
    >
      <el-table-column prop="parentIndex" label="序号"  width="60"></el-table-column>
      <el-table-column prop="landType" label="土地类别" ></el-table-column>
      <el-table-column prop="area" label=面积></el-table-column>
      <el-table-column prop="landNumber" label=地块处数></el-table-column>
      <el-table-column prop="rentOutArea" label=出租面积(亩)></el-table-column>
      <el-table-column prop="rentOutNumber" label=出租处数></el-table-column>
      <el-table-column prop="yearRent" label=年租金(万元)></el-table-column>
      <el-table-column prop="inIdleArea" label=闲置面积></el-table-column>
    </el-table>

    <div v-if=" show === 'info'" >
      <div class="right-top">
        <el-form :model="form">
          <div
              style=" display: flex;text-align: left;justify-content: left"
          >
            <el-form-item label="土地类别：">
              <el-select
                  v-model="form.landSubType"
                  placeholder="请选择类型"
              >
                <el-option label="储备用地" value="1-1"></el-option>
                <el-option label="未开发建设用地" value="1-2"></el-option>
                <el-option label="五为四必形成的存量建设用地" value="1-3"></el-option>
                <el-option label="出让到期国土及其他特殊建设用地" value="1-4"></el-option>
                <el-option label="非建设镇保等" value="2-1"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item label="编号：">
              <el-input
                  placeholder="请输入编号"
                  v-model="form.landCode"
              ></el-input>
            </el-form-item>
            <div class="add3"   @click="search('total')">搜索</div>
            <div class="add3"   @click="backSearch('info')">重置</div>

          </div>
        </el-form>
      </div>
      <el-table
          :data="totalList"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
          :tree-props="{children: 'subList', hasChildren:'true' }"
          row-key="landType"
          v-if=" show === 'info'">
        >
        <el-table-column prop="landSubType" label="土地类别" >
          <template template slot-scope="scope">
            <div v-if="scope.row.landSubType=='1-1'">储备用地</div>
            <div v-if="scope.row.landSubType=='1-2'">未开发建设用地</div>
            <div v-if="scope.row.landSubType=='1-3'">五为四必形成的存量建设用地</div>
            <div v-if="scope.row.landSubType=='1-4'">出让到期国土及其他特殊建设用地</div>
            <div v-if="scope.row.landSubType=='2-1'">非建设镇保等</div>
          </template>

        </el-table-column>
        <el-table-column prop="landCode" label="编号" ></el-table-column>
<!--        <el-table-column prop="remark" label="土地名称" ></el-table-column>-->
        <el-table-column prop="address" label=土地位置></el-table-column>
        <el-table-column prop="area" label=面积(亩)></el-table-column>
        <el-table-column prop="selfUseArea" label=自用面积(亩)></el-table-column>
        <el-table-column prop="rentOutArea" label=出租面积(亩)></el-table-column>
<!--        <el-table-column prop="rentOutNumber" label=出租处数></el-table-column>-->
        <el-table-column prop="yearRent" label=年租金(万元)></el-table-column>
        <el-table-column prop="inIdleArea" label=闲置面积></el-table-column>
        <el-table-column prop="remark" label=备注></el-table-column>
      </el-table>

      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10]"
          :page-size="100"
          layout="sizes, prev, pager, next , total"
          :total="total"
      >
      </el-pagination>
    </div>



  </div>


</template>

<script>
import {getComTuList, getComTuTotal, getHousingXian} from "@/api/Hosing";
export default {
  name: "userJurisdiction",
  props:{
    rowId:String
  },
  data() {
    return {
      form: {
        landSubType: "",
        landCode: "",
        manageId: this.rowId,
        pageSize: 15,
        pageNum: 1,// 手机号
      },
      totalList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      show:'total'
    };
  },

  methods: {
    search(){
      let data = this.form;
      // this.form.manageId= this.rowId;
      getComTuList(data).then((res) => {
        this.totalList = res.rows;
        console.log(this.totalList,'inIdleArea')
      });
    },
    backSearch(){
      this.form={}
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.rowId,
        landSubType: '',
        landCode: '',
      };
      getComTuList(data).then((res) => {
        this.totalList = res.rows;
        console.log(this.totalList,'inIdleArea')
      });
    },
    // backSearch(){
    //   this.form={}
    //   let data = {
    //     pageSize: this.pageSize,
    //     pageNum: this.currentPage,
    //     manageId: this.rowId,
    //     landSubType: '',
    //     landCode: '',
    //   };
    //   getComTuList(data).then((res) => {
    //     this.totalList = res.rows;
    //     console.log(this.totalList,'inIdleArea')
    //   });
    // },
    showBtn(e){
      this.show=e;
      if (this.show==='total'){
        this.getList();
      }else {
        this.getInfo();
      }
    },
    goIndex(){
      this.$router.push("/index");
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getInfo();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.rowId,
      };
      getComTuTotal(data).then((res) => {
        this.totalList = res.data;
        this.totalList.forEach((item,index) =>{
          item.parentIndex = index+1;
          if (item.subList){
            item.subList.forEach((it,ind) =>{
              it.parentIndex = item.parentIndex+ '.'+ (ind+1)
            })
          }

        })

        console.log(this.totalList,'inIdleArea')
      });
    },


getInfo() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.rowId,
        landSubType: '',
        landCode: '',
      };
      getComTuList(data).then((res) => {

        this.totalList = res.rows;
        this.total = res.total;
        console.log(this.totalList,'inIdleArea')
      });
    },

  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {

          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;

  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.add{
  width: 10vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
  margin-right: 3vw;
}
.add2{
  width: 10vw;
  height: 100%;
  background: url(@/assets/use/btn.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
  margin-right: 3vw;
}
.add3{
  width: 6vw;
  height: 100%;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4.6vh;
  text-align: center;
  cursor: pointer;
  margin-left: 3vw;
}
::v-deep .el-icon-arrow-right{
  color: #FF884D;
}
</style>
