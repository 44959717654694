<template>
  <div style="background: #F7F7F7;width: 100%;height: 100vh;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >
      <el-form ref="form" :model="form"  >
        <div style="width: 100%;padding-bottom: 20px">基本信息</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="编号" label-width="100px">
              <el-input v-model="form.landCode" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资产信息" label-width="100px">
              <el-input v-model="form.assetName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="有无产权证" label-width="100px">
              <el-radio-group v-model="form.haveTitleCertificate">
                <el-radio label="0" name="type">有</el-radio>
                <el-radio label="1" name="type">无</el-radio>
                <el-radio label="2" name="type">部分有</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="地理位置" label-width="100px">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="面积(亩)" label-width="100px">
              <el-input v-model="form.area"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="权证登记单位" label-width="100px">
              <el-input v-model="form.certificateUnit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="权证登记号码" label-width="100px">
              <el-input v-model="form.certificateCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="权利人" label-width="100px">
              <el-input v-model="form.certificateUser"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="使用人" label-width="100px">
              <el-input v-model="form.useUser"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系方式" label-width="100px">
              <el-input v-model="form.telphone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="管理单位" label-width="100px">
              <el-input v-model="form.manageUnit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发证日期" label-width="100px">
              <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.dateOfIssue" style="width: 100%;"></el-date-picker>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="土地性质" label-width="100px">
              <el-radio-group v-model="form.landNature">
                <el-radio label="2" name="type">集体</el-radio>
                <el-radio label="1" name="type">国有</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="土地类型" label-width="100px">
              <el-select v-model="form.landType" placeholder="土地类型">
                <el-option label="储备用地" value="1"></el-option>
                <el-option label="建设用地" value="2"></el-option>
                <el-option label="其他用地" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="镇保情况" label-width="100px">
              <el-radio-group v-model="form.chinburgCondition">
                <el-radio label="1" name="type">已做</el-radio>
                <el-radio label="2" name="type">未做</el-radio>
                <el-radio label="3" name="type">不涉及</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产出/效益" label-width="100px">
              <el-input v-model="form.output"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        、
            <el-form-item label="取得方式" label-width="100px">
              <el-radio-group v-model="form.getMethod">
                <el-radio label="1" name="type">集体土地批准使用</el-radio>
                <el-radio label="2" name="type">租赁</el-radio>
                <el-radio label="3" name="type">出让</el-radio>
                <el-radio label="4" name="type">转让</el-radio>
                <el-radio label="5" name="type">划拨</el-radio>
                <el-radio label="6" name="type">征而未用</el-radio>
                <el-radio label="7" name="type">区级委托</el-radio>
                <el-radio label="8" name="type">其他</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="备注" label-width="100px">
              <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>

        <div style="width: 100%;padding-bottom: 20px">使用情况</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="自用面积(亩)" label-width="100px">
              <el-input v-model="form.selfUseArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积(亩)" label-width="100px">
              <el-input v-model="form.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元)" label-width="100px">
              <el-input v-model="form.yearRent"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置面积(亩)" label-width="100px">
              <el-input v-model="form.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="width: 100%;padding-bottom: 20px">上传附件</div>
        <el-upload
            class="upload-demo"
            action="https://townassets.zhishang2017.com/api/cunzhen/accessory/uploadFile"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="uploadSuc"
            multiple
            :limit="9"
            :on-exceed="handleExceed"
            :file-list="fileList">
          <div slot="tip" class="el-upload__tip">文件不超过20M</div>
        </el-upload>


      </el-form>
    </div>

    <el-dialog
        style="top:5%"
        :visible.sync="dialogImg"

    >
      <div style="text-align: center">
        <img
            class="image_1"
            referrerpolicy="no-referrer"
            :src="showImg"
            style="width: 68%;height: 68%"
        />

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downThis">下 载</el-button>
      </div>

    </el-dialog>

  </div>

</template>
<script>
import { getCunDongEdit, getCunDongInfo, getCunDongAdd} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {
      },
      dialogImg:false,
      showImg:'',
      fileList: []
    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/land', query: {id: this.form.manageId,card:'landDong'}});
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      let isShow = file.url.slice(-3);
      console.log(isShow,'isShow')
      if (isShow==="png"||isShow==="jpg"){
        this.showImg = file.url;
        this.dialogImg = true;
      }else{
        console.log("ImageDownload`" +file.url +"`" );
      }
    },
    downThis(){
      console.log("ImageDownload`" +this.showImg +"`" );
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      console.log(file,'file');
      console.log(fileList,'fileList');
      return this.fileList.splice(file.url);
    },
    uploadSuc(response, file, fileList){
      if (response.code===200){
        let data = {name: response.data.originalFilename,url:response.data.url}
        this.fileList.push(data)
      }
    },
    onSubmit() {
      let data = this.form;
      if (this.fileList){
        data.picList = this.fileList;
      }
      if (this.form.id){
        getCunDongEdit(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            //this.$router.push({ path: '/landDongInfo', query: {id: this.form.manageId}});
            this.$router.push({ path: '/land', query: {id: this.form.manageId,card:'landDong'}});
          }
        });
      }else {
        getCunDongAdd(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            // this.$router.push({ path: '/landDongInfo', query: {id: this.form.manageId}});
            this.$router.push({ path: '/land', query: {id: this.form.manageId,card:'landDong'}});
          }
        });
      }
    },
    initData(){
      getCunDongInfo( this.form.id).then((res) => {
        if (res.code===200){
         this.form=res.data;
          if (this.form.picList){
            this.fileList =this.form.picList;
            console.log(this.fileList ,'this.fileList ')
          }
        }
      });
    },
    onBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    this.form.manageId = this.$route.query.id;
    if (this.$route.query.editId){
      this.form.id = this.$route.query.editId;
      this.initData();
    }

  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}

</style>
