<template>
  <div>
    <Header v-if="$route.path != '/login' && $route.path != '/' && $route.path != '/forgetPsd'
    && $route.path != '/housingAdd'&& $route.path != '/housingCunAdd'&& $route.path != '/landZhenPropertyAdd'
    && $route.path != '/landDongInfoAdd'&& $route.path != '/landZhuanAdd'&& $route.path != '/companyInfoAdd'
    && $route.path != '/companyMingAdd'&& $route.path != '/juAdd'&& $route.path != '/landZuAdd'
    && $route.path != '/housingAddInfo'&& $route.path != '/landZhenPropertyAddInfo'&& $route.path != '/landDongInfoAddInfo'
" />
    <router-view v-if="$route.path == '/login' || $route.path == '/' || $route.path == '/forgetPsd'
    || $route.path == '/housingAdd'|| $route.path == '/housingCunAdd'|| $route.path == '/landZhenPropertyAdd'
    || $route.path == '/landDongInfoAdd'|| $route.path == '/landZhuanAdd'|| $route.path == '/companyInfoAdd'
    || $route.path == '/companyMingAdd'|| $route.path == '/juAdd'|| $route.path == '/landZuAdd'
    || $route.path == '/housingAddInfo'|| $route.path == '/landZhenPropertyAddInfo'|| $route.path == '/landDongInfoAddInfo'"></router-view>
  </div>
</template>

<script>
import Header from '@/components/header.vue'

export default {
  components:{Header}
}
</script>


<style>
* {
  margin: 0;
  padding: 0;
}
body,html {
  width: 100%;
  height: 100%;
  background: #100C2A;
}
</style>
