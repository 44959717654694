<template>
  <div style="background: #F7F7F7;width: 100%;height: 100%;padding-top: 2vh;padding-bottom: 2vh">
    <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top:0.5vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
         @click="goIndex()">
      <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
      />
      <span class="text_1">返回</span>
    </div>
    <div class="formItem" >

      <el-form ref="form" :model="form"  >
        <div style="width: 100%;padding-bottom: 20px">基本信息</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="土地所有人" label-width="100px">
              <el-input v-model="form.landHolder" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="镇保土地总面积" label-width="120px">
              <el-input v-model="allTotal" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置面积总面积" label-width="180px">
              <el-input v-model="xianTotal" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置面积总处数" label-width="180px">
              <el-input v-model="xianChuTotal" disabled></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <div style="width: 100%;padding-bottom: 20px">农发公司管理面积(亩)</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="总管理面积" label-width="100px">
              <el-input v-model="nongTotal"  disabled ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自用土地" label-width="120px">
              <el-input v-model="form.agriculture.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="180px">
              <el-input v-model="form.agriculture.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.agriculture.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置处数" label-width="100px">
              <el-input v-model="form.agriculture.inIdleNum" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元/年)" label-width="120px">
              <el-input v-model="form.agriculture.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="width: 100%;padding-bottom: 20px">城投公司管理面积(亩)</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="总管理面积" label-width="100px">
              <el-input v-model="chengTotal" disabled ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自用土地" label-width="120px">
              <el-input v-model="form.city.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="180px">
              <el-input v-model="form.city.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.city.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置处数" label-width="100px">
              <el-input v-model="form.city.inIdleNum" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元/年)" label-width="120px">
              <el-input v-model="form.city.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="width: 100%;padding-bottom: 20px">三灶园区管理面积(亩)</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="总管理面积" label-width="100px">
              <el-input v-model="sanTotal"  disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自用土地" label-width="120px">
              <el-input v-model="form.sanzaoPark.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="180px">
              <el-input v-model="form.sanzaoPark.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.sanzaoPark.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置处数" label-width="100px">
              <el-input v-model="form.sanzaoPark.inIdleNum" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元/年)" label-width="120px">
              <el-input v-model="form.sanzaoPark.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="width: 100%;padding-bottom: 20px">南工区管理面积(亩)</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="总管理面积" label-width="100px">
              <el-input v-model="nanTotal" disabled ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自用土地" label-width="120px">
              <el-input v-model="form.south.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="180px">
              <el-input v-model="form.south.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.south.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置处数" label-width="100px">
              <el-input v-model="form.south.inIdleNum" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元/年)" label-width="120px">
              <el-input v-model="form.south.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="width: 100%;padding-bottom: 20px">村自管面积(亩)</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="总管理面积" label-width="100px">
              <el-input v-model="cunTotal" disabled ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自用土地" label-width="120px">
              <el-input v-model="form.burg.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="180px">
              <el-input v-model="form.burg.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.burg.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置处数" label-width="100px">
              <el-input v-model="form.burg.inIdleNum" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元/年)" label-width="120px">
              <el-input v-model="form.burg.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="width: 100%;padding-bottom: 20px">其他</div>
        <el-row >
          <el-col :span="6">
            <el-form-item label="总管理面积" label-width="100px">
              <el-input v-model="qiTotal"  disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="自用土地" label-width="120px">
              <el-input v-model="form.other.selfUseArea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出租面积" label-width="180px">
              <el-input v-model="form.other.rentOutArea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="6">
            <el-form-item label="闲置面积" label-width="100px">
              <el-input v-model="form.other.inIdleArea" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="闲置处数" label-width="100px">
              <el-input v-model="form.other.inIdleNum" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年租金(万元/年)" label-width="120px">
              <el-input v-model="form.other.yearRent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>





      </el-form>

    </div>
  </div>

</template>
<script>
import {getZhenInfo, getZhenEdit, getHosingCunInfo, getZhenAdd} from "@/api/Hosing";
import {getUserList} from "@/api/jurisdiction";

export default {
  data() {
    return {
      form: {
        agriculture:{
          selfUseArea:'',
          rentOutArea:'',
          inIdleArea:'',
          inIdleNum:'',
          totalArea:''
        },
        city:{
          selfUseArea:'',
          rentOutArea:'',
          inIdleArea:'',
          inIdleNum:'',
          totalArea:''
        },
        sanzaoPark:{
          selfUseArea:'',
          rentOutArea:'',
          inIdleArea:'',
          inIdleNum:'',
          totalArea:''
        },
        south:{
          selfUseArea:'',
          rentOutArea:'',
          inIdleArea:'',
          inIdleNum:'',
          totalArea:''
        },
        burg:{
          selfUseArea:'',
          rentOutArea:'',
          inIdleArea:'',
          inIdleNum:'',
          totalArea:''
        },
        other:{
          selfUseArea:'',
          rentOutArea:'',
          inIdleArea:'',
          inIdleNum:'',
          totalArea:''
        },
        totalArea:'',
        inIdleArea:'',
        inIdleNumber:''

      },
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    }
  },
  computed: {
    allTotal: function () {
      let num = Number(this.form.agriculture.totalArea) + Number(this.form.sanzaoPark.totalArea)+Number(this.form.south.totalArea)
      +Number(this.form.burg.totalArea) +Number(this.form.other.totalArea)+ Number(this.form.city.totalArea)
      this.form.totalArea = num;
      return   num
    },
    xianTotal: function () {
      let num = Number(this.form.agriculture.inIdleArea) + Number(this.form.sanzaoPark.inIdleArea)+Number(this.form.south.inIdleArea)
          +Number(this.form.burg.inIdleArea) +Number(this.form.other.inIdleArea)+ Number(this.form.city.inIdleArea)
      this.form.inIdleArea = num;
      return   num
    },
    xianChuTotal: function () {
      let num = Number(this.form.agriculture.inIdleNum) + Number(this.form.sanzaoPark.inIdleNum)+Number(this.form.south.inIdleNum)
          +Number(this.form.burg.inIdleNum) +Number(this.form.other.inIdleNum)+ Number(this.form.city.inIdleNum)
      this.form.inIdleNumber = num;
      return   num
    },
    nongTotal: function () {
      let num = Number(this.form.agriculture.selfUseArea) + Number(this.form.agriculture.rentOutArea)+Number(this.form.agriculture.inIdleArea)
      this.form.agriculture.totalArea = num;
      return   num
    },
    chengTotal: function () {
      let num = Number(this.form.city.selfUseArea) + Number(this.form.city.rentOutArea)+Number(this.form.city.inIdleArea)
      this.form.city.totalArea = num;
      return   num
    },
    sanTotal: function () {
      let num = Number(this.form.sanzaoPark.selfUseArea) + Number(this.form.sanzaoPark.rentOutArea)+Number(this.form.sanzaoPark.inIdleArea)
      this.form.sanzaoPark.totalArea = num;
      return   num
    },
    nanTotal: function () {
      let num = Number(this.form.south.selfUseArea) + Number(this.form.south.rentOutArea)+Number(this.form.south.inIdleArea)
      this.form.south.totalArea = num;
      return   num
    },
    cunTotal: function () {
      let num = Number(this.form.burg.selfUseArea) + Number(this.form.burg.rentOutArea)+Number(this.form.burg.inIdleArea)
      this.form.burg.totalArea = num;
      return   num
    },
    qiTotal: function () {
      let num = Number(this.form.other.selfUseArea) + Number(this.form.other.rentOutArea)+Number(this.form.other.inIdleArea)
      this.form.other.totalArea = num;
      return   num
    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/land', query: {id: this.form.manageId,card:'zhenLand'}});
    },
    onSubmit() {
      let data = this.form;
      if (this.form.id){
        getZhenEdit(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.$router.push({ path: '/land', query: { card: 'zhenLand',id: this.form.manageId}});
          }
        });
      }else {
        getZhenAdd(data).then((res) => {
          if (res.code===200){
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.$router.push({ path: '/land', query: { card: 'zhenLand',id: this.form.manageId}});
          }
        });
      }
    },
    initData(){
      getZhenInfo(this.form.id).then((res) => {
        if (res.code===200){
         this.form=res.data;
        }
      });
    },
    onBack(){
      this.$router.go(-1);
    }
  },
  mounted() {
    this.form.manageId = this.$route.query.id;
    if (this.$route.query.editId){
      this.form.id = this.$route.query.editId;
      this.initData();
    }

  }
}
</script>

<style>
/** {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
/*body,html {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: #F7F7F7 !important;*/
/*}*/

.formItem{
  width: 92vw;
  margin-left: 3vw;
  background: #FFFFFF;
  padding: 1vw;
  border-radius: 10px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-top: 20px;
}

</style>
