<template>
  <div>
    <div class="top" style="text-align: center">
      <img src="@/assets/use/index_logo.png" style="width: 80vw"/>
    </div>
    <div>
      <div class="next" style = "display: flex;justify-content: space-around;width: 60vw;margin-left: 20vw;align-items: center;">
        <div v-if="index||superuser">
          <img src="@/assets/use/index1.png" style="width: 15vw;cursor: pointer;"  @click="goView('indexOverview')"/>
        </div>
        <div  v-if="house||superuser">
          <img src="@/assets/use/index5.png" style="width: 13vw;cursor: pointer;"  @click="goView('housingTotal')"/>
        </div>
        <div v-if="land||superuser">
          <img src="@/assets/use/index4.png" style="width: 15vw;cursor: pointer;"   @click="goView('landTotal')"/>
        </div>
      </div>
      <div class="last" style = "display: flex;justify-content: space-around;width: 30vw;margin-left: 35vw;align-items: center;margin-top: 10vh;">
        <div v-if="role||superuser">
          <img src="@/assets/use/index3.png" style="width: 8vw;cursor: pointer;"   @click="goView('role')"/>
        </div>
        <div  v-if="log||superuser">
          <img src="@/assets/use/index2.png" style="width: 8vw;cursor: pointer;"  @click="goView('log')"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  getOverviewOfPower,
  getSummaryOfPower,
  getEquipmentTotal,
  getWorkNum,
  getEnvironment,
  getAlarmTotal,
  getReduce,
  getEnergy,
} from "@/api/home";
import {getUserInfo} from "@/api";
export default {
  name: "index",
  data() {
    return {
      superuser:false,
      index:false,
      house:false,
      land:false,
      role:false,
      log:false,
    };
  },
  methods: {
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },

    goView(e){
      this.$router.push('/'+e);
    },
    // 获取用户信息
    // getUser() {
    //   getUserInfo().then((res) => {
    //     localStorage.setItem("userInfo",  res.data);
    //     console.log(res.data,'user1111111111')
    //     if (res.emp) {
    //       this.info.city = res.emp.etProvince;
    //       this.title = res.user.logoName;
    //       this.logo = res.user.logoVar;
    //     } else {
    //       this.info.city = "";
    //     }
    //   });
    // },


  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    // this.getUser();
    // this.initMap();
    let data = localStorage.getItem("permissions");
    console.log(data.includes("*"),'data.includes("*")')
    if (data.includes("*")){
      this.superuser = true;
    }
    if (data.includes("可视智能屏")){
      this.index = true;
    }
    if (data.includes("房屋信息")){
      this.house = true;
    }
    if (data.includes("土地信息")){
      this.land = true;
    }
    console.log(data.includes("土地信息",'data.includes土地信息'))
    console.log(data.includes("权限管理",'data.includes("权限管理")'))
    if (data.includes("权限管理")){
      this.role = true;
    }
    if (data.includes("日志管理")){
      this.log = true;
    }



  },
};
</script>

<style lang="scss" scoped>
.next{

}
.last{

}
</style>
