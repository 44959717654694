import requests from './request'

// 分时用能
export function getTimeSharing(data){
    return requests.get('/custom/anl/timesharing?psId=' + data.psId + '&type=' + data.type + '&mType=' + data.mType + '&dateStr=' + data.dateStr,data)
}

// 分时用能查询
export function getTimeSharingTable(data){
    return requests.get('/custom/anl/timeSha?title=' + data.title + '&type=' + data.type + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&bTime=' + data.bTime + '&eTime=' + data.eTime,data)
}

// 获取电站列表
export function getStationList(){
    return requests.get('/custom/anl/psList')
}

// 分时用能导出
export function exportEleList(data){
    return requests.get('/custom/anl/importTimeSha?title=' + data.title + '&type=' + data.type + '&bTime=' + data.bTime + '&eTime=' + data.eTime,data)
}

// 电价查询
export function getElePrice(id){
    return requests.get('/custom/anl/psListPrice?psId=' + id)
}

// 分时电费查询
export function getTimeSharingPriceTable(data){
    return requests.get('/custom/anl/timeShaPrice?title=' + data.title + '&type=' + data.type + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&bTime=' + data.bTime + '&eTime=' + data.eTime,data)
}

// 分时电费导出
export function exportElePrice(data){
    return requests.get('/custom/anl/importTimeSha?title=' + data.title + '&type=' + data.type + '&bTime=' + data.bTime + '&eTime=' + data.eTime,data)
}

// 总用能排行图表
export function getTotalEle(type,dateStr){
    return requests.get('/custom/anl/mainsElectricitySta?type=' + type + '&dateStr=' + dateStr)
}

// 总用能排行台账
export function getTotalEleTable(data){
    return requests.get('/custom/anl/mainsElectricity?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&type=' + data.type + '&year=' + data.year + '&month=' + data.month + '&title=' + data.title,data)
}

// 总用能导出
export function exportTotalEleTable(data){
    return requests.get('/custom/anl/importMainsElectricity?type=' + data.type + '&year=' + data.year + '&month=' + data.month + '&title=' + data.title,data)
}

// 总用能排行
export function getTotalEnergy(){
    return requests.get('/custom/cto/ctRank')
}

// 用能占比
export function getEnergyProportion(){
    return requests.get('/custom/cto/ctRank1')
}

// 太阳能用量排
export function getEnergyConsumption(){
    return requests.get('/custom/cto/ctRank2')
}

// 市电用能排行
export function getMainsEnergyConsumption(){
    return requests.get('/custom/cto/ctRank3')
}

// 分时用能
export function getHoursEnergy(){
    return requests.get('/custom/cto/timeCan1')
}

// 损耗分析
export function getLossAnalysis(bTime){
    return requests.get('/custom/cto/spoilageApi?bTime=' + bTime)
}

// 损耗分析图表
export function getLossAnalysisChart(bTime,psId){
    return requests.get('/custom/anl/spoilage?bTime=' + bTime + '&psId=' + psId)
}

// 损耗分析台账
export function getLossAnalysisTable(data){
    return requests.get('/custom/anl/spoilage1?bTime=' + data.bTime + '&title=' + data.title + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&eTime=' + data.eTime,data)
}

// 损耗分析导出
export function exportLossAnalysisTable(data){
    return requests.get('/custom/anl/importSpoilage?bTime=' + data.bTime + '&title=' + data.title + '&eTime=' + data.eTime,data)
}

// 逆变器列表
export function getInverterList(id){
    return requests.get('/custom/anl/getItMeList/' + id)
}

// 直流电电流
export function getVoltAmmeter(bTime,sn,type){
    return requests.get('/custom/anl/voltAmmeter?bTime=' + bTime + '&sn=' + sn + '&type=' + type)
}

// 直流电电压
export function getVoltAmmeterV(bTime,sn){
    return requests.get('/custom/anl/voltAmmeter?bTime=' + bTime + '&sn=' + sn)
}

// 直流电电流台账
export function getVoltAmmeterList(data){
    return requests.get('/custom/anl/voltAmmeter?bTime=' + data.bTime + '&itName=' + data.itName + '&itCapacity=' + data.itCapacity + '&itSicount=' + data.itSicount + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&type=' + data.type,data)
}

// 直流电电流台账导出
export function exportVoltAmmeterList(data){
    return requests.get('/custom/anl/importVoltAmmeter?bTime=' + data.bTime + '&itName=' + data.itName + '&itCapacity=' + data.itCapacity + '&itSicount=' + data.itSicount,data)
}

// 同区域发电量对比图表
export function getSameAreaEle(data){
    return requests.get('/custom/vsi/powerPowerGeneration1?dateStr=' + data.dateStr + '&psCapacity=' + data.psCapacity + '&type=' + data.type + '&province=' + data.province + '&city=' + data.city + '&area=' + data.area,data)
}

// 同区域发电量对比台账
export function getSameAreaEleList(data){
    return requests.get('/custom/vsi/powerPowerGeneration?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&psCapacity=' + data.psCapacity + '&type=' + data.type + '&title=' + data.title,data)
}

// 同区域发电量对比台账导出
export function exportSameAreaEleList(data){
    return requests.get('/custom/vsi/powerPowerGeneration?psCapacity=' + data.psCapacity + '&type=' + data.type + '&title=' + data.title,data)
}

// 功率图表
export function getPower(data){
    return requests.get('/custom/vsi/totalPower?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&psId=' + data.psId,data)
}

// 功率台账
export function getPowerList(data){
    return requests.get('/custom/vsi/totalPower1?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&title=' + data.title,data)
}

// 功率导出
export function exportPowerList(data){
    return requests.get('/custom/vsi/importTotalPower?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&title=' + data.title,data)
}

// 发电量图表
export function getPowerGeneration(data){
    return requests.get('/custom/vsi/powerPowerGeneration1?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&type=' + data.type + '&psId=' + data.psId,data)
}

// 发电量台账
export function getPowerGenerationList(data){
    return requests.get('/custom/vsi/powerPowerGeneration?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&eTime=' + data.eTime + '&psId=' + data.psId + '&type=' + data.type,data)
}

// 发电量导出
export function exportPowerGenerationList(data){
    return requests.get('/custom/vsi/importPowerGeneration?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&title=' + data.title,data)
}

// 逆变器发电量图表
export function getInverterPower(data){
    return requests.get('/custom/vsi/getPvPvInverter1?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&type=' + data.type + '&psId=' + data.psId,data)
}

// 逆变器发电量台账
export function getInverterPowerList(data){
    return requests.get('/custom/vsi/getPvPvInverter?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&type=' + data.type + '&itName=' + data.itName + '&title=' + data.title + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,data)
}

// 逆变器发电量导出
export function exportInverterPowerList(data){
    return requests.get('/custom/vsi/importGetPvPvInverter?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&title=' + data.title + '&itName=' + data.itName + '&type=' + data.type,data)
}

// 逆变器温度图表
export function getInverterTemperature(data){
    return requests.get('/custom/vsi/temperature1?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&psId=' + data.psId,data)
}

// 逆变器温度
export function getInverterTemperatureList(data){
    return requests.get('/custom/vsi/temperature?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&itName=' + data.itName + '&title=' + data.title + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,data)
}

// 逆变器温度导出
export function exportInverterTemperatureList(data){
    return requests.get('/custom/vsi/importTemperature?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&itName=' + data.itName + '&title=' + data.title + '&pageSize=' + data.pageSize + '&pageNum=' + data.pageNum,data)
}

// 逆变器等效时长图表
export function getInverterHour(data){
    return requests.get('/custom/vsi/getEquivalent1?itCapacity=' + data.itCapacity + '&dateStr=' + data.dateStr + '&psId=' + data.psId + '&type=' + data.type,data)
}

// 逆变器等效时长台账
export function getInverterHourList(data){
    return requests.get('/custom/vsi/equivalent?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&title=' + data.title + '&itName=' + data.itName + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,data)
}

// 逆变器等效时长导出
export function exportInverterHourList(data){
    return requests.get('/custom/vsi/importEquivalent?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&title=' + data.title + '&type=' + data.type + '&itName=' + data.itName,data)
}

// 逆变器组串离散率图表
export function getInverterDispersionRate(data){
    return requests.get('/custom/vsi/dispersionRate1?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&psId=' + data.psId,data)
}

// 逆变器组串离散率台账
export function getInverterDispersionRateList(data){
    return requests.get('/custom/vsi/dispersionRate?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&itName=' + data.itName + '&title=' + data.title + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,data)
}

// 逆变器组串离散率台账导出
export function exportInverterDispersionRateList(data){
    return requests.get('/custom/vsi/importDispersionRate?itCapacity=' + data.itCapacity + '&bTime=' + data.bTime + '&itName=' + data.itName + '&title=' + data.title,data)
}

// 发电量台账信息
export function getPowerAccountInfo(data){
    return requests.get('/custom/yge/photovoltaicDetailList4?psCapacity=' + data.psCapacity + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&psId=' + data.psId + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&type=' + data.type,data)
}

// 导出发电量
export function exportPower(data){
    return requests.get('/custom/yge/importPhotovoltaicDetailList4?psId=' + data.psId + '&type=' + data.type + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&psCapacity=' + data.psCapacity,data)
}