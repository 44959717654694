import { render, staticRenderFns } from "./landCompany.vue?vue&type=template&id=0b4d4104&scoped=true"
import script from "./landCompany.vue?vue&type=script&lang=js"
export * from "./landCompany.vue?vue&type=script&lang=js"
import style0 from "./landCompany.vue?vue&type=style&index=0&id=0b4d4104&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4d4104",
  null
  
)

export default component.exports