<template>
  <div class="container">
    <div class="left-title" v-if="type == 1">电站离线率</div>
    <div class="left-title" v-if="type == 2">节点离线时长</div>
    <div class="back" @click="backPrev">返回</div>
    <div class="right-container">
      <div class="right-top">
        <el-form :model="form" v-if="type == 1">
          <div style="width: 62.5vw; display: flex; align-items: center">
            <el-form-item label="企业名称：">
              <el-input
                v-model="form.companyName"
                placeholder="请输入企业名称"
                style="width: 15.6vw; margin-right: 2vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="电站名称：">
              <el-input
                v-model="form.stationName"
                placeholder="请输入电站名称"
                style="width: 15.6vw; margin-right: 2vw"
              ></el-input>
            </el-form-item>
          </div>
          <div
            style="width: 62.5vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="报文时间：">
              <el-date-picker
                style="width: 11vw; margin-right: 2vw"
                v-model="form.beginTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions0"
                placeholder="开始日期"
                disabled
              >
              </el-date-picker>
              <el-date-picker
                style="width: 11vw"
                v-model="form.endTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions1"
                placeholder="结束日期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div>
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
        <el-form :model="form" v-if="type == 2">
          <div style="width: 67vw; display: flex; align-items: center">
            <el-form-item label="企业名称：">
              <el-input
                v-model="form.companyName"
                placeholder="请输入企业名称"
                style="width: 15.6vw; margin-right: 1vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="电站名称：">
              <el-input
                v-model="form.stationName"
                placeholder="请输入电站名称"
                style="width: 15.6vw; margin-right: 1vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="节点名称：">
              <el-input
                v-model="form.nodeName"
                placeholder="请输入节点名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
          </div>
          <div
            style="width: 67vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="报文时间：">
              <el-date-picker
                style="width: 11vw; margin-right: 2vw"
                v-model="form.beginTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions0"
                placeholder="开始日期"
                disabled
              >
              </el-date-picker>
              <el-date-picker
                style="width: 11vw"
                v-model="form.endTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions1"
                placeholder="结束日期"
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="queryTime">查询</div>
              <div class="query" @click="resetTime">重置</div>
              <div class="reset" @click="exportDataTime">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        v-if="type == 1"
        :data="offLine"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="etName" label="企业名称"></el-table-column>
        <el-table-column prop="onSum" label="在线节点数量"></el-table-column>
        <el-table-column prop="offSum" label="离线节点数量"></el-table-column>
        <el-table-column prop="rank" label="离线率"></el-table-column>
        <el-table-column prop="dt" label="报文时间"></el-table-column>
      </el-table>
      <el-table
        v-if="type == 2"
        :data="offLine"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="ndName" label="节点名称"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="etName" label="企业名称"></el-table-column>
        <el-table-column prop="onSum" label="在线时长"></el-table-column>
        <el-table-column prop="offSum" label="离线时长"></el-table-column>
        <el-table-column prop="dt" label="报文时间"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getStationOffLineTable,
  exportStationOffLineTable,
  getNodeOffLineTable,
  exportNodeOffLineTable,
} from "@/api/home";
export default {
  name: "nodeOfflineRate",
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        nodeName: "", // 节点名称
        beginTime: "", // 报文时间
        endTime: "",
        companyName: "",
        stationName: "",
      },
      offLine: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      type: "",
    };
  },
  watch: {
    type(val, oldVal) {
      console.log(val, oldVal);
    },
  },

  mounted() {
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.getList();
    } else {
      this.getTime();
    }
  },
  methods: {
    getCurrentTime() {
      var date = new Date();
      date.setDate(1);
      var month = parseInt(date.getMonth() + 1);
      var day = date.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      this.form.beginTime = date.getFullYear() + "-" + month + "-" + day;
    },
    getCurrentMonthStart() {
      var date = new Date();
      var month = parseInt(date.getMonth() + 1);
      var day = date.getDate();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      this.form.endTime = date.getFullYear() + "-" + month + "-" + day;
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.type == 1) {
        this.getList();
      } else {
        this.getTime();
      }
    },
    // 返回上一页
    backPrev() {
      let type = this.type;
      this.$router.push({
        path: "/rankingTotalEnergy",
        query: { type },
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    // 离线率
    getList() {
      // if (this.$route.query.date == "1") {
      //   let date = new Date();
      //   this.form.beginTime = this.formateDate(date);
      //   this.form.endTime = this.formateDate(date);
      // } else if (this.$route.query.date == "2") {
      //   this.getCurrentTime();
      //   this.getCurrentMonthStart();
      // } else if (this.$route.query.date == "3") {
      //   let date = new Date();
      //   date = date.getFullYear() + "-" + "01" + "-" + "01";
      //   this.form.beginTime = this.formateDate(date);
      //   this.getCurrentMonthStart();
      // }
      if (this.form.beginTime != "") {
        this.form.beginTime = this.formateDate(this.form.beginTime);
      } else {
        this.form.beginTime = "";
      }
      if (this.form.endTime != "") {
        this.form.endTime = this.formateDate(this.form.endTime);
      } else {
        this.form.endTime = "";
      }
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        etName: this.form.companyName,
        psName: this.form.stationName,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
      };
      getStationOffLineTable(data).then((res) => {
        this.offLine = res.rows;
        this.total = res.total;
      });
    },
    // 离线率查询
    query() {
      this.currentPage = 1;
      this.getList();
    },
    // 离线率重置
    reset() {
      this.form.companyName = "";
      this.form.stationName = "";
      this.form.beginTime = "";
      this.form.endTime = "";
      this.getList();
    },
    // 离线率导出
    exportData() {
      let data = {
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        etName: this.form.companyName,
        psName: this.form.stationName,
      };
      exportStationOffLineTable(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
    // 离线时长
    getTime() {
      if (this.form.beginTime != "") {
        this.form.beginTime = this.formateDate(this.form.beginTime);
      } else {
        this.form.beginTime = "";
      }
      if (this.form.endTime != "") {
        this.form.endTime = this.formateDate(this.form.endTime);
      } else {
        this.form.endTime = "";
      }
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        etName: this.form.companyName,
        psName: this.form.stationName,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        psId: this.$route.query.psId,
        ndName: this.form.nodeName,
      };
      getNodeOffLineTable(data).then((res) => {
        this.offLine = res.rows;
        this.total = res.total;
      });
    },
    // 离线时长查询
    queryTime() {
      this.getTime();
    },
    // 离线时长重置
    resetTime() {
      this.form.companyName = "";
      this.form.stationName = "";
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form.nodeName = "";
      this.getTime();
    },
    // 离线时长导出
    exportDataTime() {
      let data = {
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        etName: this.form.companyName,
        psName: this.form.stationName,
        ndName: this.form.nodeName,
        psId: this.$route.query.psId,
      };
      exportNodeOffLineTable(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .back {
    position: absolute;
    top: 8vh;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
  }
  .right-container {
    width: 67vw;
    position: absolute;
    top: 0;
    left: 5vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 0.1rem;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          border-radius: 6px;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          color: #fff;
          text-align: left;
          width: 6vw;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
.el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.6%;
  margin-bottom: 1.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-pager li.active {
  color: #409eff;
}
</style>
