<template>
  <div class="container">
    <div class="container-top">
      <div class="container-top-l">
        <div class="container-bluetitle">发电中</div>
        <img class="container-top-l-con" src="@/assets/icon/icon4.png" alt="" />
        <div class="container-top-l-dk">
          <img src="@/assets/icon/icon2.png" alt="" />
          <span>{{ intDetailData.allP }}千瓦</span>
        </div>
      </div>
      <div class="container-top-r">
        <div class="container-bluetitle">告警数</div>
        <div class="container-top-r-dk">
          <span>{{ intDetailData.alterCount }}</span>
        </div>
      </div>
    </div>
    <div class="container-nav">
      <div class="container-nav-top">
        <div class="container-bluetitle container-nav-top-l">功率曲线</div>
        <div class="container-checklist">
          <div
            class="container-checklist-init"
            :class="checkIndex == index ? 'container-checklist-change' : ''"
            v-for="(item, index) in checkList"
            :key="index"
            @click="clickCheckChange(item, index)"
          >
            {{ item.label }}
          </div>
          <el-date-picker
            :value-format="timeValueFormat"
            v-model="time"
            :type="timeType"
            placeholder="选择日期"
            @change="changeTimes"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <div id="threeChart" v-show="chartShow"></div>
    <div class="container-block">
      <div class="container-block-number">{{ intDetailData.itName }}</div>
      <div class="container-block-bot">
        <div class="container-block-bot-l">
          <table>
            <tr>
              <td>S/N号</td>
              <td>
                {{
                  intDetailData.itSlaveId && intDetailData.itSlaveId.split("-")
                    ? intDetailData.itSlaveId.split("-")[1]
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td>MAC</td>
              <td>{{ intDetailData.mac }}</td>
            </tr>
            <tr>
              <td>容量</td>
              <td>{{ intDetailData.itCapacity }}</td>
            </tr>
            <tr>
              <td>接入时间</td>
              <td>{{ intDetailData.date }}</td>
            </tr>
            <tr>
              <td>发电功率</td>
              <td>{{ intDetailData.allP }}千瓦</td>
            </tr>
            <tr>
              <td>无功功率</td>
              <td>{{ intDetailData.eq }}千瓦</td>
            </tr>
            <tr>
              <td>总功率因数</td>
              <td>{{ intDetailData.epf }}</td>
            </tr>
            <tr>
              <td>漏电流</td>
              <td>{{ intDetailData.lc }}毫安</td>
            </tr>
          </table>
        </div>
        <div class="container-block-bot-c">
          <div class="container-block-bot-c-l">
            <img src="@/assets/icon/icon2.png" alt="" />
            <p>累计发电</p>
            <p>{{ intDetailData.allPower }}度</p>
          </div>
          <div class="container-block-bot-c-c">
            <div class="container-block-bot-c-c-top">
              <p>今日发电</p>
              <p>{{ intDetailData.todayPower }}</p>
              <p>度</p>
            </div>
            <div class="container-block-bot-c-c-bot">
              <span>发电中</span>
              <img src="@/assets/icon/icon2.png" alt="" />
            </div>
          </div>
          <div class="container-block-bot-c-l container-block-bot-c-r">
            <img src="@/assets/icon/icon3.png" alt="" />
            <p>累计发电小时</p>
            <p>{{ intDetailData.allTime }}小时</p>
          </div>
        </div>
        <div class="container-block-bot-l container-block-bot-r">
          <table>
            <tr>
              <td>交流电压</td>
              <td>{{ intDetailData.v }} 伏</td>
            </tr>
            <tr>
              <td>机器内部温度</td>
              <td>{{ intDetailData.temp }} 安</td>
            </tr>
            <tr>
              <td>品牌</td>
              <td>{{ intDetailData.brand }}</td>
            </tr>
            <tr>
              <td>总视在功率</td>
              <td>{{ intDetailData.es }} VA</td>
            </tr>
            <tr>
              <td>总有功功率</td>
              <td>{{ intDetailData.ep }}</td>
            </tr>
          </table>
          <div class="container-block-bot-r-del" @click="checkParent">详情</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  adapterGetPvPvInverterGET,
  statisticsINverterDETailGET,
} from "@/api/inverterAddition";
export default {
  name: "OverviewOfInverterseDetailsInfo",
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      checkList: [
        {
          label: "功率曲线",
          value: "",
        },
        {
          label: "发电量",
          value: "",
        },
      ],
      checkIndex: 0,
      time: "",
      timeValueFormat: "yyyy-MM-dd",
      timeType: "date",
      windowHeight: null,
      windowWidth: null,
      timeXuna: 1,
      intDetailData: {},
      chartShow: false,
    };
  },
  watch: {
    checkIndex: {
      handler(newValue, oldValue) {
        switch (this.checkIndex) {
          case 0:
            this.timeValueFormat = "yyyy-MM-dd";
            this.timeType = "date";
            this.time = this.$utilsMethods.formatDate(
              new Date(),
              "YYYY/MM/dd",
              true
            );
            this.timeXuna = 1;
            break;
          case 1:
            this.timeValueFormat = "yyyy-MM";
            this.timeType = "month";
            this.time = this.$utilsMethods.formatDate(
              new Date(),
              "YYYY/MM",
              true
            );
            this.timeXuna = 2;
            break;
        }
        this.chartShow = false;
        this.adapterGetPvPvInverterAxios();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.statisticsINverterDETailAxios();
  },

  methods: {
    async statisticsINverterDETailAxios() {
      let self = this;
      const data_back = await statisticsINverterDETailGET({
        sn: self.rowData.itSlaveId,
      });
      console.log(data_back, "========statisticsINverterDETailGET");
      const { code, data } = data_back;
      if (code === 200) {
        self.intDetailData = data;
      }
    },
    changeTimes() {
      this.chartShow = false;
      this.adapterGetPvPvInverterAxios();
    },
    clickCheckChange(row, index) {
      this.checkIndex = index;
    },
    async adapterGetPvPvInverterAxios() {
      let self = this;
      const data_back = await adapterGetPvPvInverterGET({
        sn: self.rowData.itSlaveId,
        type: self.timeXuna,
        dateStr: self.time,
      });
      console.log(data_back, "========adapterGetPvPvInverterGET");
      const { code, data } = data_back;
      if (code === 200) {
        self.chartShow = true;
        const { header, val } = data;
        self.$nextTick(() => {
          self.getEchartThree(
            header && header.length ? header : [],
            val && val[0] && val[0].data && val[0].data.length
              ? val[0].data
              : []
          );
        });
      }
    },
    getEchartThree(header, val) {
      let myChart3 = this.$echarts.init(document.getElementById("threeChart"));
      // let header = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      // let val = [150, 230, 224, 218, 135, 147, 260];
      myChart3.clear(); //先清掉图表，再重绘图表
      myChart3.setOption({
        tooltip: {
          trigger: "axis",
          // formatter: function (params) {
          //   return params[0].name + "：" + params[0].data + "%";
          // },
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            axisLine: {
              show: false,
              //   lineStyle: {
              //     color: "#0B3A56",
              //   },
            },
            axisLabel: {
              color: "#fff",
              interval: 0, //使x轴文字显示全
            },
            data: header,
          },
        ],
        yAxis: [
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            name: "V",
            nameTextStyle: {
              color: "#06B4FC",
            },
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#0B3A56",
              },
            },
            axisLabel: {
              color: "#06B4FC",
            },
          },
        ],
        series: [
          {
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#5B8FF9",
            },
            data: val,
          },
        ],
        grid: {
          left: this.nowWidthSize(50),
          top: this.nowHeightSize(30),
          right: this.nowWidthSize(25),
          bottom: this.nowHeightSize(25),
        },
      });
      window.addEventListener("resize", function () {
        myChart3.resize();
      });
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    checkParent() {
      this.$emit("checkParent");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1.0417vw /* 20px -> 1.0417vw */;
  &-bluetitle {
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #02b5ff;
    font-size: 1.4583vw /* 28px -> 1.4583vw */;
  }
  &-top {
    display: flex;
    flex-wrap: wrap;
    &-l,
    &-r {
      width: 32.1354vw /* 617px -> 32.1354vw */;
    }
    &-l {
      &-con {
        width: 18.2292vw /* 350px -> 18.2292vw */;
        height: 10vw /* 192px -> 10vw */;
        margin: 3.3333vw /* 64px -> 3.3333vw */ 6.5625vw /* 126px -> 6.5625vw */
          1.25vw /* 24px -> 1.25vw */ 7.5vw /* 144px -> 7.5vw */;
      }
      &-dk {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        img {
          width: 1.9792vw /* 38px -> 1.9792vw */;
          height: 1.9792vw /* 38px -> 1.9792vw */;
          margin-right: 0.2604vw /* 5px -> .2604vw */;
        }
        span {
          display: inline-block;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #17e0fe;
          border-radius: 6px;
          border: 3px solid #17e0fe;
          font-size: 1.1458vw /* 22px -> 1.1458vw */;
          padding: 0.2604vw /* 5px -> .2604vw */ 0.9375vw /* 18px -> .9375vw */;
        }
      }
    }
    &-r {
      &-dk {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        span {
          display: inline-block;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #17e0fe;
          border-radius: 6px;
          border: 3px solid #17e0fe;
          font-size: 1.1458vw /* 22px -> 1.1458vw */;
          padding: 0.2604vw /* 5px -> .2604vw */ 0.9375vw /* 18px -> .9375vw */;
          margin: 6.4583vw /* 124px -> 6.4583vw */ 0 0 13.2813vw
            /* 255px -> 13.2813vw */;
        }
      }
    }
  }
  &-nav {
    margin: 3.6458vw /* 70px -> 3.6458vw */ 0;
    &-top {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &-l {
        margin-right: 28.4896vw /* 547px -> 28.4896vw */;
      }
    }
  }
  &-checklist {
    display: flex;
    align-items: center;
    &-init {
      color: #02b5ff;
      font-size: 1.0417vw /* 20px -> 1.0417vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      background: url("~@/assets/icon/icon1.png") no-repeat;
      background-size: 100% 100%;
      width: 7.2917vw /* 140px -> 7.2917vw */;
      height: 2.6042vw /* 50px -> 2.6042vw */;
      line-height: 2.6042vw /* 50px -> 2.6042vw */;
      text-align: center;
      cursor: pointer;
      margin-right: 1.0417vw /* 20px -> 1.0417vw */;
    }
    &-change {
      color: #ffffff !important;
    }
    &-init:last-child {
      margin-right: 0;
    }
  }
  &-block {
    box-shadow: inset 0px 0px 1.0417vw /* 20px -> 1.0417vw */ 1.0938vw
      /* 21px -> 1.0938vw */ rgba(15, 53, 116, 0.6);
    border: 1px solid #286ae8;
    padding: 2.9167vw /* 56px -> 2.9167vw */ 2.6042vw /* 50px -> 2.6042vw */;
    margin: 5.2083vw /* 100px -> 5.2083vw */ 0;
    &-number {
      text-align: center;
      margin-bottom: 2.3958vw /* 46px -> 2.3958vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #02b5ff;
      font-size: 1.4583vw /* 28px -> 1.4583vw */;
    }
    &-bot {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-bottom: 2.0833vw /* 40px -> 2.0833vw */;
      }
      &-l {
        margin-right: 5vw /* 96px -> 5vw */;
        table {
          width: 20.8333vw /* 400px -> 20.8333vw */;
          //   box-shadow: inset 0px 0px 1.0417vw /* 20px -> 1.0417vw */ 1.0417vw
          //     /* 20px -> 1.0417vw */ rgba(15, 53, 116, 0.6);
          border-collapse: collapse;
          text-align: center;
          font-size: 0.8333vw /* 16px -> .8333vw */;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #ffffff;
          td {
            padding-top: 0.8333vw /* 16px -> .8333vw */;
            padding-bottom: 0.8333vw /* 16px -> .8333vw */;
          }
          td,
          td {
            border: 1px solid #286ae8;
          }
          td:first-child,
          th:first-child {
            border-left: none;
          }
          td:last-child,
          th:last-child {
            border-right: none;
          }
          tr:first-child td {
            border-top: none;
          }
          tr:last-child td {
            border-bottom: none;
          }
        }
      }
      &-c {
        width: 34.375vw /* 660px -> 34.375vw */;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        &-l {
          img {
            width: 2.7083vw /* 52px -> 2.7083vw */;
            height: 2.7083vw /* 52px -> 2.7083vw */;
            margin: 0 calc((100% - 2.7083vw) / 2) 0.5208vw /* 10px -> .5208vw */;
          }
          p {
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 0.2083vw /* 4px -> .2083vw */;
            font-size: 1.0417vw /* 20px -> 1.0417vw */;
            text-align: center;
          }
        }
        &-c {
          width: 15.625vw /* 300px -> 15.625vw */;
          margin: 0 1.5vw /* 57px -> 2.9688vw */ 0 1.5vw /* 49px -> 2.5521vw */;
          &-top {
            width: 15.625vw /* 300px -> 15.625vw */;
            height: 15.625vw /* 300px -> 15.625vw */;
            margin: 0 calc((100% - 15.625vw) / 2) 1.8229vw
              /* 35px -> 1.8229vw */;
            background: url("~@/assets/icon/icon5.png") no-repeat;
            background-size: 100% 100%;
            p {
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #ffffff;
              font-size: 1.0417vw /* 20px -> 1.0417vw */;
              text-align: center;
            }
            p:nth-child(1) {
              padding-top: 2.9167vw /* 56px -> 2.9167vw */;
            }
            p:nth-child(2) {
              font-size: 3.5417vw /* 68px -> 3.5417vw */;
              margin: 1.0417vw /* 20px -> 1.0417vw */ 0;
            }
          }
          &-bot {
            border-radius: 6px;
            border: 3px solid #17e0fe;
            width: 7.8125vw /* 150px -> 7.8125vw */;
            height: 2.0833vw /* 40px -> 2.0833vw */;
            margin: 0 calc((100% - 7.8125vw) / 2);
            line-height: 2.0833vw /* 40px -> 2.0833vw */;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: 500;
            color: #17e0fe;
            font-size: 1.1458vw /* 22px -> 1.1458vw */;
            text-align: center;
            img {
              width: 1.25vw /* 24px -> 1.25vw */;
              height: 1.25vw /* 24px -> 1.25vw */;
              margin-left: 0.2604vw /* 5px -> .2604vw */;
              margin-bottom: -0.2604vw;
            }
          }
        }
      }
      &-r {
        margin-right: 0;
        margin-left: 5.4167vw /* 104px -> 5.4167vw */;
        margin-left: 4.1667vw /* 80px -> 4.1667vw */;
        &-del {
          color: #02b5ff;
          font-size: 1.0417vw /* 20px -> 1.0417vw */;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          background: url("~@/assets/icon/icon1.png") no-repeat;
          background-size: 100% 100%;
          width: 7.2917vw /* 140px -> 7.2917vw */;
          height: 2.6042vw /* 50px -> 2.6042vw */;
          line-height: 2.6042vw /* 50px -> 2.6042vw */;
          text-align: center;
          cursor: pointer;
          margin-top: 5.8854vw /* 113px -> 5.8854vw */;
          margin-left: 13.6458vw /* 262px -> 13.6458vw */;
        }
      }
    }
  }
}

#threeChart {
  width: 100%;
  height: 13.0208vw /* 250px -> 13.0208vw */;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-input__inner {
  width: 100%;
  height: 2.0833vw /* 40px -> 2.0833vw */;
  font-size: calc(100vw * 16 / 1920);
  box-shadow: inset 0px 0px 26px 10px rgba(44, 98, 230, 0.28);
  border-radius: 6px;
  border: 1px solid #286ae8;
  background: none;
  color: #fff;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-input__icon {
  line-height: 1.4063vw /* 27px -> 1.4063vw */;
}
</style>
