import { render, staticRenderFns } from "./landJu.vue?vue&type=template&id=c92f9210&scoped=true"
import script from "./landJu.vue?vue&type=script&lang=js"
export * from "./landJu.vue?vue&type=script&lang=js"
import style0 from "./landJu.vue?vue&type=style&index=0&id=c92f9210&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c92f9210",
  null
  
)

export default component.exports