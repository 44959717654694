<template>

    <div class="container">
      <div style = "position: absolute;
    color: #ffffff;
    left: 2.5vh;
    top: -4vh;
    z-index: 999;
    font-size: calc(100vw * 14 / 1920);
    display: flex;
    align-items: center;
    cursor: pointer;"
           @click="goBack()">
        <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbab5f198dfc4bdb15571a116b3d9e4cde6067c4d38c292b039ea2618876a9b7d"
        />
        <span class="text_1">返回</span>

<!--        <span class="text_1" style="margin-left: 5vw;">返回</span>-->
      </div>
    <div class="add"  @click="goAdd" style="margin-left: 10%" v-if="isAdmin=='admin'">新增</div>

    <div style=" color: #ffff;
      display: flex;
      flex-wrap: wrap;
      width: 80%;
margin-left: 10%" >
      <div class = "item" v-for="(item, index) in totalList" :key="index" >
<!--      <div class = "item"  >-->
        <div class="item_tlt" @click="goInfo(item.id)">
         土地所有人：{{item.landHolder}}
        </div>
        <div class="item_name">
          <div style="width: 70%">总面积:</div>
          <div>{{item.totalArea}}m²</div>
        </div>
        <div class="item_btn">
          <div class="btn" @click="edit(item.id)" v-if="isAdmin=='admin'">编辑</div>
          <div class="btn" @click ="del(item.id)" v-if="isAdmin=='admin'">删除</div>
        </div>
      </div>



        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[15]"
            :page-size="100"
            layout="sizes, prev, pager, next , total"
            :total="total"
        >
        </el-pagination>


      </div>

  </div>
</template>

<script>
import { getUserList, deleteUser, getZhenInfo } from "@/api/jurisdiction";
import {getHosingCunEdit, getHousingSummary, getZhenDel, getZhenTotal} from "@/api/Hosing";
export default {
  name: "userJurisdiction",

  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      totalList: [],
      currentPage: 1,
      pageSize: 15,
      total: 0,
      id:'',
      isAdmin:''
    };
  },

  methods: {
    goBack(){
      this.$router.push({ path: '/land', query: { id: this.id,card:'zhenLand'}});
    },
    goAdd(){
      // this.$router.push("/landZhenPropertyAdd");
      this.$router.push({ path: '/landZhenPropertyAdd', query: { id: this.id}});
    },
    del(row){
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        getZhenDel(row).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    goInfo(row){
      this.$router.push({ path: '/landZhenPropertyAddInfo', query: { editId: row}});
    },
    edit(row){
      this.$router.push({ path: '/landZhenPropertyAdd', query: { editId: row,id: this.id}});
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        manageId: this.id,
      };
      console.log(data,"data");
      getZhenTotal(data).then((res) => {
        this.totalList = res.rows;
        // this.totalList.pop();
        this.total = res.total;
      });
    },

  },
  mounted() {
    this.id = this.$route.query.id;
    this.getList();
    this.isAdmin = localStorage.getItem("userInfo");
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;

    .right-top {

      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 10vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  //background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286AE8;
  box-shadow: inset 0px 0px 20px 21px rgba(15,53,116,0.6);
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}

.item{
  width: 15%;
  background:  url(@/assets/use/item.png);
  background-size: 100% 100%;
  height: 12vh;
  padding: 1vw;
  margin-left: 3vh;
  margin-top: 3vh;
}
.item_tlt{
  font-size: calc(100vw * 16 / 1920);
  color: #2EE6FE;
}
.item_name{
  font-size: calc(100vw * 16 / 1920);
  display: flex;
  margin-top: 1vh;
}
.item_btn{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5vh;
}
.btn{
  background: url(@/assets/use/btn.png);
  background-size: 100% 100%;
  color: #02b5ff;
  font-size: calc(100vw * 16 / 1920);
  line-height: 4vh;
  text-align: center;
  cursor: pointer;
  width: 5vw;
  height: 100%;
}

.add{
    width: 10vw;
    height: 100%;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 16 / 1920);
    line-height: 4.6vh;
    text-align: center;
    cursor: pointer;


    margin-right: 3vw;

}

</style>
