<template>
  <div>
    <!-- <div id="allmap" ref="allmap" class="allmap"></div> -->
    <div id="container" tabindex="0" class="allmap"></div>
  </div>
</template>

<script>
import { getMap } from "@/api/home";
export default {
  // name: "map",
  data() {
    return {
      // map: "",
      pointsList: [],
    };
  },
  methods: {
    initMap() {
      // let map = new BMapGL.Map(this.$refs.allmap);
      // map.centerAndZoom(new BMapGL.Point(118.5, 27.5), 5); // 初始化地图,设置中心点坐标和地图级别
      // map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      // map.setMapType(BMAP_EARTH_MAP); // 设置地图类型为地球模式

      var map = new AMap.Map("container", {
        mapStyle: 'amap://styles/macaron',
        center: [104.937478, 35.439575],
        zoom: 5,
      });
      // var points = [];
      console.log(this.pointsList,'和杜萨会')
      var points = this.pointsList

      console.log(points, "的那瞬间");

      var count = points.length;
      console.log(count, "安徽队啊水");
      // 数据中增加权重信息，以权重高的点为中心进行聚合
      // 本示例中北京等城市中心点权重较高

      var _renderClusterMarker = function (context) {
        // 聚合中点个数
        var clusterCount = context.count;
        var div = document.createElement("div");
        // 聚合点配色
        var defaultColor = [
          "204,235,197",
          "168,221,181",
          "123,204,196",
          "78,179,211",
          "43,140,190",
        ];
        var bgColor = "";
        if (clusterCount >= 0 && clusterCount < 10) {
          bgColor = defaultColor[0];
        } else if (clusterCount >= 10 && clusterCount < 100) {
          bgColor = defaultColor[1];
        } else if (clusterCount >= 100 && clusterCount < 1000) {
          bgColor = defaultColor[2];
        } else if (clusterCount >= 1000 && clusterCount < 10000) {
          bgColor = defaultColor[3];
        } else if (clusterCount >= 10000) {
          bgColor = defaultColor[4];
        }
        div.style.backgroundColor = "rgba(" + bgColor + ",.5)";
        var size = Math.round(25 + Math.pow(clusterCount / count, 1 / 5) * 40);
        div.style.width = div.style.height = size + "px";
        div.style.border = "solid 1px rgba(" + bgColor + ",1)";
        div.style.borderRadius = size / 2 + "px";
        div.innerHTML = context.count;
        div.style.lineHeight = size + "px";
        div.style.color = "#ffffff";
        div.style.fontSize = "12px";
        div.style.textAlign = "center";
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div);
      };
      var _renderMarker = function (context) {
        var content =
          '<div style="background-color: rgba(255,255,178,.9); height: 18px; width: 18px; border: 1px solid rgba(255,255,178,1); border-radius: 12px; box-shadow: rgba(0, 0, 0, 1) 0px 0px 3px;"></div>';
        var offset = new AMap.Pixel(-9, -9);
        context.marker.setContent(content);
        context.marker.setOffset(offset);
      };
      var cluster = new AMap.MarkerCluster(map, points, {
        gridSize: 60, // 聚合网格像素大小
        renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
        renderMarker: _renderMarker, // 自定义非聚合点样式
      });
    },
  },
  mounted() {
    getMap().then((res) => {
      console.log(this.pointsList,'四年冬季ask你几点开始')
      if(res.code == 200){
        this.pointsList = res.data;
        this.initMap();
      }else {
        this.initMap();
      }
      
    });
    
  },
};
</script>

<style lang="scss" scoped>
.allmap::v-deep {
  width: 100%;
  height: 88.2vh;
  .amap-logo,
  .amap-copyright {
    display: none !important;
  }
}
</style>
