<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">设备概况</div>
    </div>
    <div class="back" @click="backPrev">返回</div>
    <div class="right-part">
      <div class="right-top">
        <el-form :model="form">
          <el-form-item label="电站名称：">
            <el-input
              v-model="form.searchContent"
              placeholder="请输入电站名称"
              style="width: 15.6vw"
            ></el-input>
          </el-form-item>
          <div style="display: flex"></div>
          <div style="display: flex; justify-content: space-between">
            <el-form-item label="报文时间：">
              <el-date-picker
                style="width: 15vw"
                v-model="form.reportingTime"
                type="month"
              >
              </el-date-picker>
            </el-form-item>
            <div style="display: flex">
              <div class="btn" @click="query">查询</div>
              <div class="btn" @click="reset">重置</div>
              <div class="btn" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        style="margin-bottom: 2.5vh"
        :data="account"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          prop="createTime"
          label="报文时间(月)"
        ></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="num" label="总发电量"></el-table-column>
        <el-table-column prop="priceNum" label="总收益"></el-table-column>
        <el-table-column prop="powerNum" label="并网功率"></el-table-column>
        <el-table-column prop="durationNum" label="等效时长"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getEquipmentList, exportEquipmentList } from "@/api/green";
export default {
  name: "accountDetails",
  data() {
    return {
      form: {
        searchContent: "",
        reportingTime: "", // 报文
      },
      account: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 14 / 1920);";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getNowDate() {
      const timeOne = new Date();
      const year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      const NOW_MONTHS_AGO = `${year}-${month}`;
      return NOW_MONTHS_AGO;
    },
    getList() {
      if (this.form.reportingTime != "") {
        this.form.reportingTime = this.formateDate(this.form.reportingTime);
      } else {
        this.form.reportingTime = this.getNowDate();
      }
      if (this.form.searchContent == "") {
        this.form.searchContent = this.$route.query.title;
      }
      getEquipmentList(this.form.searchContent, this.form.reportingTime).then(
        (res) => {
          this.account = res.rows;
          this.total = res.total;
        }
      );
    },
    query() {
      this.currentPage = 1
      this.getList();
    },
    reset() {
      this.form.searchContent = this.$route.query.title;
      this.form.reportingTime = this.getNowDate();
      this.getList();
    },
    exportData() {
      exportEquipmentList().then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() + "-" + addDateZero(d.getMonth() + 1);
      return formatdatetime;
    },
    backPrev(){
      this.$router.push('/equipmentOverview')
    }
  },
  mounted() {
    this.getList();
    console.log(this.$route.query.title, "十大护士");
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .left-part {
    position: absolute;
    left: 0;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .back {
    position: absolute;
    left: 0;
    top: 10vh;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
  }
  .right-part {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 62.5vw;
    .right-top {
      text-align: center;
      margin-bottom: 4.6vh;
      .btn {
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        width: 7vw;
        height: 4.6vh;
        font-size: calc(100vw * 18 / 1920);
        margin-left: 3vw;
        color: #02b5ff;
        text-align: center;
        line-height: 4.6vh;
        cursor: pointer;
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 6.5vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}
::v-deep .el-range-input {
  background: none;
}
::v-deep .el-date-editor .el-range-separator {
  color: #fff;
  font-size: calc(100vw * 17 / 1920);
  line-height: 4.3vh;
  height: 4.6vh;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: calc(100vw * 17 / 1920);
  line-height: 4.6vh;
  height: 4.6vh;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-date-editor .el-range-input {
  font-size: calc(100vw * 16 / 1920);
  color: #fff;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
