<template>
  <div class="container">
    <div class="left-part">
      <div class="bill-title">用户账单</div>
      <div class="bill-title" style="cursor: pointer" @click="goback">返回</div>
    </div>
    <div class="middle-part">
      <div class="bill" id="bill">
        <div class="bill-top">
          <div class="bill-one">
            <div>电站名称：<span style="font-size:calc(100vw * 14 / 1920);">{{ tableData[0].gr.gfPowerStation.psName }}</span></div>
            <div>电表类型：
              <span style="font-size:calc(100vw * 14 / 1920);" v-if="tableData[0].gr.mrWhType == '1'">总电表</span>
              <span style="font-size:calc(100vw * 14 / 1920);" v-if="tableData[0].gr.mrWhType == '2'">光伏电表</span>
            </div>
          </div>
          <div class="bill-one">
            <div>表号：<span style="font-size:calc(100vw * 14 / 1920);">{{ tableData[0].sn }}</span></div>
            <div>抄表日期：<span style="font-size:calc(100vw * 14 / 1920);">{{ tableData[0].date }}</span></div>
          </div>
          <img class="sign" src="@/assets/images/sign.png" />
        </div>
        <div class="bill-one" style="margin-bottom:2vh;font-size: calc(100vw * 16 / 1920);">
            <div>用电地址：<span style="font-size:calc(100vw * 14 / 1920);">{{ tableData[0].gr.mrAddress }}</span></div>
          </div>
        <table border="1">
          <tr>
            <td>时段</td>
            <td>上期读数</td>
            <td>本期读数</td>
            <td>倍率</td>
            <td>用电量</td>
            <td>折后单价</td>
            <td>金额</td>
          </tr>
          <tr>
            <td>尖</td>
            <td class="content">{{ tableData[0].eev[1].loDu }}</td>
            <td class="content">{{ tableData[0].eev[1].beDu }}</td>
            <td class="content">{{ tableData[0].eev[1].bl }}</td>
            <td class="content">{{ tableData[0].eev[1].ydl }}</td>
            <td class="content">{{ tableData[0].eev[1].price }}</td>
            <td class="content">{{ tableData[0].eev[1].sumPrice }}</td>
          </tr>
          <tr>
            <td>峰</td>
            <td class="content">{{ tableData[0].eev[2].loDu }}</td>
            <td class="content">{{ tableData[0].eev[2].beDu }}</td>
            <td class="content">{{ tableData[0].eev[2].bl }}</td>
            <td class="content">{{ tableData[0].eev[2].ydl }}</td>
            <td class="content">{{ tableData[0].eev[2].price }}</td>
            <td class="content">{{ tableData[0].eev[2].sumPrice }}</td>
          </tr>
          <tr>
            <td>平</td>
            <td class="content">{{ tableData[0].eev[3].loDu }}</td>
            <td class="content">{{ tableData[0].eev[3].beDu }}</td>
            <td class="content">{{ tableData[0].eev[3].bl }}</td>
            <td class="content">{{ tableData[0].eev[3].ydl }}</td>
            <td class="content">{{ tableData[0].eev[3].price }}</td>
            <td class="content">{{ tableData[0].eev[3].sumPrice }}</td>
          </tr>
          <tr>
            <td>谷</td>
            <td class="content">{{ tableData[0].eev[4].loDu }}</td>
            <td class="content">{{ tableData[0].eev[4].beDu }}</td>
            <td class="content">{{ tableData[0].eev[4].bl }}</td>
            <td class="content">{{ tableData[0].eev[4].ydl }}</td>
            <td class="content">{{ tableData[0].eev[4].price }}</td>
            <td class="content">{{ tableData[0].eev[4].sumPrice }}</td>
          </tr>
          <tr>
            <td>总</td>
            <td class="content">{{ tableData[0].eev[0].loDu }}</td>
            <td class="content">{{ tableData[0].eev[0].beDu }}</td>
            <td class="content">{{ tableData[0].eev[0].bl }}</td>
            <td class="content">{{ tableData[0].eev[0].ydl }}</td>
            <td class="content">{{ tableData[0].eev[0].price }}</td>
            <td class="content">{{ tableData[0].eev[0].sumPrice }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="right-part">
      <div class="updown" @click="downLoadPic">下载图片</div>
    </div>
  </div>
</template>

<script>
// 截图
import html2canvas from "html2canvas";
import { getUserBill } from "@/api/home";
export default {
  name: "userBill",
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    goback() {
      this.$router.push("/electricityReport");
    },
    getBill() {
      let data = {
        sn: this.$route.query.sn,
        meTime: this.$route.query.meTime,
      };
      getUserBill(data).then((res) => {
        console.log(res, "三甲基");
        this.tableData = res.data;
      });
    },
    // 下载图片
    downLoadPic() {
      html2canvas(document.getElementById("bill"), {
        useCORS: true, //是否尝试使用CORS从服务器加载图像
        backgroundColor: "#ececec", //画布背景色（如果未在DOM中指定）。设置null为透明
      }).then((canvas) => {
        // 返回一个 canvas 对象，在dom中渲染 canvas 对象
        //document.body.appendChild(canvas);
        // 转化成 dataurL
        let canvasImg = canvas.toDataURL("image/png");
        //调用下载方法
        this.downLoadFile("simple", canvasImg);
      });
    },
    downLoadFile(fileName, canvasImg) {
      //创建一个a标签
      var a = document.createElement("a");
      //指定下载文件名称
      a.href = canvasImg;
      a.download = fileName;
      //模拟点击
      a.click();
    }
  },
  created() {
    this.getBill();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__header {
  border-bottom: 1px solid #c45a1d;
}
::v-deep .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border: 1px solid #c45a1d;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-table--border {
  border: 1px solid #c45a1d;
}
::v-deep .el-table .el-table__cell {
  text-align: center;
  color: #333;
}
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    flex: 29%;
    .bill-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .middle-part {
    flex: 45%;
    .bill {
      width: 100%;
      // height: 44.5vh;
      // height: 420px;
      background: #fff;
      padding: 2.5vh 2vw;
      box-sizing: border-box;
      .bill-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.7vh;
        .bill-one {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 7vh;
          font-size: calc(100vw * 16 / 1920);
        }
        .sign {
          width: 2vw;
          height: 4vh;
        }
      }
      table {
        width: 100%;
        font-size: calc(100vw * 16 / 1920);
        border: 4px solid #c45a1d;
        border-collapse: collapse;
        text-align: center;
        tr {
          height: 5vh;
          // &:last-child {
          //   border-bottom: 2px solid #c45a1d;
          // }
          .content {
            font-size: calc(100vw * 14 / 1920);
          }
        }
      }
    }
  }
  .right-part {
    flex: 30%;
    .updown {
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      width: 16.6vw;
      height: 4.6vh;
      margin-top: 65vh;
      margin-left: 1vw;
      text-align: center;
      line-height: 4.6vh;
      color: #02b5ff;
      font-size: calc(100vw * 18 / 1920);
      cursor: pointer;
    }
  }
}
</style>
