<template>
  <div class="container">
    <div class="left-title">实时报警</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div
            style="width: 67vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="电站名称：">
              <el-input
              placeholder="请输入电站名称"
                v-model="form.stationName"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="区域：">
              <v-distpicker
                @province="provinceChange"
                @city="cityChange"
                @area="areaChange"
                :province="form.province"
                :city="form.city"
                :area="form.area"
              ></v-distpicker>
            </el-form-item>
          </div>

          <div style="display: flex; justify-content: space-between">
            <el-form-item label="报文时间：">
              <el-date-picker
                style="width: 11vw; margin-right: 2vw"
                v-model="form.beginTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions0"
                placeholder="开始日期"
              >
              </el-date-picker>
              <el-date-picker
                style="width: 11vw"
                v-model="form.endTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions1"
                placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div>
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="alarm"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="etName" label="电站名称"></el-table-column>
        <el-table-column prop="province" label="区域">
          <template slot-scope="scope">
            <span>
              {{scope.row.province}}{{scope.row.city}}{{scope.row.area}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="mac" label="mac"></el-table-column>
        <el-table-column prop="sn" label="表号(sn)">
          <template slot-scope="scope">
            <span v-if="scope.row.sn != null">
              {{scope.row.sn}}
            </span>
            <span v-else>
              /
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="电表名称">
          <template slot-scope="scope">
            <span v-if="scope.row.gr != null">
                {{scope.row.gr.mrMeName}}
            </span>
            <span v-else>
              /
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="gfType" label="设备类型"></el-table-column>
        <el-table-column prop="createTime" label="报警日期"></el-table-column>
        <el-table-column prop="sta" label="处理状态">
          <template slot-scope="scope">
            <span v-if="scope.row.sta == 0">未处理</span>
            <span v-if="scope.row.sta == 1">已处理</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5,10,15,20]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAlarmList, getAlarmExport } from "@/api/intelligence";
import VDistpicker from "v-distpicker";
export default {
  name: "alarm",
  components: {
    VDistpicker,
  },
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
            return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        province: "", // 省
        city: "", // 市
        area: "", // 区
        stationName: "", // 电站名称
        beginTime: "",
        endTime: "",
      },
      alarm: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    provinceChange(data) {
      //省
      console.log(data);
      this.form.province = data.value;
      this.form.city = '';
      this.form.area = '';
    },
    cityChange(data) {
      //市
      this.form.city = data.value;
      this.form.area = '';
    },
    areaChange(data) {
      //区、县
      this.form.area = data.value;
    },
    // 获取列表
    getList() {
      if(this.form.beginTime != ''){
        this.form.beginTime = this.formateDate(this.form.beginTime);
      }
      if(this.form.endTime != ''){
        this.form.endTime = this.formateDate(this.form.endTime);
      }
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        title: this.form.stationName,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        province: this.form.province,
        city: this.form.city,
        area: this.form.area,
      };
      getAlarmList(data).then((res) => {
        console.log(res);
        this.alarm = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    // 重置
    reset() {
      this.form.stationName = "";
      this.form.province = "";
      this.form.city = "";
      this.form.area = "";
      this.form.beginTime = "";
      this.form.endTime = "";
      this.getList();
    },
    // 导出
    exportData() {
      let data = {
        title: this.form.stationName,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        province: this.form.province,
        city: this.form.city,
        area: this.form.area,
      };
      getAlarmExport(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .right-container {
    width: 67vw;
    position: absolute;
    left: 6vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      width: 100%;
      margin-bottom: 3vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
::v-deep .el-table {
  width: 99.97%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-left: 1vw;
  font-size: calc(100vw * 14 / 1920) !important;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
