<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title" v-if="$route.query.id == undefined">设备类型录入</div>
      <div class="equipment-title" v-if="$route.query.id != undefined">设备类型编辑</div>
    </div>
    <div class="middle-part">
      <el-form :model="form" ref="form" :rules="rules">
        <div style="display: flex; height: 4.6vh; margin-bottom: 2.5vh">
          <el-form-item label="设备类型：" prop="typeName">
            <el-input v-model="form.typeName" placeholder="请输入设备类型"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <!-- <div style="display: flex; height: 4.6vh; margin-bottom: 2.5vh"> -->
          <!-- <el-form-item label="SN码：" prop="sn">
            <el-input v-model="form.sn"></el-input>
          </el-form-item> -->
          <!-- <div class="tip">（必填）</div>
        </div> -->
        <el-form-item label="备注：">
          <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="right-part">
      <div class="updown" @click="addNew('form')" v-if="$route.query.id == undefined">添加</div>
      <div class="updown" v-if="$route.query.id != undefined" @click="modify('form')">修改</div>
    </div>
  </div>
</template>

<script>
import { addEquipment, byEquipmentId,modifyEquipment } from "@/api/equipment";
export default {
  name: "addEquipment",
  data() {
    return {
      form: {
        typeName: "",
        remark: "",
        sn:""
      },
      rules: {
        typeName: [
          { required: true, message: "设备类型不能为空", trigger: "blur" },
        ],
        // sn:[
        //     { required: true, message: "sn码不能为空", trigger: "blur" },
        // ]
      },
      id: "",
    };
  },
  methods: {
    // 新增
    addNew(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          addEquipment(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/equipmentType");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 修改
    modify(form){
        this.$refs[form].validate((valid) => {
        if (valid) {
            this.form.id = this.id
          modifyEquipment(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/equipmentType");
            }
          });
        } else {
          return false;
        }
      });
    }
  },
  mounted() {
    if (this.$route.query.id != undefined) {
      this.id = this.$route.query.id;
      byEquipmentId(this.id).then((res) => {
        console.log(res, "卡拉都能拉四年");
        this.form.typeName = res.data.typeName;
        this.form.remark = res.data.remark;
        this.form.sn = res.data.sn;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    flex: 29%;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .middle-part {
    flex: 40%;
    .tip {
      margin-left: 0.5vw;
      line-height: 4.6vh;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
    }
  }
  .right-part {
    flex: 30%;
    .updown {
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      width: 7.2vw;
      height: 4.6vh;
      margin-top: 65vh;
      margin-left: 1vw;
      text-align: center;
      line-height: 4.6vh;
      color: #02b5ff;
      font-size: calc(100vw * 18 / 1920);
      cursor: pointer;
    }
  }
}
.el-form::v-deep {
  width: 30vw;
  .el-form-item {
    display: flex;
    align-items: center;
    height: 4.6vh;
    .el-form-item__label {
      width: 5.9vw;
      text-align: left;
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
    }
    .el-input__inner {
      width: 15.6vw;
      height: 4.6vh;
      background: #0f2455;
      border: 1px solid #286ae8;
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
      border-radius: 6px;
      &::placeholder {
        color: #fff;
      }
    }
  }
}
</style>
